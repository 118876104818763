import React, { useState } from 'react';
import { Button, FormFeedback, Input } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link, NavLink, useHistory } from 'react-router-dom';
import CheckBox from '../../../shared/components/form/CheckBox';
import { Auth } from "aws-amplify";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { Creators as profileDuck } from "../../../store/ducks/profile";
import { updateLogin } from '../../../api/admin';
import OtpVerificationForm from "./otp_verification/otp_verification";

const SignupSchema = Yup.object().shape({
	email: Yup.string()
		.email('Invalid Email')
		.required('Email is required'),
	password: Yup.string()
		// .test('password', 'Password must be 8 characters and it should contain at least one digit and one special symbol', (value = '') => value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/))
		.required('Password is required')
});

export default function LogInForm() {
	const [showPassword, setShowPassword] = useState(false);
	const [toggleCheckBox, setToggleCheckBox] = useState(false);
	const [loading, setLoading] = useState(false);
	const [otpVerification, setOtpVerification] = useState(false);
	const [user, setUser] = useState(false);
	const [data, setData] = useState({ email: '', password: '' });
	const history = useHistory();
	const dispatch = useDispatch();

	const showPasswordHandler = (e) => {
		e.preventDefault();
		setShowPassword(prevState => !prevState);
	};

	const toggleCheckBoxHandler = () => {
		setToggleCheckBox(prevState => !prevState)
	};

	const login = async ({ email, password }) => {
		setLoading(true);
		try {
			const u = await Auth.signIn(email, password);
			if (u.challengeName === "NEW_PASSWORD_REQUIRED") {
				history.push({ pathname: '/new_password', user: u });
				// await Auth.completeNewPassword(u, password);
			} else {
				setLoading(false);
				if (u.challengeName === "SMS_MFA") {
					setOtpVerification(true);
					setUser(u);
					return;
				}
				updateLogin().then(res => {
					console.log(res)
				}).catch(er => {
					console.log(er);
				})
				localStorage.setItem('user', JSON.stringify(u));
				dispatch(profileDuck.setUser(u));
				history.push('/homepage');
			}
		} catch (e) {
			setLoading(false);
			toast.error(e.message, {
				position: toast.POSITION.TOP_RIGHT
			});
			console.log(e);
		}
	}
	if (otpVerification) {
		return <OtpVerificationForm user={user} />
	}
	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={{ email: data.email, password: data.password }}
				validationSchema={SignupSchema}
				onSubmit={(values) => {
					login(values);
				}
				}>
				{({ values, errors, touched, handleChange, handleSubmit, handleBlur, }) => (
					<form className="form">
						<div className="form__form-group">
							<span className="form__form-group-label">Username</span>
							<div className="form__form-group-field flex-wrap ">
								<div className="form__form-group-icon ">
									<AccountOutlineIcon />
								</div>
								<Input type="email"
									className="input-without-border-radius flex-grow-1"
									invalid={errors.email && touched.email}
									onChange={handleChange('email')}
									onBlur={handleBlur('email')}
									value={values.email}
									style={{ width: 'auto' }}
									placeholder="Enter email" />
								<FormFeedback>
									{errors.email && touched.email && errors.email}
								</FormFeedback>
							</div>
						</div>
						<div className="form__form-group">
							<span className="form__form-group-label">Password</span>
							<div className="form__form-group-field flex-wrap">
								<div className="form__form-group-icon">
									<KeyVariantIcon />
								</div>

								<Input type={showPassword ? 'text' : 'password'}
									invalid={errors.password && touched.password}
									onChange={handleChange('password')}
									onBlur={handleBlur('password')}
									value={values.password}
									style={{ width: 'auto' }}
									className="input-without-border-radius flex-grow-1"
									placeholder="Enter password" />
								<button
									className={`form__form-group-button d-flex ${showPassword ? ' active' : ''}`}
									onClick={e => showPasswordHandler(e)}
									type="button"
								><EyeIcon />
								</button>
								<FormFeedback>
									{errors.password && touched.password && errors.password}
								</FormFeedback>
							</div>
							<div className="account__forgot-password">
								<NavLink to="/reset_password">Forgot a password?</NavLink>
							</div>
						</div>
						<div className="form__form-group">
							<div className="form__form-group-field">
								<CheckBox name="remember_me" label="Remember me" value={toggleCheckBox} onChange={toggleCheckBoxHandler}
								/>
							</div>
						</div>

						<Button color="primary" className="btn btn-primary account__btn account__btn--small" onClick={handleSubmit}>
							{loading ? <ClipLoader sizeUnit={"px"} size={20} color={"#fff"} loading={true} /> : 'Sign In'}
						</Button>

						<Link className="btn btn-outline-primary account__btn account__btn--small" to="/log_in">Create
							Account</Link>
					</form>
				)}
			</Formik>
		</div>
	)
}
