import React, { useState } from 'react'
import { Button, Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProjectDuck } from '../../../../../store/ducks/users'
import { ClipLoader } from 'react-spinners';
import Avatar from '../../../../../assets/avatar.png';

export default function Assignments(props) {
	const { user } = props;
	const [clientIndex, setClientIndex] = useState(0);
	const [projectIndex, setProjectIndex] = useState({ index: 0, i: 0 });
	const { clients, users } = useSelector(state => state.users) || {};
	const { isLoadingAssignProject, isLoadingUnAssignProject, isLoadingAssignClient, isLoadingUnAssignClient } = useSelector(state => state.users);
	const userMap = users.reduce((p, c) => ({ ...p, [c._id]: c }), {})
	const dispatch = useDispatch();

	const removeClientHandler = (id, index) => {
		setClientIndex(index);
		dispatch(ProjectDuck.unAssignClient(user._id, { clientId: id }));
	}

	const assignClientHandler = (id, index) => {
		setClientIndex(index);
		dispatch(ProjectDuck.assignClient(user._id, { clientId: id }));
	}
	const removeProjectHandler = (clientId, projectId, index, i) => {
		setProjectIndex({ index, i });
		dispatch(ProjectDuck.unAssignProject(user._id, { projectId, clientId }));
	}

	const assignProjectHandler = (clientId, projectId, index, i) => {
		setProjectIndex({ index, i });
		dispatch(ProjectDuck.assignProject(user._id, { projectId, clientId }));
	}

	return (
		<div>
			<Table responsive className="table--bordered">
				<thead>
					<tr>
						<th>Client</th>
						<th>Client Managers</th>
						<th>Projects</th>
						<th>Project Managers</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{clients.map((p, index) => {
						return (
							<tr>
								<td className="d-flex">
									<img src={p.image ? p.image : Avatar} className="user-profile-icons" alt="UserProfileIcon" />
									<div className="d-flex flex-column justify-content-center align-items-center">
										<h2 className="user-profile-clients-name mb-0">{p.name}</h2>
									</div>
								</td>
								<td className="overlapping-img">
									<ul>
										{p.assignees.map(a => <li><img src={userMap[a]?.image ? userMap[a]?.image : Avatar} alt="" title={userMap[a]?.name} /></li>)}
									</ul>
								</td>
								<td colSpan={3}>
									<div className="row mr-0">
										{p.data.map((c, i) => {
											return (
												<React.Fragment key={c._id}>
													<div className="col-4 mt-1 d-flex align-items-center" style={{ borderBottom: "1px solid #eee" }}>
														<h2 className="assugnments-project-title mb-0">{c.label}</h2>
													</div>
													<div className="col-4 overlapping-img mt-1" style={{ borderBottom: "1px solid #eee" }}>
														<ul>
															{c.assignees.map(a => <li><img src={userMap[a]?.image ? userMap[a]?.image : Avatar} alt="" title={userMap[a]?.name} />
															</li>)}
														</ul>
													</div>
													<div className='col-4 mt-1 pr-0' style={{ borderBottom: "1px solid #eee" }}>
														{user.role === "ProjectManager" ?
															<>
																{c.assignees.find(a => a === user._id) ?
																	<Button color="primary" className="btn btn-primary btn-block px-3 mb-0 ml-auto my-1"
																		style={{ maxWidth: "fit-content" }}
																		onClick={() => removeProjectHandler(p._id, c._id, index, i)}>{isLoadingUnAssignProject && index === projectIndex.index && i === projectIndex.i ?
																			<ClipLoader
																				size={20}
																				color={"#fff"}
																				loading={true}
																			/> : 'Remove'
																		}</Button>
																	:
																	<Button color="primary" className="btn btn-success btn-block px-3 mb-0 ml-auto my-1"
																		style={{ maxWidth: "fit-content" }}
																		onClick={() => assignProjectHandler(p._id, c._id, index, i)}>{isLoadingAssignProject && index === projectIndex.index && i === projectIndex.i ?
																			<ClipLoader
																				size={20}
																				color={"#fff"}
																				loading={true}
																			/> : 'Assign'
																		}</Button>
																}
															</>
															:
															<>
																{p.assignees.find(a => a === user._id) ?
																	<Button color="primary" className="btn btn-primary btn-block px-3 mb-0 ml-auto my-1"
																		style={{ maxWidth: "fit-content" }}
																		onClick={() => removeClientHandler(p._id, index)}>{isLoadingUnAssignClient && index === clientIndex ?
																			<ClipLoader
																				size={20}
																				color={"#fff"}
																				loading={true}
																			/> : 'Remove'
																		}</Button>
																	:
																	<Button color="primary" className="btn btn-success btn-block px-3 mb-0 ml-auto my-1"
																		style={{ maxWidth: "fit-content" }}
																		onClick={() => assignClientHandler(p._id, index)}>{isLoadingAssignClient && index === clientIndex ?
																			<ClipLoader
																				size={20}
																				color={"#fff"}
																				loading={true}
																			/> : 'Assign'
																		}</Button>}
															</>
														}
													</div>

												</React.Fragment>
											)
										})}
									</div>
								</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</div>
	)
}