import React, { useState } from 'react';
import logoImage from "../../../assets/logo.png";
import EyeIcon from 'mdi-react/EyeIcon';
import { Button, FormFeedback, Input } from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { Auth } from "aws-amplify";
import { useDispatch } from 'react-redux';
import { Creators as profileDuck } from "../../../store/ducks/profile";
import { useHistory } from 'react-router-dom';

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    // .test('password', 'Password must be 8 characters and it should contain at least one digit and one special symbol', (value = '') => value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/))
    .required('Password is required'),
  confirmPassword: Yup.string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    })
    // .test('password', 'Password must be 8 characters and it should contain at least one digit and one special symbol', (value = '') => value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/))
    .required('confirmPassword is required')

});

const NewPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({ confirmPassword: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showPasswordHandler = (e) => {
    e.preventDefault();
    setShowPassword(prevState => !prevState);
  };

  const showConfirmPasswordHandler = (e) => {
    e.preventDefault();
    setShowConfirmPassword(prevState => !prevState);
  };

  const submitHandler = async (values) => {
    setIsLoading(true);
    await Auth.completeNewPassword(props.location?.user, values.password)
      .then(res => {
        setIsLoading(false);
        localStorage.setItem('user', JSON.stringify(props.location?.user));
        dispatch(profileDuck.setUser(props.location?.user));
        history.push('/homepage');
      })
      .catch(er => {
        setIsLoading(false);
        console.log(er)
      })
  }

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card shadow">
          <div className="account__head">
            <h3 className="account__title">
              <img className="topbar__logo__image" src={logoImage} alt="" />
            </h3>
            <h4 className="account__subhead subhead">Password Reset</h4>
          </div>
          <Formik
            enableReinitialize
            initialValues={state}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              submitHandler(values)
            }
            }>
            {({ values, errors, touched, handleChange, handleSubmit, dirty, handleBlur, }) => (
              <form className="form">

                <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field flex-wrap">
                    <div className="form__form-group-icon">
                      <KeyVariantIcon />
                    </div>
                    <Input
                      invalid={errors.password && touched.password}
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                      value={values.password}
                      type={showPassword ? 'text' : 'password'}
                      style={{ width: 'auto' }}
                      placeholder="********"
                      className="input-without-border-radius flex-grow-1" />
                    <button
                      className={`form__form-group-button d-flex ${showPassword ? ' active' : ''}`}
                      onClick={e => showPasswordHandler(e)}
                      type="button"
                    ><EyeIcon />
                    </button>
                    <FormFeedback>
                      {errors.password && touched.password && errors.password}
                    </FormFeedback>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Confirm Password</span>
                  <div className="form__form-group-field flex-wrap">
                    <div className="form__form-group-icon">
                      <KeyVariantIcon />
                    </div>
                    <Input
                      invalid={errors.confirmPassword && touched.confirmPassword}
                      onChange={handleChange('confirmPassword')}
                      onBlur={handleBlur('confirmPassword')}
                      value={values.confirmPassword}
                      type={showConfirmPassword ? 'text' : 'password'}
                      style={{ width: 'auto' }}
                      placeholder="********"
                      className="input-without-border-radius flex-grow-1" />
                    <button
                      className={`form__form-group-button d-flex ${showConfirmPassword ? ' active' : ''}`}
                      onClick={e => showConfirmPasswordHandler(e)}
                      type="button"
                    ><EyeIcon />
                    </button>
                    <FormFeedback>
                      {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                    </FormFeedback>
                  </div>
                </div>
                <Button color="primary"
                  className="btn btn-primary account__btn account__btn--small" onClick={handleSubmit}>
                  {isLoading ? <ClipLoader size={20} color='#4ce1b6' /> : 'Change Password'}
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default NewPassword;