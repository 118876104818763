import { createActions, createReducer } from "reduxsauce";
import {
    assignClients,
    assignProjects,
    getAllUsers,
    unAssignClients,
    unAssignProjects,
    updateUserProfile,
    addUsers
} from '../../api/admin/index';

const getUsers = () => {
    return (dispatch) => {
        dispatch(Creators.isLoadingUsers());
        getAllUsers().then(res => {
            dispatch(Creators.getUsersSuccess(res));
        }).catch((error) => {
            dispatch(Creators.getUsersError(error));
        });
    };
};

const assignClient = (id, data) => {
    return dispatch => {
        dispatch(Creators.isLoadingAssignClient());
        assignClients(id, data)
            .then(res => {
                dispatch(Creators.assignClientSuccess(id, data.clientId));
            })
            .catch(err => {
                dispatch(Creators.assignClientError(err))
            })
    };
};

const unAssignClient = (id, data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingUnAssignClient());
        unAssignClients(id, data)
            .then(res => {
                dispatch(Creators.unAssignClientSuccess(id, data.clientId))
            })
            .catch(err => {
                dispatch(Creators.unAssignClientError(err))
            })
    };
};

const assignProject = (id, data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingAssignProject());
        assignProjects(id, data).then(res => {
            dispatch(Creators.assignProjectSuccess(id, data));
        }).catch((error) => {
            dispatch(Creators.assignProjectError(error));
        });
    };
};

const unAssignProject = (id, data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingUnAssignProject());
        unAssignProjects(id, data).then(res => {
            dispatch(Creators.unAssignProjectSuccess(id, data));
        }).catch((error) => {
            dispatch(Creators.unAssignProjectError(error));
        });
    };
};

const updateUser = (id, data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingUserUpdating());
        updateUserProfile(id, data).then(res => {
            dispatch(Creators.usersUpdateSuccess(id, data));
        }).catch((error) => {
            dispatch(Creators.usersUpdateError(error));
        });
    }
}

const addUser = (data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingUser());
        addUsers(data).then((response) => {
            dispatch(Creators.addUserSuccess(response));
        }).catch((error) => {
            dispatch(Creators.addUserError(error));
        });
    };
}

export const { Types, Creators } = createActions({
    getUsers,
    updateUser,
    assignClient,
    assignClientSuccess: ['userId', 'clientId'],
    assignClientError: ['error'],
    isLoadingAssignClient: [],
    assignProject,
    assignProjectSuccess: ['userId', 'data'],
    assignProjectError: ['error'],
    isLoadingAssignProject: [],
    unAssignClient,
    unAssignClientSuccess: ['userId', 'clientId'],
    unAssignClientError: ['error'],
    isLoadingUnAssignClient: [],
    unAssignProject,
    unAssignProjectSuccess: ['userId', 'data'],
    unAssignProjectError: ['error'],
    isLoadingUnAssignProject: [],
    getUsersSuccess: ['users'],
    getUsersError: ['error'],
    usersUpdateSuccess: ['id', 'data'],
    usersUpdateError: ['error'],
    isLoadingUsers: [],
    isLoadingUserUpdating: [],
    usersUpdateErrorClear: [],
    isLoadingUser: [],
    addUser,
    addUserSuccess: ['user'],
    addUserError: ['userError'],
});

const initialState = {
    users: [],
    clients: [],
    isError: false,
    isLoading: false,
    isLoadingUser: false,
    isErrorUser: false,
    isUserUpdateError: null,
    isUserUpdating: false,
    isLoadingAssignClient: false,
    isLoadingAssignProject: false,
    isLoadingUnAssignClient: false,
    isLoadingUnAssignProject: false,
};

const getUsersSuccess = (state = initialState, action) => {
    return {
        ...state,
        ...action.users,
        isLoading: false,
        isError: '',
    };
};

const getUsersError = (state = initialState, action) => {
    return {
        ...state,
        users: [], clients: [],
        isLoading: false,
        isError: action.error,
    };
};

const assignClientSuccess = (state = initialState, action) => {
    const clientIndex = state.clients.findIndex(a => a._id === action.clientId);
    if (clientIndex < 0) {
        return {
            ...state,
            isLoadingAssignClient: false,
            isError: '',
        };
    }
    const client = state.clients[clientIndex];
    client.assignees = client.assignees.concat(action.userId);
    return {
        ...state,
        clients: Object.assign(state.clients, { [clientIndex]: client }),
        isLoadingAssignClient: false,
        isError: '',
    };
};

const assignClientError = (state = initialState, action) => {
    return {
        ...state,
        // users: { users: [], clients: [] },
        isLoadingAssignClient: false,
        isError: action.error,
    };
};

const unAssignClientSuccess = (state = initialState, action) => {
    const clientIndex = state.clients.findIndex(p => p._id === action.clientId);
    if (clientIndex < 0) {
        return {
            ...state,
            isLoadingUnAssignClient: false,
            isError: '',
        };
    }
    const client = state.clients[clientIndex];
    client.assignees = client.assignees.filter(p => p !== action.userId);
    return {
        ...state,
        clients: Object.assign(state.clients, { [clientIndex]: client }),
        isLoadingUnAssignClient: false,
        isError: '',
    };
};

const unAssignClientError = (state = initialState, action) => {
    return {
        ...state,
        isLoadingUnAssignClient: false,
        isError: action.error,
    };
};

const isLoadingAssignClient = (state = initialState, action) => {
    return {
        ...state,
        isLoadingAssignClient: true,
        isError: '',
    };
};

const assignProjectSuccess = (state = initialState, action) => {
    // if (!state.clients) return state;
    const clientIndex = state.clients.findIndex(a => a._id === action.data.clientId);
    if (clientIndex < 0) {
        return {
            ...state,
            isLoadingAssignProject: false,
            isError: '',
        };
    }
    const client = state.clients[clientIndex];
    const projectIndex = client.data.findIndex(a => a._id === action.data.projectId);
    // const project = client.data[projectIndex];
    if (!client.data[projectIndex].assignees.includes(action.userId))
        client.data[projectIndex].assignees = client.data[projectIndex].assignees.concat(action.userId);
    return {
        ...state,
        clients: Object.assign(state.clients, { [clientIndex]: client }),
        isLoadingAssignProject: false,
        isError: '',
    };
};

const assignProjectError = (state = initialState, action) => {
    return {
        ...state,
        // users: null,
        isLoadingAssignProject: false,
        isError: action.error,
    };
};

const unAssignProjectSuccess = (state = initialState, action) => {
    const clientIndex = state.clients.findIndex(p => p._id === action.data.clientId);
    if (clientIndex < 0) {
        return {
            ...state,
            isLoadingUnAssignProject: false,
            isError: '',
        };
    }
    const client = state.clients[clientIndex];
    const projectIndex = client.data.findIndex(a => a._id === action.data.projectId);
    // const project = client.data[projectIndex];
    client.data[projectIndex].assignees = client.data[projectIndex].assignees.filter(p => p !== action.userId);
    return {
        ...state,
        clients: Object.assign(state.clients, { [clientIndex]: client }),
        isLoadingUnAssignProject: false,
        isError: '',
    };
};

const unAssignProjectError = (state = initialState, action) => {
    return {
        ...state,
        // users: null,
        isLoadingUnAssignProject: false,
        isError: action.error,
    };
};

const isLoadingAssignProject = (state = initialState, action) => {
    return {
        ...state,
        isLoadingAssignProject: true,
        isError: '',
    };
};


const isLoadingUnAssignProject = (state = initialState, action) => {
    return {
        ...state,
        isLoadingUnAssignProject: true,
        isError: '',
    };
};

const isLoadingUnAssignClient = (state = initialState, action) => {
    return {
        ...state,
        isLoadingUnAssignClient: true,
        isError: '',
    };
};

const usersUpdateSuccess = (state = initialState, action) => {
    const data = [...state.users];
    const index = data.findIndex(u => u._id === action.id);
    data[index] = {
        ...data[index],
        ...action.data
    };

    return {
        ...state,
        users: data,
        isUserUpdating: false,
        isUserUpdateError: '',
    };
};

const usersUpdateError = (state = initialState, action) => {
    return {
        ...state,
        isUserUpdating: false,
        isUserUpdateError: action.error,
    };
};

const usersUpdateErrorClear = (state = initialState) => {
    return {
        ...state,
        isError: '',
        isUserUpdateError: null,
    };
};

const isLoadingUsers = (state = initialState, action) => {
    return {
        ...state,
        isLoading: true,
        isError: '',
    };
};
const isLoadingUserUpdating = (state = initialState, action) => {
    return {
        ...state,
        isUserUpdating: true,
        isUserUpdateError: '',
    };
};

const isLoadingUser = (state = initialState, action) => {
    return {
        ...state,
        isLoadingUser: true,
        isErrorUser: '',
    };
};

const addUserSuccess = (state = initialState, action) => {
    return {
        ...state,
        users: [...state.users, action.user],
        isLoadingUser: false,
        isErrorUser: '',
    };
};

const addUserError = (state = initialState, action) => {
    return {
        ...state,
        isLoadingUser: false,
        isErrorUser: action.userError,
    };
};

export default createReducer(initialState, {
    [Types.GET_USERS]: getUsers,
    [Types.UPDATE_USER]: updateUser,
    [Types.GET_USERS_SUCCESS]: getUsersSuccess,
    [Types.GET_USERS_ERROR]: getUsersError,
    [Types.USERS_UPDATE_SUCCESS]: usersUpdateSuccess,
    [Types.USERS_UPDATE_ERROR]: usersUpdateError,
    [Types.IS_LOADING_USERS]: isLoadingUsers,
    [Types.ASSIGN_CLIENT]: assignClient,
    [Types.ASSIGN_CLIENT_SUCCESS]: assignClientSuccess,
    [Types.ASSIGN_CLIENT_ERROR]: assignClientError,
    [Types.IS_LOADING_ASSIGN_CLIENT]: isLoadingAssignClient,
    [Types.ASSIGN_PROJECT_SUCCESS]: assignProjectSuccess,
    [Types.ASSIGN_PROJECT_ERROR]: assignProjectError,
    [Types.IS_LOADING_ASSIGN_PROJECT]: isLoadingAssignProject,
    [Types.UN_ASSIGN_CLIENT]: unAssignClient,
    [Types.UN_ASSIGN_CLIENT_SUCCESS]: unAssignClientSuccess,
    [Types.UN_ASSIGN_CLIENT_ERROR]: unAssignClientError,
    [Types.IS_LOADING_UN_ASSIGN_CLIENT]: isLoadingUnAssignClient,
    [Types.UN_ASSIGN_PROJECT_SUCCESS]: unAssignProjectSuccess,
    [Types.UN_ASSIGN_PROJECT_ERROR]: unAssignProjectError,
    [Types.IS_LOADING_UN_ASSIGN_PROJECT]: isLoadingUnAssignProject,
    [Types.IS_LOADING_USER_UPDATING]: isLoadingUserUpdating,
    [Types.USERS_UPDATE_ERROR_CLEAR]: usersUpdateErrorClear,
    [Types.IS_LOADING_USER]: isLoadingUser,
    [Types.ADD_USER]: addUser,
    [Types.ADD_USER_SUCCESS]: addUserSuccess,
    [Types.ADD_USER_ERROR]: addUserError,

});
