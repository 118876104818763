import React, { useEffect, useMemo, useState } from 'react'
import { Button } from 'reactstrap';
import BaseReactTable from '../../../../shared/components/table/BaseReactTable';
import { useHistory, useLocation, useParams } from 'react-router';
import { Creators as usersDuck } from '../../../../store/ducks/users';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';

export default function AssignProject(props) {
  const history = useHistory();
  const {id:clientId} = useParams();
  const { state: { projectId } } = useLocation();
  const { users, clients: clientData, isLoadingAssignProject, isLoadingUnAssignProject } = useSelector(state => state.users) || {};
  const projectManagers = users.filter(u => u.role === 'ProjectManager');
  const selectedClient = clientData?.find(c => c._id === clientId);
  const project = selectedClient?.data?.find(p => p._id === projectId);
  const assignees = project?.assignees || [];
  const projectName = project?.label
  const dispatch = useDispatch();
  const [projectIndex, setProjectIndex] = useState(0);

  if (!history.location.state) history.goBack()

  const removeProjectHandler = (userId, index) => {
    setProjectIndex(index);
    dispatch(usersDuck.unAssignProject(userId, { projectId, clientId: clientId }));
  }

  const assignProjectHandler = (userId, index) => {
    setProjectIndex(index);
    dispatch(usersDuck.assignProject(userId, { projectId, clientId: clientId }));
  }

  const columns = useMemo(() => [
    { accessor: 'users', Header: 'USERS', disableGlobalFilter: true, disableSortBy: true, },
    { accessor: 'date_last_active', Header: 'Date Last Active', },
    { accessor: ' inProgressProjects', Header: 'In Progress Projects', },
    { accessor: 'assign', Header: '', disableSortBy: true, },
  ], []);

  useEffect(() => {
    dispatch(usersDuck.getUsers());
  }, []);

 
  const data = projectManagers.map((p, index) => {
    const assigneesProject = assignees?.find(a => a === p._id);
    return {
      users: [(<td className="d-flex">
        <img src={p.image} className="user-profile-icons" alt="UserProfileIcon" />
        <div className="d-flex flex-column">
          <h2 className="user-name-title">{p.name}</h2>
          <h2 className="user-name-description">{p.role}</h2>
        </div>
      </td>)],
      date_last_active: moment(p.lastLogin).format('DD-MMM-YYYY'),
      // projects: ['Maria', 'Bobby  ', 'Alexander'][Math.floor((Math.random() * 3))],
      inProgressProjects: 0,
      assign: [
        assigneesProject ?
          <Button color="primary" className="btn btn-primary btn-block px-0 mb-0 w-75"
            onClick={() => removeProjectHandler(p._id, index)}>{isLoadingUnAssignProject && projectIndex === index ?
              <ClipLoader
                size={20}
                color={"#fff"}
                loading={true}
              /> : 'Remove'
            }</Button>
          :
          <Button color="success" className="btn btn-success btn-block px-0 mb-0 w-75"
            onClick={() => assignProjectHandler(p._id, index)}>{isLoadingAssignProject && projectIndex === index ?
              <ClipLoader
                size={20}
                color={"#fff"}
                loading={true}
              /> : 'Assign'
            }</Button>
      ],
    }
  })
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };
  return (
    <div>
      <div className="row m-0">
        <div>
          <h2 className="assign-project-title">Assign Project Managers to {projectName}</h2>
          <h4 className="assign-project-sub-title">Assign or remove project managers to the project.</h4>
        </div>
        <div className="ml-auto">
          <Button color="primary" className="btn btn-primary btn-block mb-0" onClick={history.goBack} >Back</Button>
        </div>
      </div>
      <div className="assign-project-table mt-4">
        <BaseReactTable
          columns={columns}
          data={data}
          tableConfig={tableConfig}
          onRowClick={() => console.log("test")} />
      </div>
    </div>
  )
}
