import React, { useEffect, useMemo } from 'react'
import BaseReactTable from '../../../../../shared/components/table/BaseReactTable';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { useHistory, useParams } from 'react-router';
import Avatar from '../../../../../assets/avatar.png';
import { Creators as usersDuck } from "../../../../../store/ducks/users";

export default function ProjectManager(props) {
  const { id: clientId } = useParams();
  const { clients, users, isLoading } = useSelector(state => state.users) || [];
  const userMap = users.reduce((p, c) => ({ ...p, [c._id]: c }), {})
  const history = useHistory();
  const selectedClient = clients.find(c => c._id === clientId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersDuck.getUsers());
  }, []);

  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const columns = useMemo(() => [
    { accessor: 'project_name', Header: 'Project Name', width: 80, disableGlobalFilter: true, },
    { accessor: 'date_created', Header: 'Date Created', },
    { accessor: 'totalProjects', Header: 'Projects', },
    { accessor: 'project_managers', Header: 'Project Managers', },
    { accessor: 'status', Header: 'Status', },
    { accessor: 'manage', Header: '', disableGlobalFilter: true, disableSortBy: true, width: 110, },
  ], []);


  const data = selectedClient?.data?.map((c, index) => {
    return ({
      project_name: c.label,
      date_created: moment(c.createdAt).format('DD-MMM-YYYY'),
      projects: c.label,
      project_managers: [(<td className="overlapping-img">
        <ul>
          {c.assignees.map(a => <li><img src={userMap[a]?.image ? userMap[a]?.image : Avatar} alt="" title={userMap[a]?.name} /></li>)}
        </ul>
      </td>)],
      status: c.status,
      manage: [
        <Button color="primary" className="btn btn-primary btn-block px-0 mb-0 w-75"
          onClick={() => history.push(`${clientId}/project-manage`, {
            projectId: c._id,
            assignees: c.assignees
          })}>Manage</Button>
      ],
    })
  })
  return (
    <div>
      {isLoading ?
        <div className="p-5 d-flex  my-auto justify-content-center align-items-center">
          <ClipLoader size={40} color={"#4ce1b6"} loading={true} />
        </div> :
        data && !!data.length ?
          <BaseReactTable
            searchPlaceholder='Search Client'
            columns={columns}
            data={data}
            tableConfig={tableConfig}
            onRowClick={(e, i) => console.log(i)}
          />
          :
          <div className="d-flex align-items-center justify-content-center">
            <h4>Create a new project to assign project managers</h4>
          </div>
      }
    </div>
  )
};
