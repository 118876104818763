import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Setting from "./Setting";
import Assignments from "./Assignments";
import Activity from "./Activity";

export default function UserTabs(props) {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Col>
        <Card>
          <CardBody className="p-0">
            <div className="tabs tabs--bordered-bottom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => toggle("1")}>
                      ASSIGNMENTS
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === "2" })} onClick={() => toggle("2")}>
                      TIMELINE
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === "3" })} onClick={() => toggle("3")}>
                      SETTINGS
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Assignments user={props.user} />
                  </TabPane>
                  <TabPane tabId="2">
                    <Activity user={props.user} goBack={(tab) => toggle(tab)} />
                  </TabPane>
                  <TabPane tabId="3">
                    <Setting user={props.user} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}
