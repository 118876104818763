import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import DoneIcon from 'mdi-react/TickIcon';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonGroup, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown } from 'reactstrap'
import { formatCurrency, groupBy } from '../../../utils';
import { Creators as suggestionsDuck } from "../../../store/ducks/suggestions";
import OnPremiseEstimatesCharts from "./OnPremiseEstimatesCharts";

const OPTIONS = ["Once Off", "Monthly", "Yearly"];
const OPTION_DATA = {
    "Once Off": "once off", "Monthly": "per month", "Yearly": "per year"
};
export default function OnPremiseEstimates({ setTotalCost,setDoughnutChartData,editable, clientData, suggestionsData, assumptionsData, totalMonths, ...props }) {
    const vCenterQuantity = Object.values(groupBy(clientData, "vCentre"))?.length;
    const [onPremiseAssumptions, setOnPremiseAssumptions] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [customChargeData, setCustomChargeData] = useState({ duration: "Monthly", amount: '', description: '' });
    const dispatch = useDispatch();
    const [dataCentersCalculations, setDataCentersCalculations] = useState([]);

    useEffect(() => {
        const dataCenter = groupBy(clientData, "dataCentre");

        if (onPremiseAssumptions && onPremiseAssumptions.length) {
            const calculationPerDataCenter = Object.keys(dataCenter).map(dataCenterKey => {
                const selectedClientServers = dataCenter[dataCenterKey];
                const onPremiseAssumptionsObject = onPremiseAssumptions?.reduce((p, c) => {
                    if (c.name === "charges") {
                        if (c.dataCenter === dataCenterKey) return { ...p, [c.name]: c }
                        else return p;
                    } else
                        return { ...p, [c.name]: c };
                }, {});
                const serverSpecsData = onPremiseAssumptionsObject?.specs?.attributes?.reduce((p, c) => ({ ...p, [c.name]: +c.value }), {})
                const basicData = selectedClientServers?.reduce((p, c) => ({ vCpu: p.vCpu + +c?.vCPU, cores: p.cores + +c?.cores, memory: p.memory + +c?.memory, storage: p.storage + +c?.storageallocated }), { memory: 0, vCpu: 0, storage: 0, cores: 0 });
                const totalPhysicalCores = Math.ceil((basicData.vCpu / 2) / serverSpecsData?.cores);
                const totalMemory = Math.ceil(basicData.memory / serverSpecsData?.memory);
                const serverQuantity = Math.max(totalPhysicalCores, totalMemory) + 1;
                const monthlyDataCenterCosts = +onPremiseAssumptionsObject?.monthlyDataCenterCosts?.attributes?.find(a => a.name === "monthlyDataCenterCosts")?.value;
                const monthlyLabourCosts = +onPremiseAssumptionsObject?.labourCosts?.attributes?.find(a => a.name === "labourCosts")?.value;
                const serverTotalCors = serverSpecsData?.cores * serverQuantity;
                const serverSocketsTotal = serverQuantity * 2;
                const serverMemory = serverSpecsData?.memory * serverQuantity;
                const upfrontServerCost = serverQuantity * (serverSpecsData ? serverSpecsData["Purchase Cost"] : 1);
                const yearlySupportCost = upfrontServerCost / 100 * serverSpecsData?.yearlySupportCost
                const storageSpecsData = onPremiseAssumptionsObject?.storage?.attributes?.reduce((p, c) => ({ ...p, [c.name]: +c.value }), {})
                const totalStorageCost = ((basicData.storage || 0) / 1024) * storageSpecsData?.costPerTb;
                const storageSupportCostYearly = totalStorageCost / 100 * storageSpecsData?.yearlySupportCost;
                const softwareSpecsData = onPremiseAssumptionsObject?.software?.attributes?.reduce((p, c) => ({ ...p, [c.name]: +c.value }), {});
                const totalVMwareCost = (vCenterQuantity * softwareSpecsData?.costPervCenter) + (serverSocketsTotal * softwareSpecsData?.vSphereCostPerSocket);
                const totalVMwareCostYearly = totalVMwareCost / 100 * softwareSpecsData?.vMwareSupportCost;
                const networkSpecsData = onPremiseAssumptionsObject?.infrastructure?.attributes?.reduce((p, c) => {
                    if (c.name === "costPerSwitch") return { ...p, costPerSwitch: +c.value, totalSwitchCost: +c.value };
                    else if (c.name === "switchQuantity") return { ...p, switchQuantity: +c.value, totalSwitchCost: +c.value * p.costPerSwitch };
                    else if (c.name === "switchSupportCost") return { ...p, switchSupportCostPer: +c.value }
                    return p;
                }, { costPerSwitch: 0, switchQuantity: 0, totalSwitchCost: 0, switchSupportCostPer: 0 });
                const yearlySwitchSupportCost = networkSpecsData?.totalSwitchCost / 100 * networkSpecsData?.switchSupportCostPer;
                const customCharges = onPremiseAssumptionsObject && onPremiseAssumptionsObject.charges?.attributes.reduce((p, c) => ({
                    monthly: p.monthly + (c.inputType === "Monthly" ? +c.value : 0),
                    yearly: p.yearly + (c.inputType === "Yearly" ? +c.value : 0),
                    upfront: p.upfront + (c.inputType === "Once Off" ? +c.value : 0),
                }), { monthly: 0, yearly: 0, upfront: 0 });
                return {
                    serverSpecsData,
                    totalStorageCost,
                    totalVMwareCost,
                    networkSpecsData,
                    serverQuantity,
                    upfrontServerCost,
                    storageSpecsData,
                    basicData,
                    monthlyDataCenterCosts,
                    monthlyLabourCosts,
                    serverTotalCors,
                    serverMemory,
                    yearlySupportCost,
                    storageSupportCostYearly,
                    totalVMwareCostYearly,
                    yearlySwitchSupportCost,
                    customCharges,
                    softwareSpecsData,
                    serverSocketsTotal,
                    dataCenterName: dataCenterKey
                }
            });
            setDataCentersCalculations(calculationPerDataCenter);
        }
    }, [onPremiseAssumptions]);

    useEffect(() => {
        if (assumptionsData)
            setOnPremiseAssumptions(assumptionsData.filter(m => m.attributeType === "onPremiseServer"));
    }, [assumptionsData])

    const handleChange = ({ target: { value, name } }) => {
        setCustomChargeData(p => ({ ...p, [name]: value }));
    }

    const handleSaveCustomCharge = (dataCenter) => {
        const data = {
            inputType: customChargeData.duration,
            description: customChargeData.description,
            type: OPTION_DATA[customChargeData.duration],
            value: customChargeData.amount,
        };
        if (!onPremiseAssumptions.find(a => a.name === "charges" && a.dataCenter === dataCenter)) {
            setOnPremiseAssumptions(a => [...a, {
                attributeType: "onPremiseServer",
                attributes: [data],
                enumerations: [],
                label: "Custom Charges",
                name: "charges",
                dataCenter,
                searchThreshold: [],
                tooltip: "On Premise Server Custom charges"
            }]);
        } else {
            const allData = Object.assign([], onPremiseAssumptions);
            const index = allData.findIndex(a => a.name === "charges" && a.dataCenter === dataCenter);
            setOnPremiseAssumptions(Object.assign(allData, {
                [index]: { ...allData[index], attributes: allData[index].attributes.concat(data) }
            }));
        }
        dispatch(suggestionsDuck.updateAssumptions(data, dataCenter));
    }
    const handleRemoveCharge = (dataCenter, index) => {
        const allData = Object.assign([], onPremiseAssumptions);
        const dataIndex = allData.findIndex(a => a.name === "charges" && dataCenter === a.dataCenter);
        if (dataIndex >= 0) {
            allData[dataIndex].attributes.splice(index, 1);
            setOnPremiseAssumptions(allData);
            dispatch(suggestionsDuck.deleteAssumptionAttribute(index, dataCenter));
        }
    }


    return (
        <>
            <OnPremiseEstimatesCharts setTotalCost={setTotalCost} setDoughnutChartData={setDoughnutChartData} calculations={dataCentersCalculations} totalMonths={totalMonths} />
            <div className="m-5">
                <span className="premise-title">On Premise Estimates</span>
                {
                    dataCentersCalculations.map((dataCenter, i) => {
                        return (
                            <div className="pl-4 shadow-sm mt-3">
                                <span className="premise-title pt-5">{dataCenter.dataCenterName}</span>
                                <div className="row align-items-center pl-3">
                                    <span className="detail-text">Total vCPU : {dataCenter.basicData.vCpu || 0}</span>
                                    <span className="ml-5 detail-text">Physical Cores : {Math.ceil(dataCenter.basicData.cores / 2) || 0}</span>
                                    <span className="ml-5 detail-text">Memory : {dataCenter.basicData.memory || 0} Gb</span>
                                    <span className="ml-5 detail-text">Storage : {dataCenter.basicData.storage || 0} Gb</span>
                                </div>
                                <Row>
                                    <Col className="col-6">
                                        <Row className="mt-3">
                                            <Col>
                                                <span className="premise-text">Servers</span>
                                                <Row>
                                                    <span className="ml-3 totals">Total Servers: {dataCenter.serverQuantity || 0}</span>
                                                    <span className="ml-3 totals">Total Cores: {Math.ceil(dataCenter.serverTotalCors / 2) || 0}</span>
                                                    <span className="ml-3 totals">Total Sockets: {dataCenter.serverSocketsTotal || 0}</span>
                                                    <span className="ml-3 totals">Total Memory: {dataCenter.serverMemory || 0} Gb</span>
                                                </Row>
                                                <Row className='flex-nowrap'>
                                                    <span className="ml-3 mr-1 premise-text">upfront Server Cost : </span>
                                                    <span>{`${formatCurrency(dataCenter.upfrontServerCost)} (${dataCenter.serverQuantity} Servers x ${formatCurrency(dataCenter.serverSpecsData ? dataCenter.serverSpecsData["Purchase Cost"] : 0)} per server)`}</span>
                                                </Row>
                                                <Row>
                                                    <span className="ml-3 mr-1 premise-text">Yearly Server Support Cost : </span>
                                                    <span>{`${formatCurrency(dataCenter.yearlySupportCost)} (${formatCurrency(dataCenter.upfrontServerCost)} x ${dataCenter.serverSpecsData?.yearlySupportCost}% yearly support cost)`}</span>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {
                                            onPremiseAssumptions?.map(p => {
                                                switch (p.name) {
                                                    case "software": {
                                                        return (
                                                            <Row className="mt-3" key={p.name}>
                                                                <Col>
                                                                    <span className="premise-text">{p.label}</span>
                                                                    <Row>
                                                                        <span className="ml-3 mr-1 totals">Total vSphere Cost : </span>
                                                                        <span>{`${formatCurrency(dataCenter.serverSocketsTotal * dataCenter.softwareSpecsData?.vSphereCostPerSocket)} (${dataCenter.serverSocketsTotal} Server Sockets Total x ${formatCurrency(dataCenter.softwareSpecsData?.vSphereCostPerSocket)} vSphere Cost Per Socket )`}</span>
                                                                    </Row>
                                                                    <Row>
                                                                        <span className="ml-3 mr-1 totals">Total vCentre Cost : </span>
                                                                        <span>{`${formatCurrency(vCenterQuantity * dataCenter.softwareSpecsData?.costPervCenter)} (${vCenterQuantity} vCentre Quantity x ${formatCurrency(dataCenter.softwareSpecsData?.costPervCenter)}  per vCentre Cost)`}</span>
                                                                    </Row>
                                                                    <Row>
                                                                        <span className="ml-3 premise-text">Total VMware Cost :</span>
                                                                        <span>{` ${formatCurrency(dataCenter.totalVMwareCost)} (${formatCurrency(vCenterQuantity * dataCenter.softwareSpecsData?.costPervCenter)} Total vCentre Cost + ${formatCurrency(dataCenter.serverSocketsTotal * dataCenter.softwareSpecsData?.vSphereCostPerSocket)} vSphere Total Cost)`}</span>
                                                                    </Row>
                                                                    <Row>
                                                                        <span className="ml-3 premise-text">VMware Support Cost Yearly : </span>
                                                                        <span>{` ${formatCurrency(dataCenter.totalVMwareCostYearly)} (${formatCurrency(dataCenter.totalVMwareCost)} Total VMware Cost x ${dataCenter.softwareSpecsData?.vMwareSupportCost}% VMware Support)`}</span>
                                                                    </Row>

                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                    case "infrastructure": {
                                                        return (
                                                            <Row className="mt-3">
                                                                <Col>
                                                                    <span className="premise-text">{p.label}</span>
                                                                    <Row>
                                                                        <span className="ml-3 mr-1 totals">{`Total Switch Cost : `}</span>
                                                                        <span>{`${formatCurrency(dataCenter.networkSpecsData?.totalSwitchCost)} (${formatCurrency(dataCenter.networkSpecsData?.costPerSwitch)} Per switch cost x ${dataCenter.networkSpecsData?.switchQuantity} Switch quantity)`}</span>
                                                                    </Row>
                                                                    <Row>
                                                                        <span className="ml-3 mr-1 totals">{`Total Switch Support Cost Yearly : `}</span>
                                                                        <span>{`${formatCurrency(dataCenter.yearlySwitchSupportCost)} (${formatCurrency(dataCenter.networkSpecsData?.totalSwitchCost)} Total switch cost x ${dataCenter.networkSpecsData?.switchSupportCostPer}% switch support)`}</span>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                    case "storage": {
                                                        return (
                                                            <Row className="mt-3">
                                                                <Col>
                                                                    <span className="premise-text">{p.label}</span>
                                                                    <Row>
                                                                        <span className="ml-3 mr-1 premise-text">Total Storage Cost : </span>
                                                                        <span>{`${formatCurrency(dataCenter.totalStorageCost)} (${((dataCenter.basicData.storage || 0) / 1024).toFixed(2)}TB x ${formatCurrency(dataCenter.storageSpecsData.costPerTb)} Cost per TB)`}</span>
                                                                    </Row>
                                                                    <Row>
                                                                        <span className="ml-3 mr-1 premise-text">Storage Support Cost Yearly :</span>
                                                                        <span>{`${formatCurrency(dataCenter.storageSupportCostYearly)} (${formatCurrency(dataCenter.totalStorageCost)} Total Storage cost x ${dataCenter.storageSpecsData.yearlySupportCost}% Storage Support Cost Yearly)`}</span>
                                                                    </Row>
                                                                </Col>

                                                            </Row>
                                                        )
                                                    }

                                                    default: {
                                                        return null;
                                                    }
                                                }
                                            })
                                        }

                                        <Row className="mt-3">
                                            <Col>
                                                <span className="premise-text">Data Centre</span>
                                                <Row>
                                                    <span className="ml-3 mr-1 premise-text">Monthly Data Centre Cost : </span>
                                                    <span>{`${formatCurrency(dataCenter.monthlyDataCenterCosts)}`}</span>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <span className="premise-text">Data Centre</span>
                                                <Row>
                                                    <span className="ml-3 mr-1 premise-text">Monthly Management & Labour Cost: </span>
                                                    <span>{formatCurrency(dataCenter.monthlyLabourCosts)}</span>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <span className="premise-text">Custom Charges</span>
                                                {editable && <Row>
                                                    <Col className='col-12'>
                                                        <div className={`d-flex align-items-center p-2 cursor-pointer add-custom-btn ${isExpanded === i && 'mb-0'}`} onClick={() => {
                                                            setCustomChargeData({ duration: "Monthly", amount: '', description: '' });
                                                            setIsExpanded(p => p === i ? null : i);
                                                        }}>
                                                            <div className='add-custom-btn-icon'>
                                                                {isExpanded === i ? <MinusIcon color="#ffffff" style={{ height: "24px" }} /> : <PlusIcon color="#ffffff" style={{ height: "24px" }} />}
                                                            </div>
                                                            <span className='ml-1'> Add custom charge </span>
                                                        </div>
                                                    </Col>
                                                    <Collapse isOpen={isExpanded === i}>
                                                        <Col className='col-12'>
                                                            <div className='d-flex align-items-center p-2'>
                                                                <div className='mr-2' style={{ minWidth: "20%" }}>
                                                                    <UncontrolledDropdown id='drop-down'>
                                                                        <ButtonGroup dir="ltr" className="w-100 m-0">
                                                                            <DropdownToggle style={{ minWidth: "fit-content", maxWidth: "100%" }} outline className="validation-icon icon--right rounded-0 assumption-drop-btn m-0">
                                                                                <span className='mr-2 text-nowrap'>{customChargeData.duration}</span>
                                                                                <ChevronDownIcon className='ml-auto' />
                                                                            </DropdownToggle>
                                                                        </ButtonGroup>
                                                                        <DropdownMenu right className="dropdown__menu custom-dropdown-menu">
                                                                            {OPTIONS.map((option) => (
                                                                                <DropdownItem
                                                                                    onClick={() => setCustomChargeData(p => ({ ...p, duration: option }))}
                                                                                    name="pricingModel"
                                                                                    key={option}>
                                                                                    {option}
                                                                                </DropdownItem>
                                                                            ))}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                                <div className="mx-2">
                                                                    <Input
                                                                        onChange={handleChange}
                                                                        value={customChargeData.amount}
                                                                        placeholder='Amount'
                                                                        name='amount'
                                                                        type="number"
                                                                        inputmode="numeric"
                                                                        className="w-100 text-center custom-charge-input" />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <Input
                                                                        value={customChargeData.description}
                                                                        onChange={handleChange}
                                                                        name='description'
                                                                        placeholder='Description'
                                                                        className="w-100 text-center custom-charge-input" />
                                                                </div>
                                                                <button
                                                                    disabled={
                                                                        !customChargeData.description ||
                                                                        !customChargeData.amount
                                                                    } className='ml-2 cursor-pointer add-charge-btn'
                                                                    onClick={() => handleSaveCustomCharge(dataCenter.dataCenterName)}>
                                                                    <DoneIcon color="#00d200" />
                                                                </button>
                                                                <div className='ml-2 cursor-pointer'>
                                                                    <DeleteIcon color="#dc3545" onClick={() => setCustomChargeData({ duration: "Monthly", amount: '', description: '' })} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Collapse>
                                                </Row>}
                                                {onPremiseAssumptions?.filter(a => a.name === "charges" && dataCenter.dataCenterName === a.dataCenter)?.map(p => (
                                                    p.attributes.map((a, j) => (
                                                        <Row>
                                                            <Col className='col-4'>
                                                                <span className="ml-3 premise-text">{a.description}</span>
                                                            </Col>
                                                            <Col className='col-4'>
                                                                <span className="ml-2"> {`${formatCurrency(+a.value)} ${a.type}`} </span>
                                                            </Col>
                                                            {editable && <Col className='col-4'>
                                                                <div className='ml-2 cursor-pointer'>
                                                                    <DeleteIcon color="#dc3545" onClick={() => handleRemoveCharge(dataCenter.dataCenterName, j)} />
                                                                </div>
                                                            </Col>}
                                                        </Row>
                                                    ))
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-6">
                                        <span className="premise-title">Totals</span>
                                        <div className="total-container">
                                            <span className="totals">Monthly Data Centre cost : {formatCurrency(dataCenter.monthlyDataCenterCosts)}</span>
                                            <span className="totals">Monthly Management & Labour cost : {formatCurrency(dataCenter.monthlyLabourCosts)}</span>
                                            <span className="totals">Monthly Custom Charges cost : {formatCurrency((dataCenter.customCharges?.monthly || 0))}</span>
                                            <span className="premise-text">Monthly Total : {formatCurrency(dataCenter.monthlyDataCenterCosts + dataCenter.monthlyLabourCosts + (dataCenter.customCharges?.monthly || 0))}</span>
                                        </div>
                                        <div className="total-container mt-2">
                                            <span className="totals">Upfront Server cost : {formatCurrency(dataCenter.upfrontServerCost)}</span>
                                            <span className="totals">Upfront Storage cost : {formatCurrency(dataCenter.totalStorageCost)}</span>
                                            <span className="totals">Upfront VMware cost : {formatCurrency(dataCenter.totalVMwareCost)}</span>
                                            <span className="totals">Upfront Switch cost : {formatCurrency(dataCenter.networkSpecsData?.totalSwitchCost)}</span>
                                            <span className="totals">Upfront Custom Charges : {formatCurrency((dataCenter.customCharges?.upfront || 0))}</span>
                                            <span className="premise-text">Upfront Total : {formatCurrency(dataCenter.upfrontServerCost + dataCenter.totalStorageCost + dataCenter.totalVMwareCost + dataCenter.networkSpecsData?.totalSwitchCost + (dataCenter.customCharges?.upfront || 0))}</span>
                                        </div>
                                        <div className="total-container mt-2">
                                            <span className="totals">Yearly Server Support cost : {formatCurrency(dataCenter.yearlySupportCost)}</span>
                                            <span className="totals">Yearly Storage Support cost : {formatCurrency(dataCenter.storageSupportCostYearly)}</span>
                                            <span className="totals">Yearly vMware Support cost : {formatCurrency(dataCenter.totalVMwareCostYearly)}</span>
                                            <span className="totals">Yearly Switch Support cost : {formatCurrency(dataCenter.yearlySwitchSupportCost)}</span>
                                            <span className="totals">Yearly Custom Charges : {formatCurrency((dataCenter.customCharges?.yearly || 0))}</span>
                                            <span className="premise-text">Yearly Total : {formatCurrency(dataCenter.yearlySupportCost + dataCenter.storageSupportCostYearly + dataCenter.totalVMwareCostYearly + dataCenter.yearlySwitchSupportCost + + (dataCenter.customCharges?.yearly || 0))}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
