import React, { Fragment, useEffect, useState } from 'react'
import {
  Button,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { ClipLoader } from "react-spinners";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as enumsDuck } from "../../../../../store/ducks/enums";
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PercentIcon from 'mdi-react/PercentOutlineIcon';
import AbsoluteIcon from 'mdi-react/PinIcon';
import PeakIcon from 'mdi-react/PeakIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import Cost from 'mdi-react/CurrencyUsdIcon';
import Range from '../../../../../shared/components/range_slider/Range';
import Info from 'mdi-react/InfoCircleOutlineIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { useMediaQuery } from 'react-responsive'
import AddCircleOutlineIcon from "mdi-react/AddCircleIcon";
import classnames from 'classnames';

const options = [{ value: 'peakCPU', label: 'Peak CPU' }, { value: 'peakMemory', label: 'Peak Memory' },];
const searchCriteriaOptions = [{ value: 'percentage', label: 'Percentage', icon: PercentIcon }, {
  value: 'absolute',
  label: 'Absolute',
  icon: AbsoluteIcon
}, { value: 'peak', label: 'Peak', icon: PeakIcon }];
const operaterOptions = [
  { value: '$in', label: 'in' },
  { value: '$nin', label: 'not in' },
  { value: '$gte', label: '>=' },
  { value: '$lte', label: '<=' },
  { value: '$gt', label: '>' },
  { value: '$lt', label: '<' },
];
const groupColors = ["success", "warning", "danger", "primary", "secondary", "info", "light", "dark", "success", "warning"];

const groupPricingOptions = [
  { value: 'noMigration', year: '', label: 'No Migration' },
  { value: 'OnDemand', year: '', label: 'OnDemand' },
  { value: 'convertible1yrNo Upfront', year: '1', label: 'convertible 1yr No Upfront' },
  { value: 'convertible1yrPartial Upfront', year: '1', label: 'convertible 1yr Partial Upfront' },
  { value: 'convertible1yrAll Upfront', year: '1', label: 'convertible 1yr All Upfront' },
  { value: 'convertible3yrNo Upfront', year: '3', label: 'convertible 3yr No Upfront' },
  { value: 'convertible3yrPartial Upfront', year: '3', label: 'convertible 3yr Partial Upfront' },
  { value: 'convertible3yrAll Upfront', year: '3', label: 'convertible 3yr All Upfront' },
  { value: 'standard1yrNo Upfront', year: '1', label: 'standard 1yr No Upfront' },
  { value: 'standard1yrPartial Upfront', year: '1', label: 'standard 1yr Partial Upfront' },
  { value: 'standard1yrAll Upfront', year: '1', label: 'standard 1yr All Upfront' },
  { value: 'standard3yrNo Upfront', year: '3', label: 'standard 3yr No Upfront' },
  { value: 'standard3yrPartial Upfront', year: '3', label: 'standard 3yr Partial Upfront' },
  { value: 'standard3yrAll Upfront', year: '3', label: 'standard 3yr All Upfront' },
];

const CustomIcon = ({ Icon, ...props }) => <Icon />;

export default function Assumptions({
  isAssumptionPage,
  handleChange: handleChangeData,
  updatedAssumption,
  isServerDetail,
  rows,
  groupsOnchangeHandler,
  utilizationOnchangeHandler,
  updatePricingGroups = () => 0,
  ...props
}) {
  const [assumptionsData, setAssumptionsData] = useState(props.assumptionsData);
  const [pricingMonths, setPricingMonths] = useState(36);
  const [migrationStrategy, setMigrationStrategy] = useState([]);
  const [utilizationGroups, setUtilizationGroups] = useState([]);
  const [isError, setIsError] = useState({});
  const { enums, isLoading: enumsLoading } = useSelector(state => state.enums);
  const { suggestions } = useSelector(state => state.suggestions);
  const [activeTab, setActiveTab] = useState(1);
  const sm = useMediaQuery({ query: '(min-width: 576px)' })
  const md = useMediaQuery({ query: '(min-width: 768px)' })
  const lg = useMediaQuery({ query: '(min-width: 992px)' })
  const xlg = useMediaQuery({ query: '(min-width: 1200px)' })
  const dispatch = useDispatch();

  useEffect(() => {
    if (!enums) {
      dispatch(enumsDuck.getEnums({ names: [] }));
    }
    if (rows && !suggestions) {
      // set utilization group
      if (!suggestions || (suggestions && !suggestions.utilizationGroups && !suggestions.assumptions?.default)) {
        const utilizationGroups = [...new Set(rows.filter(p => p.utilizationGroup).map(p => p.utilizationGroup))];
        const utilization = Object.values(utilizationGroups).map(key => ({
          name: key,
          week: [{ hours: 24, id: 1, value: true, label: "S" }, { hours: 24, id: 2, value: true, label: "M" }, { hours: 24, id: 3, value: true, label: "T" }, { hours: 24, id: 4, value: true, label: "W" }, { hours: 24, id: 5, value: true, label: "T" }, { hours: 24, id: 6, value: true, label: "F" }, { hours: 24, id: 7, value: true, label: "S" }]
        }));
        utilization.forEach(m => utilizationOnchangeHandler(m, m.name));
        setUtilizationGroups(utilization);
      }
      // set migration strategy group
      if (!suggestions || (suggestions && !suggestions.migrationStrategy && !suggestions.assumptions?.default)) {
        const migrationStrategyGroups = [...new Set(rows.filter(p => p.migrationStrategy).map(p => p.migrationStrategy))];
        const migrationStrategy = Object.values(migrationStrategyGroups).map(key => ({
          name: key,
          pricingMonths: 36,
          pricingGroups: [{ reservationTermFrom: 1, reservationTermTo: 36, pricingModel: "OnDemand" }]
        }));
        migrationStrategy.forEach(m => groupsOnchangeHandler(m, m.name));
        setMigrationStrategy(migrationStrategy);
      }
    } else if (suggestions && (suggestions.utilizationGroups && suggestions.migrationStrategy)) {
      if (suggestions.utilizationGroups) {
        setUtilizationGroups(suggestions.utilizationGroups)
      }
      if (suggestions.migrationStrategy) {
        setMigrationStrategy(suggestions.migrationStrategy)
      }
    } else if (suggestions && suggestions.assumptions?.default) {
      setUtilizationGroups(suggestions.assumptions.default.filter(p => p.attributeType === "utilizationGroup"));
      setMigrationStrategy(suggestions.assumptions.default.filter(p => p.attributeType === "migrationStrategy"));
    }
    // if (rows && (!suggestions || (suggestions && !suggestions.utilizationGroups && !suggestions.assumptions?.default))) {
    //   const utilizationGroups = [...new Set(rows.filter(p => p.utilizationGroup).map(p => p.utilizationGroup))];
    //   const utilization = Object.values(utilizationGroups).map(key => ({
    //     name: key,
    //     week: [{ hours: 24, id: 1, value: true, label: "S" }, { hours: 24, id: 2, value: true, label: "M" }, { hours: 24, id: 3, value: true, label: "T" }, { hours: 24, id: 4, value: true, label: "W" }, { hours: 24, id: 5, value: true, label: "T" }, { hours: 24, id: 6, value: true, label: "F" }, { hours: 24, id: 7, value: true, label: "S" }]
    //   }));
    //   utilization.forEach(m => utilizationOnchangeHandler(m, m.name));
    //   setUtilizationGroups(utilization);
    // } else if (suggestions && suggestions.utilizationGroups) {
    //   setUtilizationGroups(suggestions.utilizationGroups)
    // } else if (suggestions && suggestions.assumptions?.default) {
    //   setUtilizationGroups(suggestions.assumptions.default.filter(p => p.attributeType === "utilizationGroup"));
    // }
    // if (rows && (!suggestions || (suggestions && !suggestions.migrationStrategy && !suggestions.assumptions?.default))) {
    //   const migrationStrategyGroups = [...new Set(rows.filter(p => p.migrationStrategy).map(p => p.migrationStrategy))];
    //   const migrationStrategy = Object.values(migrationStrategyGroups).map(key => ({
    //     name: key,
    //     pricingGroups: [{ reservationTermFrom: 1, reservationTermTo: 36, pricingModel: "OnDemand" }]
    //   }));
    //   migrationStrategy.forEach(m => groupsOnchangeHandler(m, m.name));
    //   setMigrationStrategy(migrationStrategy);
    // } else if (suggestions && suggestions.migrationStrategy) {
    //   setMigrationStrategy(suggestions.migrationStrategy)
    // } else if (suggestions && suggestions.assumptions?.default) {
    //   setMigrationStrategy(suggestions.assumptions.default.filter(p => p.attributeType === "migrationStrategy"));
    // }
  }, [rows, suggestions]);

  useEffect(() => {
    setAssumptionsData(props.assumptionsData);
  }, [props.assumptionsData]);

  const handleRemoveRecommendation = (id, optionId) => {
    const allData = [...assumptionsData];
    const index = allData.findIndex(i => i._id === id);
    const findAssumption = allData[index];
    findAssumption.recommendationCriteria.splice(optionId, 1);
    handleChangeData(allData);
  }

  const handleChange = (selectedValue, event, id, optionId, type = false) => {
    const allData = [...assumptionsData];
    const index = allData.findIndex(i => i._id === id);
    const findAssumption = allData[index];
    if (event === "addRecommendation") {
      findAssumption.recommendationCriteria.push({
        criteria: "$gte",
        enumerations: "volumeApiName",
        inputName: "peakDiskIO",
        recommended: "sc1",
        value: [0, 0]
      })
    } else if (type === "apply") {
      findAssumption.rightSizing = {
        ...findAssumption.rightSizing,
        [event.name]: event.name === "applyRightsizing" ? selectedValue.checked : selectedValue.value,
      }
    } else if (type === "applyOptional") {
      if (optionId >= 0) {
        findAssumption.pricingAttributes[optionId].value = selectedValue?.value || (selectedValue?.map(v => v.value) || [])
      } else {
        findAssumption[event.name] = selectedValue.checked;
      }
    } else if (type === "recommendation") {
      if (optionId >= 0) {
        if (event.name === "value")
          findAssumption.recommendationCriteria[optionId][event.name] = selectedValue;
        else
          findAssumption.recommendationCriteria[optionId][event.name] = selectedValue?.value;
      }
    } else if (type === "onPremiseServer") {
      findAssumption.attributes[optionId].value = event.value;
    } else {
      const optionIndex = findAssumption.searchThreshold.findIndex(i => i._id === optionId);
      findAssumption.searchThreshold[optionIndex] = {
        ...findAssumption.searchThreshold[optionIndex],
        [event.name]: selectedValue?.value || (type ? (selectedValue?.map(v => v.value) || []) : '')
      }
    }
    handleChangeData(allData);
    if (updatedAssumption)
      updatedAssumption(findAssumption);
  };

  const pricingFieldHandleChange = ({ value }, { target: { name } }, parentIndex, index, year) => {
    //DropDown
    const data = Object.assign([], migrationStrategy);
    data[parentIndex].pricingGroups[index][name] = value;
    if (year) {
      data[parentIndex].pricingGroups[index].reservationTermTo = index === 0 ? +year * 12 : (data[parentIndex].pricingGroups[index].reservationTermFrom - 1) + (+year * 12);
      const changedData = pricingAutoChangeHandler(data, parentIndex, index)
      setMigrationStrategy(changedData);
    }
    setMigrationStrategy(data);
    setIsError(p => ({ ...p, [data[parentIndex].name]: '' }));
    if ((+year * 12) > pricingMonths) {
      setIsError(p => ({ ...p, [data[parentIndex].name]: 'Value cannot be grater than value pricing months!' }));
    }
    groupsOnchangeHandler(data[parentIndex], data[parentIndex].name);
  }

  const groupMonthsHandler = ({ target: { value, name } }, index, migrationName) => {
    const selectedGroupIndex = migrationStrategy.findIndex(a => a.name === migrationName);
    const data = Object.assign([], migrationStrategy);
    data[selectedGroupIndex].pricingGroups[index][name] = +value;
    const changedData = pricingAutoChangeHandler(data, selectedGroupIndex, index)
    setMigrationStrategy(changedData);
    setIsError(p => ({ ...p, [migrationName]: '' }));
    if (value > pricingMonths) {
      setIsError(p => ({ ...p, [migrationName]: 'Value cannot be greater than value pricing months!' }));
    }
    groupsOnchangeHandler(changedData[selectedGroupIndex], changedData[selectedGroupIndex].name);
  };

  const pricingHandleChange = (index) => {
    //AddNew
    const data = Object.assign([], migrationStrategy);
    data[index].pricingGroups.push({
      reservationTermFrom: +data[index].pricingGroups[data[index].pricingGroups.length - 1].reservationTermTo + 1,
      reservationTermTo: +data[index].pricingGroups[data[index].pricingGroups.length - 1].reservationTermTo + 2,
      pricingModel: "noMigration"
    });
    setMigrationStrategy(data);
  };

  const handleChangePricingGroups = ({ target: { value } }) => {
    const data = migrationStrategy.map(a => ({ ...a, pricingMonths: value }));
    setMigrationStrategy(data);
    setPricingMonths(value);
    updatePricingGroups(data);
  };

  const pricingModelRemoveHandler = (index, i) => {
    //Remove
    let newFormValues = [...migrationStrategy];
    newFormValues[index].pricingGroups.splice(i, 1);
    const changedData = pricingAutoChangeHandler(newFormValues, index, 0)
    setMigrationStrategy(changedData);
  }

  const pricingAutoChangeHandler = (data, parentIndex, i) => {
    //Auto
    data[parentIndex].pricingGroups.forEach((p, index) => {
      if (index > i) {
        const priceYears = groupPricingOptions.find(a => a.value === p.pricingModel);
        p.reservationTermFrom = +data[parentIndex].pricingGroups[index - 1].reservationTermTo + 1;
        p.reservationTermTo = +p.reservationTermFrom + (priceYears && priceYears.year ? (+priceYears.year * 12) - 1 : 1);
      }
      return p;
    });
    return data;
  };

  const toggleTabs = (newTab) => {
    setActiveTab(newTab);
  }

  const utilizationHoursHandler = (value = false, key, index = false) => {
    const selectedUtilizationIndex = utilizationGroups.findIndex(u => u.name === key);
    const utilizationData = Object.assign([], utilizationGroups);
    if (typeof index === "boolean")
      utilizationData[selectedUtilizationIndex].week = utilizationData[selectedUtilizationIndex].week.map(a => ({ ...a, hours: +value == 0 ? 1 : +value > 24 ? 24 : +value }));
    else if (typeof index === "number")
      utilizationData[selectedUtilizationIndex].week[index].value = !utilizationData[selectedUtilizationIndex].week[index].value;
    utilizationOnchangeHandler(utilizationData[selectedUtilizationIndex], key);
    setUtilizationGroups(utilizationData);
  }

  return ((enumsLoading || props.loading) ? <div className="d-flex justify-content-center align-items-center mb-5">
    <ClipLoader size={50} color={"#4ce1b6"} loading={true} />
  </div> :
    <div className="ml-2">

      <Nav tabs className="assumptions-review-tabs-container pt-3">
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 1 })} onClick={() => {
            toggleTabs(1)
          }}>
            Server
          </NavLink>
        </NavItem>
        {!isAssumptionPage && <NavItem>
          <NavLink className={classnames({ active: activeTab === 2 })} onClick={() => {
            toggleTabs(2)
          }}>
            Migration Strategy
          </NavLink>
        </NavItem>}
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 3 })} onClick={() => {
            toggleTabs(3)
          }}>
            On Premise
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 4 })} onClick={() => {
            toggleTabs(4)
          }}>
            Utilization Group
          </NavLink>
        </NavItem>

        <div className="d-flex justify-content-center ml-auto">
          <div>
            {(props.closeHandler || props.previousPageHandler) && <Button color="secondary" className="btn-sm mb-0"
              onClick={props.closeHandler ? props.closeHandler : props.previousPageHandler}>
              Back
            </Button>}
            {props.resetAssumptionHandler &&
              <Button className="btn-sm btn-primary mb-0" color="primary" onClick={props.resetAssumptionHandler}>
                {props.isResetLoading ? <ClipLoader sizeUnit={"px"} size={20} color={"#fff"} loading={true} /> : 'Reset'}
              </Button>}
            {props.handleContinue && <Button color="primary" className="btn-sm mb-0" onClick={props.handleContinue}
              disabled={props.isLoading || assumptionsData.isLoading}>
              {props.isLoading ? (
                <ClipLoader
                  sizeUnit={"px"}
                  size={20}
                  color={"#4ce1b6"}
                  loading={true}
                />
              ) : (isAssumptionPage ? "Save" : props.resetAssumptionHandler ? "Recalculate" : "Continue")}
            </Button>}
          </div>
        </div>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <>
            {assumptionsData?.filter(p => p.attributeType === "clientData").map(p => {
              return (
                <>
                  <div className="row mt-3 validation-row">
                    <div className="col-12  d-flex align-items-center">
                      <h5 className=" assumption-labels">{p.inputName}</h5>
                    </div>
                  </div>
                  {p.inputType === 'numeric' &&
                    <div className="offset-12 offset-md-2 col-12 w-auto">
                      <div className="row">
                        <div className="col-12">
                          <h5 className="assumption-title my-2">
                            Search criteria
                            <Info style={{ height: "20px" }} className="ml-2" color="#70bbfd" id="TooltipExample" />
                          </h5>
                        </div>
                      </div>
                      {p.searchThreshold.map(s => {
                        return (
                          <div className="row mt-3">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                              <UncontrolledDropdown>
                                <ButtonGroup dir="ltr" className="w-100">
                                  <Button outline
                                    className="w-100 text-left rounded-0 validation-dropdown-style">{operaterOptions.find(op => op.value === s.criteria).label}</Button>
                                  <DropdownToggle outline
                                    className="validation-icon icon--right rounded-0">
                                    <ChevronDownIcon />
                                  </DropdownToggle>
                                </ButtonGroup>
                                <DropdownMenu right className="dropdown__menu">
                                  {operaterOptions.map(o => (
                                    <DropdownItem name="criteria"
                                      onClick={(e) => handleChange(o, { name: e.target.name }, p._id, s._id)}>{o.label}</DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3 mb-sm-0">
                              <UncontrolledDropdown>
                                <ButtonGroup dir="ltr" className="w-100">
                                  <Input type="text" name="value"
                                    className="w-100 text-left rounded-0 validation-dropdown-style"
                                    value={s.value}
                                    onChange={(e) => handleChange(e.target, e.target, p._id, s._id)} />
                                  <DropdownToggle outline
                                    className="validation-icon icon--right rounded-0"
                                    htmlFor="type">
                                    <CustomIcon
                                      Icon={searchCriteriaOptions.find(o => o.value === s.type).icon} />
                                  </DropdownToggle>
                                </ButtonGroup>
                                <DropdownMenu right className="dropdown__menu">
                                  {searchCriteriaOptions.map(c => (
                                    <DropdownItem name="type"
                                      onClick={(e) => handleChange(c, { name: e.target.name }, p._id, s._id)}>{c.label}</DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        )
                      })}

                      <div className="row">
                        <div className="col-12">
                          <h5 className="assumption-title my-2">
                            Right sizing
                            <Info style={{ height: "20px" }} className="ml-2" color="#70bbfd" id="TooltipExample" />
                          </h5>
                        </div>
                        <div className="col-12 col-md-6 ">
                          <label className="toggle-btn customizer__toggle">
                            <input
                              className="toggle-btn__input"
                              type="checkbox" name="applyRightsizing"
                              checked={p.rightSizing.applyRightsizing}
                              onChange={(e) => handleChange(e.target, e.target, p._id, p.rightSizing?._id, "apply")}
                            />
                            <span className="assumption-apply mr-2">Apply rightsizing</span>
                            <span className="toggle-btn__input-label m-0" />
                          </label>
                        </div>
                      </div>
                      {p.rightSizing.applyRightsizing &&
                        <div className="row mt-3">
                          <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <UncontrolledDropdown>
                              <ButtonGroup dir="ltr" className="w-100">
                                <Button outline
                                  className="w-100 text-left rounded-0 validation-dropdown-style">{options.find(op => op.value === p.rightSizing.column)?.label}</Button>
                                <DropdownToggle outline className="validation-icon icon--right rounded-0">
                                  <ChevronDownIcon />
                                </DropdownToggle>
                              </ButtonGroup>
                              <DropdownMenu right className="dropdown__menu">
                                {options.map(o => (
                                  <DropdownItem name="column"
                                    onClick={(e) => handleChange(o, { name: e.target.name }, p._id, p.rightSizing._id, "apply")}>{o.label}</DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3 mb-sm-0">
                            <UncontrolledDropdown>
                              <ButtonGroup dir="ltr" className="w-100">
                                <Input type="text"
                                  className="w-100 text-left rounded-0 validation-dropdown-style"
                                  name="value"
                                  placeholder="value" value={p.rightSizing.value}
                                  onChange={(e) => handleChange(e.target, e.target, p._id, p.rightSizing._id, "apply")} />
                                <DropdownToggle outline
                                  className="validation-icon icon--right rounded-0"
                                  htmlFor="type">
                                  <CustomIcon
                                    Icon={searchCriteriaOptions.find(o => o.value === p.rightSizing.type).icon} />
                                </DropdownToggle>
                              </ButtonGroup>
                              <DropdownMenu right className="dropdown__menu">
                                {searchCriteriaOptions.map(c => (
                                  <DropdownItem name="type"
                                    onClick={(e) => handleChange(c, { name: e.target.name }, p._id, p.rightSizing._id, "apply")}>{c.label}</DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      }
                    </div>
                  }
                  {p.inputType === 'category' &&
                    <div className="offset-12 offset-md-2 col-12 mt-3 w-auto">
                      <div className="row mt-3">
                        <div className="col-12 mb-4">
                          <h5 className="assumption-title my-2">
                            Search criteria
                            <Info style={{ height: "20px" }} className="ml-2" color="#70bbfd"
                              id="TooltipExample" />
                          </h5>
                        </div>
                      </div>
                      {p.searchThreshold.map(s => {
                        const searchOptions = p.enumerations.map(e => ({ value: e, label: e }));
                        const enumOptions = enums.find(e => e.name === p.inputName);
                        const valueOption = enumOptions.values.map(e => ({ value: e, label: e }));
                        return (
                          <div className="row mb-3">
                            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                              <UncontrolledDropdown>
                                <ButtonGroup dir="ltr" className="w-100">
                                  <Button outline
                                    className="w-100 text-left rounded-0 validation-dropdown-style">{searchOptions.find(o => o.value === s.inputValue).label}</Button>
                                  <DropdownToggle outline
                                    className="validation-icon icon--right rounded-0">
                                    <ChevronDownIcon />
                                  </DropdownToggle>
                                </ButtonGroup>
                                <DropdownMenu right className="dropdown__menu">
                                  {searchOptions.map(c => (
                                    <DropdownItem name="inputValue"
                                      onClick={(e) => handleChange(c, { name: e.target.name }, p._id, s._id)}>{c.label}</DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div
                              className="col-12 col-sm-2 col-md-2 col-lg-1 d-flex justify-content-center align-items-center ">
                              <h5 className="assumption-os-title my-2">maps to</h5>
                            </div>
                            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                              <Select
                                isMulti
                                name="value"
                                value={valueOption.filter(v => s.value.includes(v.value))}
                                placeholder="value"
                                onChange={(value, e) => handleChange(value, e, p._id, s._id, "isMulti")}
                                options={valueOption}
                                clearable={false}
                                closeOnSelect={false}
                                removeSelected={false}
                                className="react-select "
                                classNamePrefix="react-select"
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  }
                  <hr />
                </>
              )
            })}

            {
              assumptionsData?.filter(p => p.attributeType === "optional").map(p => {
                return (
                  <>
                    <div className="row mt-3 validation-row">
                      <div className="col-12  d-flex align-items-center">
                        <h5 className=" assumption-labels">{p.name}</h5>
                      </div>
                    </div>
                    <div className="offset-12 offset-md-2 col-12  w-auto">
                      <div className="row">
                        <div className="col-12 col-md-6 ">
                          <label className="toggle-btn customizer__toggle">
                            <input
                              className="toggle-btn__input"
                              type="checkbox" name={'enabled'} checked={p.enabled}
                              onChange={(e) => handleChange(e.target, e.target, p._id, undefined, "applyOptional")}
                            />
                            <span className="assumption-apply mr-2">	{p.label}</span>
                            <span className="toggle-btn__input-label m-0" />
                          </label>
                        </div>
                      </div>
                      <div className="row mt-3">
                        {p.enabled &&
                          <>
                            <div className="col-12 mb-3 col-sm-12 col-md-8 col-lg-8 col-xl-8 ">
                              <div className="d-flex flex-wrap">
                                {p.pricingAttributes.map((pricingAttr, index) => {
                                  const pricingOptions = pricingAttr.enumerations.map((ops) => ({
                                    value: ops,
                                    label: ops
                                  }));
                                  return (
                                    <div className="flex-grow-1 height-40 m-1 min-width-100">
                                      <Select
                                        isMulti={p.name === "snapshots"}
                                        name="optional"
                                        value={
                                          p.name === "snapshots" ?
                                            pricingOptions.filter(o => pricingAttr.value.includes(o.value))
                                            : pricingOptions.find(o => o.value === pricingAttr.value)
                                        }
                                        placeholder="Select ..."
                                        onChange={(value, e) => handleChange(value, e, p._id, index, "applyOptional")}
                                        options={pricingOptions}
                                        clearable={false}
                                        closeOnSelect={false}
                                        removeSelected={false}
                                        className="react-select "
                                        classNamePrefix="react-select"
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </div>
                    {p.name === "storage" && p.enabled && p.recommendationCriteria && (
                      <>
                        <div className="offset-12 offset-md-2 col-12  w-auto">
                          <div className="row my-3">
                            <div className="col-12 row m-0 w-100">
                              <div className="my-3">
                                <h5 className="assumption-title">
                                  Recommendation criteria
                                  <Info style={{ height: "20px" }} className="ml-2"
                                    color="#70bbfd" id="TooltipExample" />
                                </h5>
                              </div>
                              <div className="ml-auto">
                                <Button className="btn assumption-storage-add-btn py-2"
                                  onClick={() => handleChange('', 'addRecommendation', p._id)}>Add</Button>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="card assumption-card">
                                <div className="card-body">
                                  {p.recommendationCriteria.map((s, i) => {
                                    const enumOptions = enums.find(e => e.name === s.enumerations);
                                    const valueOption = enumOptions.values.map(e => ({
                                      value: e,
                                      label: e
                                    }));
                                    return (
                                      <>
                                        <div className="row m-0">
                                          <div className="d-flex align-items-center">
                                            <h5 className="assumption-title mt-2 mr-2 ">PeakDiskI0</h5>
                                          </div>
                                          <div className="">
                                            <UncontrolledDropdown>
                                              <ButtonGroup dir="ltr"
                                                className="w-100">
                                                <Button outline
                                                  className="text-left rounded-0 assumption-dropdown-style mb-0">{valueOption.find(v => s.recommended === v.value).label}</Button>
                                                <DropdownToggle outline
                                                  className="assumptions-icon icon--right rounded-0 mb-0">
                                                  <ChevronDownIcon />
                                                </DropdownToggle>
                                              </ButtonGroup>
                                              <DropdownMenu right
                                                className="dropdown__menu">
                                                {valueOption.map(x => (
                                                  <DropdownItem
                                                    name="recommended"
                                                    onClick={(e) => handleChange(x, { name: e.target.name }, p._id, i, 'recommendation')}>{x.label}</DropdownItem>
                                                ))}
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </div>
                                          <div
                                            className="ml-auto assumption-slider-close-icon"
                                            onClick={() => handleRemoveRecommendation(p._id, i)}>
                                            <CloseIcon />
                                          </div>
                                        </div>
                                        <div className="my-4" dir="ltr">
                                          <Range min={0} max={1000}
                                            value={[s.value[0], s.value[1]]}
                                            onChange={(e) => handleChange(e, { name: "value" }, p._id, i, 'recommendation')}
                                            tipFormatter={value => `$${value}`}
                                            className="slider-success" />
                                        </div>
                                      </>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <hr />
                  </>
                )
              })
            }

            {!!assumptionsData?.filter(p => p.attributeType === "preference").length &&
              <>
                <div className="row mt-3 validation-row">
                  <div className="col-12  d-flex align-items-center">
                    <h5 className=" assumption-labels">Preferences</h5>
                  </div>
                </div>
                <div className="offset-12 offset-md-2 col-12 mt-3 w-auto">
                  {assumptionsData?.filter(p => p.attributeType === "preference").map((p) => {
                    return <>
                      {
                        p.searchThreshold.map(s => {
                          const enumOptions = enums.find(e => e.name === p.searchName.slice(11));
                          const valueOption = enumOptions.values.map(e => ({ value: e, label: e }));
                          return (
                            <div className="row my-2">
                              <div className="col-12">
                                <h5 className="assumption-title "> {p.searchName.slice(11)}</h5>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                                <UncontrolledDropdown>
                                  <ButtonGroup dir="ltr" className="w-100">
                                    <Button outline
                                      className="w-100 text-left rounded-0 validation-dropdown-style">{operaterOptions.find(op => op.value === s.criteria).label}</Button>
                                    <DropdownToggle outline
                                      className="validation-icon icon--right rounded-0">
                                      <ChevronDownIcon />
                                    </DropdownToggle>
                                  </ButtonGroup>
                                  <DropdownMenu right className="dropdown__menu">
                                    {operaterOptions.map(o => (
                                      <DropdownItem name="criteria"
                                        onClick={(e) => handleChange(o, { name: e.target.name }, p._id, s._id)}>{o.label}</DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                              <div className="col-12 col-md-12 col-lg-4">
                                <Select
                                  isMulti
                                  name="value"
                                  value={valueOption.filter(v => s.value.includes(v.value))}
                                  placeholder="value"
                                  onChange={(value, e) => handleChange(value, e, p._id, s._id, "isMulti")}
                                  options={valueOption}
                                  clearable={false}
                                  closeOnSelect={false}
                                  removeSelected={false}
                                  className="react-select "
                                  classNamePrefix="react-select"
                                />
                              </div>
                            </div>
                          )
                        })
                      }
                    </>
                  })}
                </div>
              </>
            }
          </>
        </TabPane>
        {!isAssumptionPage && <TabPane tabId={2}>
          {!!migrationStrategy.length && <>
            {/* <hr />
            <h4 className='d-flex align-items-center justify-content-center'>Migration Strategy</h4> */}
            <div className="row mt-3 validation-row">
              <div className="col-12  d-flex align-items-center">
                <h5 className=" assumption-labels">Migration Strategy</h5>
              </div>
            </div>
            <div className="offset-12 offset-md-2 col-12 mt-3 w-auto">
              <div className="row align-items-center assumption-num-container">
                <div className="">
                  <h2 className="assumption-title">Number of Pricing Months</h2>
                </div>
                <div className="">
                  <Input type="number" name="value" value={migrationStrategy[0].pricingMonths}
                    onChange={handleChangePricingGroups}
                    className="w-100 text-left rounded-0 assumption-group-input font-weight-bold" />
                </div>
              </div>
              <div className="row align-items-center mt-4 mb-2 ">
                <div className="col-12 col-sm-6 col-md-6 col-lg-3 p-0 ">
                  <h2 className="assumption-title">Groups</h2>
                </div>
              </div>
              {migrationStrategy.map((m, index) => {
                return (
                  <div className="row gap my-2">
                    <div className="">
                      <Button color={groupColors[index]}
                        className="rounded assmption-group-btn">{m.name}</Button>
                    </div>
                    <div className="flex-grow-1 mr-lg-5 mr-xl-5">
                      <Card className="assumption-card-content">
                        <CardBody className="p-3">
                          {m.pricingGroups.map((group, i) => {
                            return (
                              <div className="row m-0 gap">
                                <div className=" ">
                                  <div className="row m-0 align-items-center">
                                    <div className="mr-2">
                                      <Input type="number" inputmode="numeric"
                                        name="reservationTermFrom" disabled
                                        value={group.reservationTermFrom}
                                        className="w-100 text-left rounded-0 assumption-group-input" />
                                    </div>
                                    <span className="assumption-label-t">to</span>
                                    <div className="ml-2">
                                      <Input type="number" max={pricingMonths}
                                        inputmode="numeric" name="reservationTermTo"
                                        readOnly={group.pricingModel !== "noMigration" && group.pricingModel !== "OnDemand"}
                                        value={group.reservationTermTo}
                                        className="w-100 text-left rounded-0 assumption-group-input"
                                        onChange={(e) => groupMonthsHandler(e, i, m.name)}
                                        onBlur={({ target: { value, name } }) => {
                                          const data = Object.assign([], migrationStrategy);
                                          data[index].pricingGroups[i][name] = name === "reservationTermTo" ? +value <= +data[index].pricingGroups[i].reservationTermFrom ? +data[index].pricingGroups[i].reservationTermFrom : +value : +value
                                          const changedData = pricingAutoChangeHandler(data, index, i)
                                          setMigrationStrategy(changedData);
                                        }} />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <UncontrolledDropdown>
                                    <ButtonGroup dir="ltr" className="w-100">
                                      <Button outline
                                        className="w-100 text-left rounded-0 validation-dropdown-style assumption-drop-label">{group.pricingModel}</Button>
                                      <DropdownToggle outline
                                        className="validation-icon icon--right rounded-0 assumption-drop-btn">
                                        <ChevronDownIcon />
                                      </DropdownToggle>
                                    </ButtonGroup>
                                    <DropdownMenu right className="dropdown__menu custom-dropdown-menu">
                                      {groupPricingOptions.map(o => (
                                        <DropdownItem name="pricingModel"
                                          onClick={(e) => pricingFieldHandleChange(o, e, index, i, o.year)}>{o.label}</DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                {!!i &&
                                  <div onClick={() => pricingModelRemoveHandler(index, i)}>
                                    <DeleteIcon color="#dc3545" className="deleteIconStyle" />
                                  </div>}
                              </div>
                            )
                          })}
                          {isError[m.name] && <span className="red-text">{isError[m.name]}</span>}
                          <div className="row m-0">
                            <div className="" onClick={() => pricingHandleChange(index)}>
                              <AddCircleOutlineIcon className="add-circle-icon pointer" />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                )
              })}
            </div>
          </>}
        </TabPane>}
        <TabPane tabId={3}>
          {assumptionsData?.filter(p => p.attributeType === "onPremiseServer").map((a, index) => {
            return (
              <Fragment key={a._id}>
                <div className="row mt-3 validation-row">
                  <div className="col-12  d-flex align-items-center">
                    <h5 className=" assumption-labels">{a.label}</h5>
                  </div>
                </div>
                <div className="offset-12 offset-md-2 col-12 mt-3 w-auto">
                  <div className="row my-3">
                    {a.attributes.map((p, i) => {
                      return (
                        <div key={p._id} className={`col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 custom-margin`}
                          style={{ marginRight: (i && i % 2 !== 0) && (xlg ? "50%" : lg ? "33%" : md ? "0%" : sm ? "0%" : '') }}>
                          <div className="p-0">
                            <h2 className="assumption-title">{p.label}</h2>
                          </div>
                          <div className="">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Input type="text" name={p.name} value={p.value}
                                  className={`w-100 text-right rounded-0 assumption-server-specs-input ${p.inputType && "custom-input"}`}
                                  onChange={(e) => handleChange(p, e.target, a._id, i, 'onPremiseServer')} />
                                {p.inputType !== 'number' &&
                                  <div className={`form__form-group-icon  migration-data-icon px-2`}>
                                    {p.inputType === 'storage' ? <span className="custom-icon">{'GB'}</span> :
                                      <CustomIcon Icon={p.inputType === 'cost' ? Cost : PercentIcon} />}
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Fragment>
            )
          })}
        </TabPane>
        <TabPane tabId={4}>
          <div className="row mt-3 validation-row">
            <div className="col-12  d-flex align-items-center">
              <h5 className=" assumption-labels">Utilization Group</h5>
            </div>
          </div>
          <div className="offset-12 offset-md-2 col-12 mt-3 w-auto">
            <div className="row align-items-center mt-4 mb-2 ">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 p-0 ">
                <h2 className="assumption-title">Groups</h2>
              </div>
            </div>
            {utilizationGroups.map((m, index) => {
              const hours = m.week[0].hours;
              return (
                <div className="row my-2">
                  <div className="col-3 d-flex">
                    <Button color={groupColors[index]} className="rounded assmption-group-btn w-100 m-auto">{m.name}</Button>
                  </div>
                  <div className="col-9 flex-grow-1">
                    <Card className="assumption-card-content">
                      <CardBody className="p-3 d-flex align-items-center">
                        <div className="m-0 d-flex align-items-center">
                          <Input type="number" name={'hours'} value={hours}
                            min={1} max={24} maxLength={2}
                            className={`rounded-0 utilization-hours-input`}
                            onChange={(e) => utilizationHoursHandler(e.target.value, m.name)} />
                        </div>
                        <div className="m-0 d-flex align-items-center">
                          <span className='ml-2'>hours per day</span>
                        </div>
                        <div className="m-0 d-flex align-items-center">
                          {m.week.map((a, index) => {
                            return (
                              <div className={`utilization-week cursor-pointer ${a.value && "selected-week-day"}`} onClick={() => utilizationHoursHandler(false, m.name, index)}>
                                <span>{a.label}</span>
                              </div>
                            )
                          }
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              )
            })}
          </div>
        </TabPane>
      </TabContent>
    </div>
  )
}
