import React, { useEffect, useRef, useState } from 'react'
import { Button, ButtonToolbar, Label } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import { Formik } from "formik";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as usrDuck } from '../../../../../store/ducks/users';
import { MenuItem } from '@material-ui/core';
import { ClipLoader } from 'react-spinners';
import { getUploadUrl } from '../../../../../api/admin';
import UploadIcon from 'mdi-react/EditOutlineIcon';
import { getExtension, mobileNumberOnly, usePrevious } from '../../../../../utils';
import Avatar from '../../../../../assets/avatar.png';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function NewUser(props) {
	let myref = useRef();
	const [initialState] = useState({ name: '', email: '', role: '', title: '', phone: '', designation: '', image: '', });
	const dispatch = useDispatch();
	const { isUserUpdateError, isErrorUser, isLoadingUser } = useSelector(state => state.users);
	const [selectedImage, setSelectedImage] = useState();
	const [selectedImageLoading, setSelectedImageLoading] = useState(false);
	const prevIsUserUpdating = usePrevious(isLoadingUser);

	useEffect(() => {
		if (!isLoadingUser && isErrorUser) {
			toast.error(isErrorUser?.response?.data?.error || 'Something went wrong', { position: toast.POSITION.TOP_RIGHT })
			dispatch(usrDuck.usersUpdateErrorClear())
		} else if (!isLoadingUser && prevIsUserUpdating) {
			toast.success('User Added Successfully.', { position: toast.POSITION.TOP_RIGHT });
			props.onClose(false);
		}
	}, [isUserUpdateError, prevIsUserUpdating, isLoadingUser])

	const handleSubmitData = async (values, setError) => {
		if (selectedImage) {
			setSelectedImageLoading(true)
			await getUploadUrl({
				name: `profiles/${selectedImage.file.name}`,
				type: selectedImage.file.type,
				ACL: 'public-read'
			})
				.then(async (res) => {
					await uploadUrl(res.uploadURL, selectedImage.file);
					values.image = `https://${process.env.REACT_APP_BUCKET}.s3-ap-southeast-2.amazonaws.com/profiles/${selectedImage.file.name}`;
					setSelectedImageLoading(false);
				})
				.catch((er) => {
					setSelectedImageLoading(false);
					console.log(er);
				});
		}
		if (values.image) {
			dispatch(usrDuck.addUser({ ...values, phone: '+61' + values.phone }));
		} else {
			setError('image', "Please select Image");
		}
	}

	const uploadUrl = (url, file) =>
		fetch(url, {
			method: "PUT",
			body: new Blob([file], { type: file.type }),
		});

	const handleBrowse = function (e) {
		e.preventDefault();
		myref.click();
	};

	const onFileSelected = (e, setFieldValue) => {
		const files = e.target.files;
		for (let file of files) {
			if (['jpeg', 'jpg', 'png'].includes(getExtension(file.name))) {
				const reader = new FileReader();
				reader.addEventListener("load", () => {
					setFieldValue('image', reader.result);
					setSelectedImage({ file })
				});
				reader.readAsDataURL(file);
			} else {
				toast.error("Please Choose Valid File Format", { position: toast.POSITION.TOP_RIGHT })
			}
		}
		e.target.value = "";
	};
	const isValidPhone = (setFieldError, errors) => (value, country) => {
		if (!errors.phone && !value.replace(country.countryCode, '').match(/^\d{9,10}$/)) {
			setFieldError('phone', 'Phone number is not valid');
			return true;
		} else {
			return true;
		}
	}
	return (
		<Formik
			enableReinitialize
			initialValues={initialState}
			validationSchema={SignUpSchema}
			onSubmit={(values, { setFieldError }) => {
				handleSubmitData(values, setFieldError);
			}}
		>{({
			values,
			errors,
			touched,
			setFieldValue,
			setFieldError,
			handleChange,
			handleSubmit,
			handleBlur,
		}) => (
			<form className="material-form" onSubmit={handleSubmit}>
				<div className='my-1 mb-3 position-relative upload-profile-picture-container'>
					<img className="user-profile-picture" src={values.image ? values.image : Avatar} alt='user-picture' />
					<input type="file" name="image" accept=".jpeg, .jpg, .png" hidden id="exampleFile" ref={(r) => {
						myref = r;
					}} onChange={(e) => onFileSelected(e, setFieldValue)} />
					<div class="upload-profile-picture-middle">
						<Button onClick={handleBrowse} type='button' className='upload-profile-picture-btn'>
							<UploadIcon className='p-0 m-0 upload-icon' />
						</Button>
					</div>
				</div>
				{touched.image && errors.image &&
					<span className="form__form-group-error mb-2">{touched.image && errors.image}</span>}
				<div>
					<TextField
						className="material-form__field"
						type="text"
						label="Full Name"
						placeholder="Larry Boom"
						name="name" onChange={handleChange} onBlur={handleBlur} value={values.name}
						invalid={touched.name && errors.name} />
					{touched.name && errors.name &&
						<span className="form__form-group-error mb-2">{touched.name && errors.name}</span>}
				</div>
				<div>
					<TextField
						className="material-form__field"
						placeholder="example@mail.com"
						name="email" onChange={handleChange} onBlur={handleBlur}
						label="Email"
						value={values.email}
					/>
					{touched.email && errors.email &&
						<span className="form__form-group-error mb-2">{touched.email && errors.email}</span>}
				</div>
				<div>
					<TextField
						className="material-form__field"
						label="Title"
						name="title" onChange={handleChange} onBlur={handleBlur} value={values.title}
						invalid={touched.title && errors.title} />
					{touched.title && errors.title &&
						<span className="form__form-group-error mb-2">{touched.title && errors.title}</span>}
				</div>
				<div>
					<TextField
						className="material-form__field"
						label="Role"
						name="role" onChange={handleChange} onBlur={handleBlur} value={values.role} select>
						<MenuItem value="TenantManager">Tenant Manager</MenuItem>
						<MenuItem value="ClientManager">Client Manager</MenuItem>
						<MenuItem value="ProjectManager">Project Manager</MenuItem>
					</TextField>
					{
						touched.role && errors.role &&
						<span className="form__form-group-error mb-2">{touched.role && errors.role}</span>
					}
				</div>
				<div>
					<Label className='label'>Phone</Label>
					<PhoneInput
						country={'au'}
						value={values.phone}
						onChange={handleChange('phone')}
						onBlur={handleBlur('phone')}
						autoFormat={false}
						isValid={isValidPhone(setFieldError, errors)}
						containerStyle={{ borderWidth: 10 }}
						inputStyle={{ border: 'none', borderBottom: '1px solid #f2f4f7', borderRadius: '0px' }}
						buttonStyle={{ background: 'red !important' }}
						placeholder="Mobile Number (will be used for 2FA)"
					/>
					{touched.phone && errors.phone &&
						<span className="form__form-group-error mb-2">{touched.phone && errors.phone}</span>}
				</div>
				<div>
					<TextField
						className="material-form__field"
						label="Designation"
						name="designation" onChange={handleChange} onBlur={handleBlur} value={values.designation}
						invalid={touched.designation && errors.designation} />
					{touched.designation && errors.designation &&
						<span className="form__form-group-error mb-2">{touched.designation && errors.designation}</span>}
				</div>
				<ButtonToolbar className="form__button-toolbar">
					<div className="col-12 p-0">
						<Button type="button" disabled={isLoadingUser || selectedImageLoading} color="primary" onClick={handleSubmit}>
							{isLoadingUser || selectedImageLoading ? <ClipLoader size={20} color={'#4ce1b6'} /> : 'Add User'}
						</Button>
					</div>
				</ButtonToolbar>
			</form>
		)}
		</Formik>
	)
}

export default NewUser;
const phoneRegExp = /^\d{9,10}$/;
const SignUpSchema = Yup.object().shape({
	name: Yup.string()
		.required('Required'),
	email: Yup.string()
		.email('Invalid Email')
		.required('Required'),
	title: Yup.string()
		.required('Required'),
	role: Yup.string()
		.required('Required'),
});