/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle,react/no-did-mount-set-state */
import React, {useEffect, useState} from 'react';
import {Collapse, Table} from 'reactstrap';
import {formatCurrency, groupBy} from '../../../utils';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';

export default function MigrationTable(props) {
  const assumptionsData = props?.assumptionsData;
  const suggestionsData = props?.suggestionsData;
  const clientData = props?.clientData;
  const [tableData, setTableData] = useState({});
  const [collapse, setCollapse] = useState(false);

  const calculateComputing = (pricingStrategy, pricingDimensions, server, maxPricingMonths) => {
    let cumulativeComputing = [];
    for (let {pricePerUnit, unit} of pricingDimensions) {
      if (unit === 'Hrs') {
        const totalCost = (Math.round(+pricePerUnit.USD * 365 * 24 * 100) / 100) / 12;
        const utilizationSavings = pricingStrategy.serverPricingPlan === 'OnDemand' ? calculateUtilization(totalCost, server.utilizationGroup) : 0;
        for (let i = pricingStrategy.reservationTermFrom; i <= Math.min(+pricingStrategy.reservationTermTo, maxPricingMonths - 1); i++) {
          if (i > pricingStrategy.reservationTermFrom || cumulativeComputing[0] === undefined)
            cumulativeComputing.push(totalCost - utilizationSavings);
          else {
            cumulativeComputing[0] += totalCost - utilizationSavings;
          }
        }
      } else if (unit === 'Quantity') {
        cumulativeComputing[0] = (cumulativeComputing[0] || 0) + +pricePerUnit.USD;
      }
    }
    return cumulativeComputing;
  }

  const calculateUtilization = (totalCost, utilizationType) => {
    const utilizationGroup = assumptionsData.filter(m => m.attributeType === "utilizationGroup");
    const utilization = utilizationGroup.find(a => a.name === utilizationType);
    if (utilization) {
      const {week} = utilization;
      const hour = week[0].hours;
      const daySelected = week.filter(w => w.value).length;
      const total = ((hour / 24) * (daySelected / 7) * totalCost);
      return Math.abs(totalCost - total);
    }
    return 0;
  }

  const calculateStorage = (pricingStrategy, pricingDimensions, pricingAttributes, server, maxPricingMonths) => {
    let cumulativeStorage = [];
    for (let {pricePerUnit, unit} of pricingDimensions) {
      if (unit === 'GB-Mo' || unit === 'GB-month') {
        let attributes = {
          storageGB: 'storageused',
          storagegrowth: 'Additive',
        };
        attributes = pricingAttributes.reduce((p, c) => ({...p, [c.name]: c.value}), attributes);
        let cost = 0;
        let storage = 0;
        [...Array(Math.max(0, Math.min(pricingStrategy.reservationTermTo, maxPricingMonths) - pricingStrategy.reservationTermFrom + 1))].forEach((_, month) => {
          if (attributes.storagegrowth !== 'Additive') {
            // # Type 1 : We calculate the exponential growth of the storage
            storage = (+server[attributes.storageGB] * (1 + +server.monthlyDiskGrowthRate) ^ month);
            cost = +pricePerUnit.USD * storage;
          } else {
            // # Type 2 : We calculate the additive growth of the storage
            storage = ((+server[attributes.storageGB] || 0) + (+server.monthlyDiskGrowthRate || 0) * (+server[attributes.storageGB] || 0) * month);
            cost = +pricePerUnit.USD * storage;
          }
          cumulativeStorage.push(cost);
        });
      }
    }
    return cumulativeStorage;
  }

  const calculateSnapshot = (pricingStrategy, pricingDimensions, pricingAttributes, server, maxPricingMonths) => {
    let cumulativeSnapshot = [];
    for (let {pricePerUnit, unit} of pricingDimensions) {
      if (unit === 'GB-Mo' || unit === 'GB-month') {
        let attributes = {
          storageGB: 'storageused',
          storagegrowth: 'Additive',
        };
        attributes = pricingAttributes.reduce((p, c) => ({...p, [c.name]: c.value}), attributes);
        let cost = 0;
        let storage = 0;
        const cumulativeStorage = [];
        if (attributes?.snapshots?.includes(server.serverRole)) {
          [...Array(Math.min(pricingStrategy.reservationTermTo, maxPricingMonths) - pricingStrategy.reservationTermFrom + 1)].forEach((_, month) => {
            const storageUsed = cumulativeStorage[cumulativeStorage.length] || +server[attributes.storageGB];
            if (attributes.storagegrowth !== 'Additive') {
              // # Type 1 : We calculate the exponential growth of the storage
              storage = (storageUsed * (1 + +server.monthlyDiskGrowthRate) ^ (month + 1)) * +server.snapshotsPerMonth * +server.dailyDiskChangeRate;
            } else {
              // # Type 2 : We calculate the additive growth of the storage
              storage = (storageUsed + (+server.monthlyDiskGrowthRate * storageUsed * (month + 1)));
            }
            // const dailySnapshotCost = +pricePerUnit.USD * storage * +server.snapshotsPerMonth * +server.dailyDiskChangeRate * 0.5;
            const monthlyInitialSnapshotCost = +pricePerUnit.USD * storage;
            // cumulativeSnapshot.push(dailySnapshotCost);
            cumulativeSnapshot.push(monthlyInitialSnapshotCost);
            cumulativeStorage.push(storage);
          });
        }
      }
    }
    return cumulativeSnapshot;
  };

  useEffect(() => {
    if (!assumptionsData || !assumptionsData.length || !clientData.length || !Object.keys(suggestionsData).length) return;
    const migrationAssumptions = assumptionsData.filter(m => m.attributeType === "migrationStrategy");
    const clientDataByMigrationStrategy = groupBy(clientData, 'migrationStrategy');
    const pricingAttributes = assumptionsData.filter(a => a.pricingAttributes);
    let data = {};
    for (let migrationStrategy in clientDataByMigrationStrategy) {
      if (migrationStrategy === 'undefined') continue;
      const clientData = clientDataByMigrationStrategy[migrationStrategy];
      if (clientData) {
        const migrationAssumption = migrationAssumptions.find(p => p.name === migrationStrategy);
        data[migrationStrategy] = calculateMigrationStrategy(migrationAssumption, pricingAttributes, clientData);
      }
    }
    const yearlyData = groupBy(Object.values(data).flat(), 'key');
    const datasets = Object.keys(yearlyData).map((c) => {
      const data = yearlyData[c];
      const mergedData = data.reduce((prev, cur) => {
        prev.total += cur.total;
        prev.key = cur.key;
        prev.data.compute.total += cur.data.compute.total;
        prev.data.storage.total += cur.data.storage.total;
        prev.data.snapshot.total += cur.data.snapshot.total;
        const computeLength = Math.max(cur.data.compute.data.length, prev.data.compute.data.length);
        prev.data.compute.data = [...Array(computeLength)].map((d, i) => ((prev.data.compute.data[i] || 0) + (cur.data.compute.data[i] || 0)));
        const storageLength = Math.max(cur.data.storage.data.length, prev.data.storage.data.length);
        prev.data.storage.data = [...Array(storageLength)].map((d, i) => ((prev.data.storage.data[i] || 0) + (cur.data.storage.data[i] || 0)));
        const snapshotLength = Math.max(cur.data.snapshot.data.length, prev.data.snapshot.data.length);
        prev.data.snapshot.data = [...Array(snapshotLength)].map((d, i) => ((prev.data.snapshot.data[i] || 0) + (cur.data.snapshot.data[i] || 0)));
        return prev;
      }, {
        total: 0,
        data: {compute: {data: [], total: 0}, storage: {data: [], total: 0}, snapshot: {data: [], total: 0}}
      });
      return mergedData;
    }, []);
    setTableData({datasets});
    props.setCalculatedData && props.setCalculatedData(datasets.reduce((p, c) => ({
      total: p.total + c.total,
      data: Object.keys(c.data).reduce((pr, key) => ({...pr, [key]: (c.data[key].total || 0) + (pr[key] || 0)}), p.data)
    }), {total: 0, data: {}}));
    props.totalMonths(datasets?.reduce((p, a) => p + Object.keys(a.data).reduce((prev, curKey) => Math.max(prev, a.data[curKey].data.length), 0), 0));
  }, [suggestionsData, clientData, assumptionsData]);

  const calculateMigrationStrategy = (pricingStrategy, pricingAttributes, selectedServers) => {
    if (!selectedServers.length) return [];
    // client data servers
    let allOffers = [];
    selectedServers.forEach(c => {
      const servers = suggestionsData[c._id];
      const serversByFamily = servers?.length ? groupBy(servers, 'productFamily') : {};
      const selectedInstance = serversByFamily['Compute Instance'] && (serversByFamily['Compute Instance'].find(p => p.selected) || serversByFamily['Compute Instance'][0]);
      const selectedStorage = serversByFamily['Storage'] && (serversByFamily['Storage'].find(p => p.selected) || serversByFamily['Storage'][0]);
      const selectedSnapshotStorage = serversByFamily['Storage Snapshot'] && (serversByFamily['Storage Snapshot'].find(p => p.selected) || serversByFamily['Storage Snapshot'][0]);
      const storagePricingAttributes = pricingAttributes.filter(a => a.searchThreshold && a.searchThreshold.length && a.searchThreshold[0].value === 'Storage').flatMap(p => p.pricingAttributes);
      const snapshotPricingAttributes = pricingAttributes.filter(a => a.searchThreshold && a.searchThreshold.length && a.searchThreshold[0].value === 'Storage Snapshot').flatMap(p => p.pricingAttributes);
      let compute = [], storage = [], snapshot = [];
      if (pricingStrategy && pricingStrategy.pricingGroups) {
        const maxPricingMonths = pricingStrategy.pricingMonths || pricingStrategy.pricingGroups.reduce((p, c) => (p > +c.reservationTermTo ? p : +c.reservationTermTo), 0);
        pricingStrategy.pricingGroups.map(pg => {
          //Compute Calculation
          if (pg.pricingModel === 'OnDemand') {
            if (selectedInstance) {
              compute = compute.concat(...selectedInstance.terms.onDemand.map(({priceDimensions}) => {
                return calculateComputing(pg, priceDimensions, c, maxPricingMonths);
              }));
            }
          } else {
            if (selectedInstance && pg.pricingModel !== 'noMigration') {
              compute = compute.concat(...selectedInstance.terms.reserved
                .filter(({
                           termAttributes: {
                             OfferingClass,
                             LeaseContractLength,
                             PurchaseOption
                           }
                         }) => OfferingClass.concat(LeaseContractLength, PurchaseOption) === pg.pricingModel)
                .map(({termAttributes, priceDimensions}) => {
                  return calculateComputing(pg, priceDimensions, c, maxPricingMonths);
                }));
            } else if (selectedInstance)
              compute = compute.concat(...Array.from(Array(Math.max(0, (Math.min(+pg.reservationTermTo, maxPricingMonths) - +pg.reservationTermFrom) + 1))).map(p => 0));
          }

          //Storage Calculation
          if (selectedStorage && pg.pricingModel !== 'noMigration') {
            storage = storage.concat(...selectedStorage.terms.onDemand.map(({priceDimensions}) => {
              return calculateStorage(pg, priceDimensions, storagePricingAttributes, c, maxPricingMonths);
            }));
          } else if (selectedStorage)
            storage = storage.concat(...Array.from(Array(Math.max(0, (Math.min(+pg.reservationTermTo, maxPricingMonths) - +pg.reservationTermFrom) + 1))).map(p => 0));

          //Snapshots Calculation
          if (selectedSnapshotStorage && pg.pricingModel !== 'noMigration') {
            snapshot = snapshot.concat(...selectedSnapshotStorage.terms.onDemand.map(({priceDimensions}) => {
              return calculateSnapshot(pg, priceDimensions, snapshotPricingAttributes, c, maxPricingMonths);
            }));
          } else if (selectedSnapshotStorage)
            snapshot = snapshot.concat(...Array.from(Array(Math.max(0, (Math.min(+pg.reservationTermTo, maxPricingMonths) - +pg.reservationTermFrom) + 1))).map(p => 0));
        });
      }
      allOffers = allOffers.concat({compute, storage, snapshot});
    });
    return allOffers.reduce((p, c) => {
      const totalHighestLength = Math.max(...Object.keys(c).map(key => c[key].length));
      let datasets = [];
      for (let i = 0; i < Math.ceil(totalHighestLength / 12); i++) {
        const data = Object.keys(c).reduce((prev, pKey) => ({
          ...prev, [pKey]: {
            total: c[pKey].slice(i * 12, 12 + (i * 12)).reduce((a, b) => a + +b, 0) || 0,
            data: c[pKey].slice(i * 12, 12 + (i * 12)),
          }
        }), {});
        datasets.push({
          data,
          total: Object.keys(data).reduce((pr, curKey) => pr + +data[curKey].total, 0),
          key: `Year-${i + 1}`,
        });
      }
      return [...p, ...datasets];
    }, [])
  }

  const toggle = (i) => {
    setCollapse(p => p === i ? null : i);
  }
  return (
    tableData.datasets ?
      tableData.datasets.map((a, i, allData) => {
          const totalLength = Object.keys(a.data).reduce((prev, curKey) => Math.max(prev, a.data[curKey].data.length), 0);
          const maxTotalSubTable = Math.max(...[...Array(totalLength)].map((p, i) => Object.keys(a.data).reduce((prev, curKey) => prev + (a.data[curKey].data[i] || 0), 0)));
          const maxTotal = Math.max(...allData.map(m => m.total || 0));
          return (
            <div style={{marginBottom: 30}}>
              <div className='bar-chart-container'>
                <h4 className="barChart-tableHead bar-chart-container-title ">{a.key}</h4>
                <div className=" border-0 flex-grow-1">
                  <div className='row m-0 align-items-center'>
                    {Object.keys(a.data).map(key => {
                      return (
                        <div className={`custom-bar-chart ${key}-bgcolor`}
                             style={{width: `${(a.data[key].total / maxTotal) * 90}%`}}>
                          <div className='chart-bottom-label'/>
                        </div>
                      )
                    })}
                    <div className='expand-button' onClick={() => toggle(i)}>
                      {collapse === i ? <MinusIcon color="#ffffff" style={{height: "30px"}}/> :
                        <PlusIcon color="#ffffff" style={{height: "30px"}}/>}
                    </div>
                  </div>

                  <div className='d-flex'>
                    {Object.keys(a.data).map(key => {
                      return (
                        <div className='mr-5 d-flex flex-column justify-content-center align-items-center'>
                          <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div className={`badge-color ${key}-bgcolor`}/>
                            <span className='ml-1'>{key}</span>
                          </div>
                          <div className='chart-bottom-total'>
                            <span className='ml-1 chart-total'>${(a.data[key].total).toFixed(2)}</span>
                          </div>
                        </div>
                      )
                    })}
                    <div className='mr-5 d-flex flex-column justify-content-center align-items-center'>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <span className='ml-1'>Total</span>
                      </div>
                      <div className='chart-bottom-total'>
                        <span className='ml-1 chart-total'>${(a.total).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Collapse isOpen={collapse === i}>
                <Table bordered responsive className='migration-table-detail' style={{marginTop: 40}}>
                  <tbody>
                  <tr>
                    <th className="barChart-tableHead" style={{verticalAlign: "bottom"}}></th>
                    {[...Array(totalLength)].map((p, index) => {
                      return <td
                        key={`${Math.random()}`}
                        style={{verticalAlign: "bottom", textAlign: 'center'}}>
                        {/* Chart */}

                        <div className='monthly-chart'
                             style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          {Object.keys(a.data).map((key, i) => {
                            const id = `${key}-${i}`
                            return (
                              <>
                                <div className={`custom-bar-chart tooltip-migration-chart ${key}-bgcolor`} id={id}
                                     style={{
                                       height: a.data[key].data[index] ? `${((a.data[key].data[index] || 0) / maxTotalSubTable) * 200}px` : '0px',
                                       width: 30
                                     }}>
                                  <div className='chart-bottom-label'/>
                                  <span
                                    class="tooltiptext">{`${key}\n${formatCurrency(a.data[key].data[index] || 0)}`}</span>
                                </div>
                              </>
                            )
                          })}
                        </div>
                      </td>
                    })}

                  </tr>
                  <tr className='table-bg'>
                    <th className="barChart-tableHead">
                      Total
                    </th>
                    {[...Array(totalLength)].map((p, index) => {
                      return <td key={`${Math.random()}`}
                                 style={{verticalAlign: "bottom", textAlign: 'center'}}>
                        {formatCurrency(Object.keys(a.data).reduce((prev, curKey) => prev + (a.data[curKey].data[index] || 0), 0))}
                      </td>
                    })}
                  </tr>
                  {Object.keys(a.data).map((key, i) => {
                    return (
                      <tr key={`${key}`}>
                        <th className="barChart-tableHead">{key}</th>
                        {[...Array(totalLength)].map((item, ind) => {
                          return (
                            <td key={`${Math.random()}-${i}-${ind}-${key}`}
                                className="barChart-tableData">{formatCurrency(a.data[key].data[ind] || 0)}</td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  <tr>
                    <th className="barChart-tableHead">{' '}</th>
                    {[...Array(totalLength)].map((item, ind) => {
                      return (
                        <td key={`${Math.random()}-${i}-${ind}`}
                            className="barChart-tableData">{ind + 1}</td>
                      )
                    })}
                  </tr>
                  </tbody>
                </Table>
              </Collapse>
            </div>
          )
        }
      )
      : ""
  )
};

MigrationTable.defaultProps = {
  totalMonths: () => {
  }
}
