export const Roles = {
	TenantManager: 'Tenant Manager',
	ClientManager: 'Client Manager',
	ProjectManager: 'Project Manager',
}

export const types = {
	'scenario-change': 'scenario-change',
	'client-create': 'client-create',
	'client-update': 'client-update',
	'client-delete': 'client-delete',
	'project-create': 'project-create',
	'project-delete': 'project-delete',
	'project-update': 'project-update',
	'login': 'login'
}