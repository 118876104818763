import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import WeeklyState from '../../WeeklyState';
import Storage from 'mdi-react/StorageIcon'
import ServerSuggestionsPricing from './ServerSuggestionsPricing';
import MigrationTable from '../../MigrationTable';
import { useSelector } from 'react-redux';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import AddCircleOutlineIcon from "mdi-react/AddCircleIcon";

const groupColors = ["success", "warning", "danger", "primary", "secondary", "info", "light", "dark", "success", "warning"];
const groupPricingOptions = [
    { value: 'noMigration', year: '', label: 'No Migration' },
    { value: 'onDemand', year: '', label: 'OnDemand' },
    { value: 'convertible1yrNoUpfront', year: '1', label: 'convertible 1yr No Upfront' },
    { value: 'convertible1yrPartialUpfront', year: '1', label: 'convertible 1yr Partial Upfront' },
    { value: 'convertible1yrAllUpfront', year: '1', label: 'convertible 1yr All Upfront' },
    { value: 'convertible3yrNoUpfront', year: '3', label: 'convertible 3yr No Upfront' },
    { value: 'convertible3yrPartialUpfront', year: '3', label: 'convertible 3yr Partial Upfront' },
    { value: 'convertible3yrAllUpfront', year: '3', label: 'convertible 3yr All Upfront' },
    { value: 'standard1yrNoUpfront', year: '1', label: 'standard 1yr No Upfront' },
    { value: 'standard1yrPartialUpfront', year: '1', label: 'standard 1yr Partial Upfront' },
    { value: 'standard1yrAllUpfront', year: '1', label: 'standard 1yr All Upfront' },
    { value: 'standard3yrNoUpfront', year: '3', label: 'standard 3yr No Upfront' },
    { value: 'standard3yrPartialUpfront', year: '3', label: 'standard 3yr Partial Upfront' },
    { value: 'standard3yrAllUpfront', year: '3', label: 'standard 3yr All Upfront' },
];
export default function StorageSuggestionsCard({
    highestCost,
    server,
    serverSelectHandler,
    serverUpdateHandler,
    selectServer,
    assumptionsData,
    clientServer,
    groupsOnchangeHandler,
    storageProducts,
    ...props }) {
    const shared = props.shared;
    const { costs, _id, productFamily } = server;
    const reservationTerms = [...new Set(costs.map(p => p.termAttributes.LeaseContractLength))];
    const paymentOptions = [...new Set(costs.map(p => p.termAttributes.PurchaseOption))];
    const pricingModels = [...new Set(costs.map(p => p.termAttributes.OfferingClass))];
    const [selectedCost, setSelectedCost] = useState(costs.find(p => p.selected) || costs[0]);
    const storageCost = selectedCost?.totalCost1Year;
    const servers = [server];
    const selectedComputeInstance = storageProducts["Compute Instance"] && (storageProducts["Compute Instance"]?.find(p => p.selected) || storageProducts["Compute Instance"][0])
    selectedComputeInstance && servers.push(selectedComputeInstance);
    const selectedSnapshot = storageProducts['Storage Snapshot'] && (storageProducts['Storage Snapshot']?.find(p => p.selected) || storageProducts['Storage Snapshot'][0])
    selectedSnapshot && servers.push(selectedSnapshot)
    const { suggestions } = useSelector(state => state.suggestions);
    const [migrationStrategy, setMigrationStrategy] = useState([]);
    const [pricingMonths, setPricingMonths] = useState(36);
    const [isError, setIsError] = useState({});

    useEffect(() => {
        if (suggestions && suggestions.migrationStrategy && clientServer) {
            setMigrationStrategy(suggestions.migrationStrategy && suggestions.migrationStrategy.filter(m => m.name === clientServer.migrationStrategy))
        } else if (suggestions) {
            setMigrationStrategy(suggestions.assumptions.default.filter(m => m.name === clientServer.migrationStrategy && m.attributeType === "migrationStrategy"))
        }
    }, [clientServer, suggestions]);

    const selectCostHandler = (costData) => {
        costData.selected = true;
        selectedCost.selected = undefined;
        setSelectedCost(costData);
        serverUpdateHandler(selectServer);
    };

    const groupMonthsHandler = ({ target: { value, name } }, index, migrationName) => {
        const selectedGroupIndex = migrationStrategy.findIndex(a => a.name === migrationName);
        const data = Object.assign([], migrationStrategy);
        data[selectedGroupIndex].pricingGroups[index][name] = value;
        const changedData = pricingAutoChangeHandler(data, selectedGroupIndex, index)
        setMigrationStrategy(changedData);
        setIsError(p => ({ ...p, [migrationName]: '' }));
        if (value > pricingMonths) {
            setIsError(p => ({ ...p, [migrationName]: 'Value cannot be greater than value pricing months!' }));
        }
        migrationStrategyHandler(changedData[selectedGroupIndex], changedData[selectedGroupIndex].name);
    }
    const pricingAutoChangeHandler = (data, parentIndex, i) => {
        //Auto
        data[parentIndex].pricingGroups.forEach((p, index) => {
            if (index > i) {
                const priceYears = groupPricingOptions.find(a => a.value === p.year);
                p.reservationTermFrom = +data[parentIndex].pricingGroups[index - 1].reservationTermTo + 1;
                p.reservationTermTo = +p.reservationTermFrom + (priceYears ? (priceYears * 12) : 1);
            }
            return p;
        });
        return data;
    }
    const migrationStrategyHandler = (migrationStrategy, name) => {
        // const findIndex = assumptionsData.data.findIndex(a => a.name === name);
        // setAssumptionsData({
        //   ...assumptionsData,
        //   data: Object.assign(assumptionsData.data, {
        //     [findIndex >= 0 ? findIndex : assumptionsData.data.length]: {
        //       ...migrationStrategy,
        //       attributeType: 'migrationStrategy',
        //       searchThreshold: []
        //     }
        //   })
        // });
    }
    const pricingFieldHandleChange = ({ value }, { target: { name } }, parentIndex, index, year) => {
        //DropDown
        const data = Object.assign([], migrationStrategy);
        data[parentIndex].pricingGroups[index][name] = value;
        if (year) {
            data[parentIndex].pricingGroups[index].reservationTermTo = index === 0 ? +year * 12 : data[parentIndex].pricingGroups[index].reservationTermFrom + (+year * 12);
            const changedData = pricingAutoChangeHandler(data, parentIndex, index)
            setMigrationStrategy(changedData);
        }
        setMigrationStrategy(data);
        setIsError(p => ({ ...p, [data[parentIndex].name]: '' }));
        if ((+year * 12) > pricingMonths) {
            setIsError(p => ({ ...p, [data[parentIndex].name]: 'Value cannot be grater than value pricing months!' }));
        }
        migrationStrategyHandler(data[parentIndex], data[parentIndex].name);
    }
    const pricingModelRemoveHandler = (index, i) => {
        //Remove
        let newFormValues = [...migrationStrategy];
        newFormValues[index].pricingGroups.splice(i, 1);
        const changedData = pricingAutoChangeHandler(newFormValues, index, 0)
        setMigrationStrategy(changedData);
    }
    const pricingHandleChange = (index) => {
        const data = Object.assign([], migrationStrategy);
        data[index].pricingGroups.push({
            reservationTermFrom: +data[index].pricingGroups[data[index].pricingGroups.length - 1].reservationTermTo + 1,
            reservationTermTo: +data[index].pricingGroups[data[index].pricingGroups.length - 1].reservationTermTo + 2,
            pricingModel: "noMigration"
        })
        setMigrationStrategy(data);
    }

    const handleChangePricingGroups = ({ target: { value } }) => {
        const data = Object.assign([], migrationStrategy);
        data.forEach(a => {
            a.pricingMonths = value;
            return a;
        });
        setPricingMonths(value);
        setMigrationStrategy(data);
    };
    return (
        <div className="card shadow mt-3">
            <div className="card-body p-3">
                {productFamily === 'Unavailable' ?
                    <span className="server-not-available">No server detail available  for this server</span> :
                    <>
                        <div className="row m-0">
                            <div className="col-12 col-lg-12 col-xl-6">
                                <h3 className="storage-title d-flex align-items-center"><Storage />{server.productFamily}</h3>
                                <div className="row">
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Service name</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.servicename}</span>
                                    </div>
                                    <div className="sm-hr col-12">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Volume Api Name</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.volumeApiName}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Volume Type</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.volumeType}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Usage Type</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.usagetype}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Storage Media</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.storageMedia}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Service Code</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.servicecode}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Numeric vCPU</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.numericVCPU || '-'}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Max Volume Size</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.maxVolumeSize || '-'}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Max Throughput volume</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.maxThroughputvolume || '-'}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Max Iops Volume</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.maxIopsvolume || '-'}</span>
                                    </div>
                                    <div className="col-12 lg-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Max Iops Burst Performance</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.maxIopsBurstPerformance || '-'}</span>
                                    </div>
                                    <div className="col-12 sm-hr">
                                        <hr />
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-label">Location Type</span>
                                    </div>
                                    <div className="col-6  col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <span className="server-instance-name">{server.attributes.locationType || '-'}</span>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-lg-12 col-xl-6 d-flex align-items-center justify-content-center my-3">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    {selectedCost && highestCost && <WeeklyState label={`$${storageCost.toFixed(2)}`}
                                        value={(storageCost * 100) / highestCost} />}
                                    <div className="d-flex justify-content-center mt-4">
                                        <Button color={_id === selectServer?._id ? "success" : "primary"}
                                            className="tn btn-primary  account__btn--small"
                                            onClick={() => serverSelectHandler(server)} disabled={shared}>{_id === selectServer?._id ? "Current" : "Select"}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div style={{ overflow: "auto", width: '100%' }}>
                                <MigrationTable assumptionsData={assumptionsData} suggestionsData={{
                                    [clientServer._id]: servers
                                }}
                                    clientData={[clientServer]} />
                            </div>
                            {!!migrationStrategy.length && <>
                                <hr />
                                <h4 className='d-flex align-items-center justify-content-center'>Migration Strategy</h4>
                                <div className="row mt-3 validation-row">
                                    <div className="col-12  d-flex align-items-center">
                                        <h5 className=" assumption-labels">Migration Strategy</h5>
                                    </div>
                                </div>
                                <div className="offset-12 offset-md-2 col-12 mt-3 w-auto">
                                    <div className="row align-items-center assumption-num-container">
                                        <div className="">
                                            <h2 className="assumption-title">Number of Pricing Months</h2>
                                        </div>
                                        <div className="">
                                            <Input type="number" name="value" value={pricingMonths}
                                                onChange={handleChangePricingGroups}
                                                className="w-100 text-left rounded-0 assumption-group-input font-weight-bold" />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mt-4 mb-2 ">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 p-0 ">
                                            <h2 className="assumption-title">Groups</h2>
                                        </div>
                                    </div>
                                    {migrationStrategy.map((m, index) => {
                                        return (
                                            <div className="row gap my-2">
                                                <div className="">
                                                    <Button color={groupColors[index]}
                                                        className="rounded assmption-group-btn">{m.name}</Button>
                                                </div>
                                                <div className="flex-grow-1 mr-lg-5 mr-xl-5">
                                                    <Card className="assumption-card-content">
                                                        <CardBody className="p-3">
                                                            {m.pricingGroups.map((group, i) => {
                                                                return (
                                                                    <div className="row m-0 gap">
                                                                        <div className=" ">
                                                                            <div className="row m-0 align-items-center">
                                                                                <div className="mr-2">
                                                                                    <Input type="number" inputmode="numeric"
                                                                                        name="reservationTermFrom" disabled
                                                                                        value={group.reservationTermFrom}
                                                                                        className="w-100 text-left rounded-0 assumption-group-input" />
                                                                                </div>
                                                                                <span className="assumption-label-t">to</span>
                                                                                <div className="ml-2">
                                                                                    <Input type="number" max={pricingMonths}
                                                                                        inputmode="numeric" name="reservationTermTo"
                                                                                        readOnly={group.pricingModel !== "noMigration" && group.pricingModel !== "OnDemand"}
                                                                                        value={group.reservationTermTo}
                                                                                        className="w-100 text-left rounded-0 assumption-group-input"
                                                                                        onChange={(e) => groupMonthsHandler(e, i, m.name)}
                                                                                        onBlur={({ target: { value, name } }) => {
                                                                                            const data = Object.assign([], migrationStrategy);
                                                                                            data[index].pricingGroups[i][name] = name === "reservationTermTo" ? +value <= data[index].pricingGroups[i].reservationTermFrom ? +data[index].pricingGroups[i].reservationTermFrom + 1 : value : value
                                                                                            const changedData = pricingAutoChangeHandler(data, index, i)
                                                                                            setMigrationStrategy(changedData);
                                                                                        }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <UncontrolledDropdown>
                                                                                <ButtonGroup dir="ltr" className="w-100">
                                                                                    <Button outline
                                                                                        className="w-100 text-left rounded-0 validation-dropdown-style assumption-drop-label">{group.pricingModel}</Button>
                                                                                    <DropdownToggle outline
                                                                                        className="validation-icon icon--right rounded-0 assumption-drop-btn">
                                                                                        <ChevronDownIcon />
                                                                                    </DropdownToggle>
                                                                                </ButtonGroup>
                                                                                <DropdownMenu right
                                                                                    className="dropdown__menu custom-dropdown-menu">
                                                                                    {groupPricingOptions.map(o => (
                                                                                        <DropdownItem name="pricingModel"
                                                                                            pricingFieldHandleChang onClick={(e) => pricingFieldHandleChange(o, e, index, i, o.year)}>{o.label}</DropdownItem>
                                                                                    ))}
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </div>
                                                                        {!!i &&
                                                                            <div onClick={() => pricingModelRemoveHandler(index, i)}>
                                                                                <DeleteIcon color="#dc3545" className="deleteIconStyle" />
                                                                            </div>}
                                                                    </div>
                                                                )
                                                            })}
                                                            {isError[m.name] && <span className="red-text">{isError[m.name]}</span>}
                                                            <div className="row m-0">
                                                                <div className="" onClick={() => pricingHandleChange(index)}>
                                                                    <AddCircleOutlineIcon className="add-circle-icon pointer" />
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>}
                        </div>

                        <div className="my-3">
                            <ServerSuggestionsPricing shared={shared} selectedCost={selectedCost} setSelectedCost={selectCostHandler} costs={costs}
                                reservationTerms={reservationTerms} pricingModels={pricingModels}
                                paymentOptions={paymentOptions} storageCost={storageCost} pricingType={productFamily === "Storage" ? "storage" : 'snapshot'} server={server} />
                        </div>
                    </>
                }
            </div>
        </div>
    );
}