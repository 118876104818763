import React, { Suspense } from 'react'
import { Card, CardBody, Col, Container, Row, } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

const ClientData = React.lazy(() => import('./ClientData/ClientData'));
const AddProject = React.lazy(() => import('./AddProject/AddProject'));
const ReviewAssumptions = React.lazy(() => import('./ReviewAssumptions/ReviewAssumptions'));
const ReviewSuggestions = React.lazy(() => import('./ReviewSuggesions/ReviewSuggestions'));
const ClientDetails = React.lazy(() => import('./ReviewSuggesions/ServerDeatil/ServerDetail'));
export default function Index(props) {
	const { pathname } = props.location;
	return (
		<Suspense fallback={<div className="d-flex justify-content-center align-items-center">
			<ClipLoader size={50} color={"#4ce1b6"} loading={true} />
		</div>}>
			<div>
				<Container style={pathname.includes('review-suggestions') ? {} : { overflow: 'auto' }}>
					<Row style={pathname.includes('review-suggestions') ? { width: 'fit-content' } : {}}>
						<Col md={12} lg={12}>
							<Card>
								<CardBody className="wizard review-assumption-card">
									<div className="wizard__steps">
										{/* <div className={`wizard__step ${pathname.includes('/welcome') ? ' wizard__step--active' : ''}`}>
										<p>Welcome</p>
									</div> */}
										<div className={`wizard__step ${pathname.includes('/project/add') ? ' wizard__step--active' : ''}`}>
											<p>Upload Data</p>
										</div>
										<div className={`wizard__step ${pathname.includes('/review-assumptions') ? ' wizard__step--active' : ''}`}>
											<p>Review Assumptions</p></div>
										<div className={`wizard__step ${pathname.includes('/review-suggestions') ? ' wizard__step--active' : ''}`}>
											<p>Review Suggestions</p></div>
									</div>
									<Switch>
										<Route path="/homepage/client/:id/welcome" exact component={ClientData} />
										<Route path="/homepage/client/:id/project/add" component={AddProject} />
										<Route path="/homepage/client/:id/project/review-assumptions" component={ReviewAssumptions} />
										<Route path="/homepage/client/:id/project/review-suggestions/:suggestionId" exact
											component={ReviewSuggestions} />
										<Route path="/homepage/client/:id/project/review-suggestions/:suggestionId/details/:serverId"
											component={ClientDetails} />
									</Switch>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</Suspense>
	)
}
