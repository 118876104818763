import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import TimeLineItem from "../../../shared/components/TimeLineItem";
import moment from 'moment';

export default function History(props) {
	const [timeline, setTimeline] = useState([]);

	useEffect(() => {
		const { state = [] } = props.location;
		setTimeline(state);
	}, []);

	const previousPageHandler = () => {
		props.history.goBack()
	}
	return (
		<div>
			<div>
				<Container>
					<Row>
						<Col md={12} lg={12}>
							<Card>
								<CardBody className="wizard review-assumption-card">
									<div className="d-flex justify-content-end mx-2 mx-sm-2 mx-md-3 mx-lg-4 mx-xl-5 ">
										<Button color="secondary" onClick={previousPageHandler}>
											Back
					</Button>
									</div>
									{!!timeline.length ? <div className="timeline">
										{timeline.map(a => {
											return (
												<TimeLineItem type="work" title={a.label} date={moment(a.createdAt).fromNow()}>
													<p>{a.description}</p>
												</TimeLineItem>
											);
										})}
									</div> :
										<div className="d-flex justify-content-center">
											<h1 className="mt-4 noresult-found-title">Record Not Found</h1>
										</div>}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
