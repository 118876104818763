import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Collapse, Table } from 'reactstrap';
import { formatCurrency, groupBy } from '../../../utils';

const groupColors = ['#36A2EB', '#FF6384', '#FFCE56', '#4336EB', '#36ebbb', '#b4eb36', '#eb7b36', '#0707075e', '#4ba062b5', "#8215568f"];

export default function OnPremiseEstimatesCharts({ setTotalCost, setDoughnutChartData, calculations, totalMonths, ...props }) {
    const [chartData, setChartData] = useState([]);
    const [colors, setColors] = useState({});
    const [isExpandedBar, setIsExpandedBar] = useState(null);
    useEffect(() => {
        const dataCentersGroups = groupBy(calculations, "dataCenterName");
        setColors(Object.keys(dataCentersGroups).reduce((p, c, i) => ({ ...p, [c]: groupColors[i] }), {}));
        const datasets = [...Array(Math.ceil(totalMonths / 12))].map((_, i) => {
            const data = Object.keys(dataCentersGroups).map((key, j) => {
                const dataCenter = dataCentersGroups[key][0];
                const data = [...Array(Math.min(totalMonths - (i * 12), (12 + (i * 12)) - (i * 12)))].map((_, k) => {
                    let upfrontCost = 0, yearlyCost = 0;
                    if (i === 0 && k === 0) upfrontCost = dataCenter.upfrontServerCost + dataCenter.totalStorageCost + dataCenter.totalVMwareCost + dataCenter.networkSpecsData?.totalSwitchCost + (dataCenter.customCharges?.upfront || 0)
                    if (k === 0) yearlyCost = dataCenter.yearlySupportCost + dataCenter.storageSupportCostYearly + dataCenter.totalVMwareCostYearly + dataCenter.yearlySwitchSupportCost + + (dataCenter.customCharges?.yearly || 0);
                    const monthlyCost = dataCenter.monthlyDataCenterCosts + dataCenter.monthlyLabourCosts + (dataCenter.customCharges?.monthly || 0);
                    return monthlyCost + upfrontCost + yearlyCost;
                })
                return {
                    label: key,
                    data,
                    total: data.reduce((p, c) => p + c, 0)
                }
            });
            return {
                data,
                total: data.reduce((p, c) => p + c.total, 0),
                label: `Year-${i + 1}`
            }
        });
        const labels = [];
        const data = [];
        const colors = [];
        const groupedData = groupBy(datasets.flatMap(p => p.data), "label")
        Object.keys(groupedData).forEach((key, i) => {
            labels.push(key);
            colors.push(groupColors[i]);
            data.push(groupedData[key].reduce((p, c) => p + c.total, 0));
        });
        setTotalCost(data.reduce((p, c) => p + c, 0));
        setDoughnutChartData({ labels, data, colors });
        setChartData(datasets);
    }, [calculations, totalMonths]);

    const collapseToggler = (barIndex) => setIsExpandedBar(p => p === barIndex ? null : barIndex);
    return (
        <div className='row m-0'>
            <div className='dashboard container'>
                <div className="row m-0">
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12' style={{ overflow: "auto" }}>


                        {
                            chartData.map((item, i, allData) => {
                                const maxTotal = Math.max(...allData.map(m => m.total || 0));
                                const totalLength = item.data.reduce((p, c) => Math.max(p, c.data.length), 0) || 0;
                                const maxSubTotal = Math.max(...[...Array(totalLength)].map((_, i) => (item.data.reduce((p, c) => p + c.data[i], 0) || 0)));

                                return (
                                    <div key={item.label} style={{ marginBottom: 30 }} >
                                        <div className='bar-chart-container'>
                                            <h4 className="barChart-tableHead bar-chart-container-title ">{item.label}</h4>
                                            <div className=" border-0 flex-grow-1">
                                                <div className='row m-0 align-items-center'>
                                                    {item.data.map((a, k) => {
                                                        return (
                                                            <div
                                                                key={k + a.label + i}
                                                                className={`custom-bar-chart`}
                                                                style={{
                                                                    backgroundColor: colors[a.label],
                                                                    width: `${(a.total / maxTotal) * 90}%`
                                                                }}
                                                            >
                                                                <div className='chart-bottom-label' />
                                                            </div>
                                                        )
                                                    })}
                                                    <div className='expand-button' onClick={() => collapseToggler(i)}>
                                                        {
                                                            isExpandedBar === i ?
                                                                <MinusIcon color="#ffffff" style={{ height: "30px" }} /> :
                                                                <PlusIcon color="#ffffff" style={{ height: "30px" }} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    {item.data.map((a, k) => {
                                                        return (
                                                            <div key={k + i + a.label + k} className='mr-5 d-flex flex-column justify-content-center align-items-center'>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <div className={`badge-color`} style={{
                                                                        backgroundColor: colors[a.label],
                                                                    }} />
                                                                    <span className='ml-1'>{a.label}</span>
                                                                </div>
                                                                <div className='chart-bottom-total'>
                                                                    <span className='ml-1 chart-total'>{formatCurrency(a.total)}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className='mr-5 d-flex flex-column justify-content-center align-items-center'>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <span className='ml-1'>Total</span>
                                                        </div>
                                                        <div className='chart-bottom-total'>
                                                            <span className='ml-1 chart-total'>{formatCurrency(item.total)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse isOpen={isExpandedBar === i}>
                                            <Table bordered responsive className='migration-table-detail' style={{ marginTop: 40 }}>
                                                <tbody>
                                                    <tr>
                                                        <th className="barChart-tableHead" style={{ verticalAlign: "bottom" }}></th>
                                                        {[...Array(totalLength)].map((p, index) => {
                                                            return <td
                                                                key={`${index}-${item.label}-${i}`}
                                                                style={{ verticalAlign: "bottom", textAlign: 'center' }} >

                                                                <div className='monthly-chart' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                    {item.data.map((a, i) => {
                                                                        const id = `${a.label}-${i}`
                                                                        return (
                                                                            <>
                                                                                <div
                                                                                    key={id + item.label}
                                                                                    className={`custom-bar-chart tooltip-migration-chart`} id={id}
                                                                                    style={{
                                                                                        backgroundColor: colors[a.label],
                                                                                        height: `${((a.data[index] || 0) / maxSubTotal) * 200}px`,
                                                                                        width: 30
                                                                                    }} >
                                                                                    <div className='chart-bottom-label' />
                                                                                    <span class="tooltiptext">{`${a.label}\n${formatCurrency(a.data[index] || 0)}`}</span>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </td>
                                                        })}

                                                    </tr>
                                                    <tr className='table-bg'>
                                                        <th className="barChart-tableHead">
                                                            Total
                                                        </th>
                                                        {[...Array(totalLength)].map((_, i) => {
                                                            return (
                                                                <td key={`${Math.random()}`}
                                                                    style={{ verticalAlign: "bottom", textAlign: 'center' }}>
                                                                    {formatCurrency(item.data.reduce((p, c) => p + c.data[i], 0) || 0)}
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                    {item.data.map((a, i) => {
                                                        return (
                                                            <tr key={`${a.label}-${i}`}>
                                                                <th className="barChart-tableHead">{a.label}</th>
                                                                {[...Array(totalLength)].map((item, ind) => {
                                                                    return (
                                                                        <td key={`${Math.random()}-${i}-${ind}-${a.label}`}
                                                                            className="barChart-tableData">{formatCurrency(a.data[ind] || 0)}</td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <th className="barChart-tableHead">{' '}</th>
                                                        {[...Array(totalLength)].map((item, ind) => {
                                                            return (
                                                                <td key={`${Math.random()}-${i}-${ind}`}
                                                                    className="barChart-tableData">{ind + 1}</td>
                                                            )
                                                        })}
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Collapse>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
