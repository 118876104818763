import React, { useEffect } from 'react';
import { Button, FormFeedback, Input } from 'reactstrap';
import KeyIcon from 'mdi-react/KeyIcon';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Creators as scenarioDuck } from "../../../store/ducks/ScenarioLink";
import { toast } from "react-toastify";

const SignupSchema = Yup.object().shape({
  code: Yup.string()
    .required('Code is Required')
});

export default function ResetPasswordForm(props) {
  const { id } = useParams();
  const {  isLoadingScenario, isErrorScenario } = useSelector(state => state.scenarioLink);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isErrorScenario) {
      toast.error(isErrorScenario, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }, [isErrorScenario])

  const submitHandler = (values) => {
    dispatch(scenarioDuck.getScenarios(id, values.code));
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ code: '' }}
        validationSchema={SignupSchema}
        onSubmit={(values) => { submitHandler(values) }
        }>
        {({ values, errors, touched, handleChange, handleSubmit, handleBlur, }) => (
          <form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Enter Code</span>
              <div className="form__form-group-field flex-wrap">
                <div className="form__form-group-icon d-flex">
                  <KeyIcon />
                </div>
                <Input type="text"
                  invalid={errors.code && touched.code}
                  onChange={handleChange('code')}
                  onBlur={handleBlur('code')}
                  value={values.code}
                  style={{ width: 'auto' }}
                  placeholder="enter code here"
                  className="input-without-border-radius flex-grow-1" />
                <FormFeedback>
                  {errors.code && touched.code && errors.code}
                </FormFeedback>
              </div>
            </div>
            <div className="row m-0 w-100">
              <div className="col">
                <Button color="primary" className="btn btn-block btn-primary account__btn account__btn--small" disabled={isLoadingScenario} onClick={handleSubmit}>
                  {isLoadingScenario ? <ClipLoader
                    sizeUnit={"px"}
                    size={20}
                    color={"#70bbfd"}
                    loading={true}
                  />
                    :
                    "Submit"
                  }
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}
