import { createActions, createReducer } from "reduxsauce";
import { submitAssumptions, submitManualSearchQuery } from "../../api/admin";

const getSuggestions = (data, suggestions = {}) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingSuggestionData());
		const parts = [];
		while (parts.length < data.serverIds.length / 100) {
			parts.push(data.serverIds.slice(parts.length * 100, parts.length * 100 + 100));
		}
		Promise.all(parts.map(serverIds => submitAssumptions({
			...data,
			serverIds
		}))).then(res => {
			return res.reduce((p, c) => ({ ...p, ...c }), {})
		})
			.then((products) => {
				dispatch(Creators.getSuggestionDataSuccess({
					...suggestions,
					products,
					assumptions: { default: data.assumptions }
				}));
			}).catch((error) => {
				dispatch(Creators.getSuggestionDataError(error));
			});
	};
};

const applyManualSearch = (data, tree) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingApplyManualSearch());
		submitManualSearchQuery(data).then((response) => {
			dispatch(Creators.applyManualSearchSuccess(response, { id: data.serverIds, tree }));
		}).catch((error) => {
			dispatch(Creators.applyManualSearchError(error, { id: data.serverIds, tree }));
		});
	};
};

const setMigrationStrategy = (pricingGroups) => {
	return (dispatch) => {
		dispatch(Creators.migrationStrategySuccess(pricingGroups));
	};
};

const setUtilizationGroups = (utilization) => {
	return (dispatch) => {
		dispatch(Creators.utilizationGroupsSuccess(utilization));
	};
};

export const { Types, Creators } = createActions({
	getSuggestions,
	setMigrationStrategy,
	migrationStrategySuccess: ['pricingGroups'],
	setUtilizationGroups,
	utilizationGroupsSuccess: ['utilization'],
	applyManualSearch,
	getSuggestionDataSuccess: ['suggestion'],
	applyManualSearchSuccess: ['suggestion', 'tree'],
	getSuggestionDataError: ['error'],
	applyManualSearchError: ['error', 'tree'],
	isLoadingSuggestionData: [],
	isLoadingApplyManualSearch: [],
	clearSuggestionsData: [],
	updateAssumptions: ['assumption', 'dataCenter'],
	deleteAssumptionAttribute: ['dataIndex', 'dataCenter'],
});

const initialState = {
	suggestions: null,
	isError: false,
	isLoading: false,
};

const updateAssumptions = (state = initialState, action) => {
	let assumptions = state.suggestions.assumptions.default;
	const isExistChargesIndex = assumptions.findIndex(a => a.name === "charges" && a.dataCenter === action.dataCenter);
	if (isExistChargesIndex >= 0) {
		assumptions[isExistChargesIndex].attributes.push(action.assumption);
	} else {
		assumptions.push({
			attributeType: "onPremiseServer",
			attributes: [action.assumption],
			enumerations: [],
			label: "Custom Charges",
			dataCenter: action.dataCenter,
			name: "charges",
			searchThreshold: [],
			tooltip: "On Premise Server Custom charges"
		})
	}
	return {
		...state,
		suggestions: {
			...state.suggestions,
			assumptions: {
				...state.suggestions.assumptions,
				default: assumptions
			}
		},
		isLoading: false,
		isError: '',
	};
};
const deleteAssumptionAttribute = (state = initialState, action) => {
	let assumptions = state.suggestions.assumptions.default;
	const isExistChargesIndex = assumptions.findIndex(a => a.name === "charges" && a.dataCenter === action.dataCenter);
	if (isExistChargesIndex >= 0) {
		assumptions[isExistChargesIndex].attributes.splice(action.dataIndex, 1);
	}
	return {
		...state,
		suggestions: {
			...state.suggestions,
			assumptions: {
				...state.suggestions.assumptions,
				default: assumptions
			}
		},
		isLoading: false,
		isError: '',
	};
};

const migrationStrategySuccess = (state = initialState, action) => {
	return {
		...state,
		suggestions: {
			migrationStrategy: action.pricingGroups,
		},
		isLoading: false,
		isError: '',
	};
};

const utilizationGroupsSuccess = (state = initialState, action) => {
	return {
		...state,
		suggestions: {
			utilizationGroups: action.utilization,
		},
		isLoading: false,
		isError: '',
	};
};


const getSuggestionDataSuccess = (state = initialState, action) => {
	return {
		...state,
		suggestions: {
			...state.suggestions,
			...action.suggestion
		},
		isLoading: false,
		isError: '',
	};
};

const getSuggestionDataError = (state = initialState, action) => {
	return {
		...state,
		suggestions: null,
		isLoading: false,
		isError: action.error,
	};
};
const clearSuggestionsData = (state = initialState, action) => {
	return {
		...state,
		suggestions: null,
	};
};

const isLoadingSuggestionData = (state = initialState, action) => {
	return {
		...state,
		isLoading: true,
		isError: '',
	};
};

const applyManualSearchSuccess = (state = initialState, action) => {
	return {
		...state,
		suggestions: {
			...state.suggestions,
			manualSearch: {
				...state.suggestions?.manualSearch,
				[action.tree.id]: action.tree.tree
			},
			products: {
				...state.suggestions?.products,
				[action.tree.id]: action.suggestion[action.tree.id]
			}
		},
		isLoadingManualSearch: false,
		isErrorManualSearch: '',
	};
};

const applyManualSearchError = (state = initialState, action) => {
	return {
		...state,
		suggestions: {
			...state.suggestions,
			manualSearch: {
				...state.suggestions?.manualSearch,
				[action.tree.id]: action.tree.tree
			},
		},
		isLoadingManualSearch: false,
		isErrorManualSearch: action.error,
	};
};

const isLoadingApplyManualSearch = (state = initialState, action) => {
	return {
		...state,
		isLoadingManualSearch: true,
		isErrorManualSearch: '',
	};
};


export default createReducer(initialState, {
	[Types.SET_MIGRATION_STRATEGY]: setMigrationStrategy,
	[Types.MIGRATION_STRATEGY_SUCCESS]: migrationStrategySuccess,
	[Types.SET_UTILIZATION_GROUPS]: setUtilizationGroups,
	[Types.UTILIZATION_GROUPS_SUCCESS]: utilizationGroupsSuccess,
	[Types.GET_SUGGESTIONS]: getSuggestions,
	[Types.GET_SUGGESTION_DATA_SUCCESS]: getSuggestionDataSuccess,
	[Types.GET_SUGGESTION_DATA_ERROR]: getSuggestionDataError,
	[Types.IS_LOADING_SUGGESTION_DATA]: isLoadingSuggestionData,
	[Types.APPLY_MANUAL_SEARCH]: applyManualSearch,
	[Types.APPLY_MANUAL_SEARCH_SUCCESS]: applyManualSearchSuccess,
	[Types.APPLY_MANUAL_SEARCH_ERROR]: applyManualSearchError,
	[Types.IS_LOADING_APPLY_MANUAL_SEARCH]: isLoadingApplyManualSearch,
	[Types.CLEAR_SUGGESTIONS_DATA]: clearSuggestionsData,
	[Types.UPDATE_ASSUMPTIONS]: updateAssumptions,
	[Types.DELETE_ASSUMPTION_ATTRIBUTE]: deleteAssumptionAttribute,
});
