import React, { useEffect, useRef, useState } from 'react'
import { Button, ButtonToolbar, Label } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import { Formik } from "formik";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as usrDuck } from '../../../../../store/ducks/users';
import { MenuItem } from '@material-ui/core';
import { ClipLoader } from 'react-spinners';
import { forgotPassword, getUploadUrl } from '../../../../../api/admin';
import UploadIcon from 'mdi-react/EditOutlineIcon';
import { getExtension } from '../../../../../utils';
import Avatar from '../../../../../assets/avatar.png';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function Setting(props) {
	let myref = useRef();
	const { user } = props;
	const [initialState, setInitialState] = useState({});
	const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
	const dispatch = useDispatch();
	const { isUserUpdateError, isUserUpdating } = useSelector(state => state.users);
	const [selectedImage, setSelectedImage] = useState();
	const [selectedImageLoading, setSelectedImageLoading] = useState(false);
	const [toggle, setToggle] = useState(true);

	const toggleChangeHandler = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		setInitialState({
			name: user.name,
			image: user.image,
			email: user.email,
			title: user.title,
			role: user.role,
			phone: user.phone.replace(/ /g, ""),
			mfa: user.mfa
		});
	}, [user]);

	useEffect(() => {
		if (!isUserUpdating && isUserUpdateError) {
			toast.error(isUserUpdateError.response.data.error || 'Something went wrong', { position: toast.POSITION.TOP_RIGHT })
			dispatch(usrDuck.usersUpdateErrorClear())
		}
	}, [isUserUpdateError, isUserUpdating])

	const handleSubmitData = (values) => {
		if (selectedImage) {
			setSelectedImageLoading(true)
			getUploadUrl({ name: `profiles/${selectedImage.file.name}`, type: selectedImage.file.type, ACL: 'public-read' })
				.then(async (res) => {
					await uploadUrl(res.uploadURL, selectedImage.file);
					dispatch(usrDuck.updateUser(user._id, {
						...values,
						image: `https://${process.env.REACT_APP_BUCKET}.s3-ap-southeast-2.amazonaws.com/profiles/${selectedImage.file.name}`
					}));
					setSelectedImageLoading(false);
				})
				.catch((er) => {
					setSelectedImageLoading(false);
					console.log(er);
				});
		} else {
			dispatch(usrDuck.updateUser(user._id, { ...values, phone: '+' + values.phone }));
		}
	}

	const uploadUrl = (url, file) =>
		fetch(url, {
			method: "PUT",
			body: new Blob([file], { type: file.type }),
		});

	const forgotPasswordHandler = () => {
		setIsLoadingForgotPassword(true);
		forgotPassword({ email: user.email }).then(res => {
			toast.success('Email sent successfully.', { position: toast.POSITION.TOP_RIGHT, className: 'toast-success' })
			setIsLoadingForgotPassword(false);
		}).catch(er => {
			toast.error(er.response.data.error || 'Something went wrong', { position: toast.POSITION.TOP_RIGHT })
			setIsLoadingForgotPassword(false);
		})
	}

	const handleBrowse = function (e) {
		e.preventDefault();
		myref.click();
	};

	const onFileSelected = (e, setFieldValue) => {
		const files = e.target.files;
		for (let file of files) {
			if (['jpeg', 'jpg', 'png'].includes(getExtension(file.name))) {
				const reader = new FileReader();
				reader.addEventListener("load", () => {
					setFieldValue('image', reader.result);
					setSelectedImage({ file })
				});
				reader.readAsDataURL(file);
			} else {
				toast.error("Please Choose Valid File Format", { position: toast.POSITION.TOP_RIGHT })
			}
		}
		e.target.value = "";
	};

	const isValidPhone = (setFieldError, errors) => (value, country) => {
		if (!errors.phone && !value.replace(country.countryCode, '').match(/^\d{9,10}$/)) {
			setFieldError('phone', 'Phone number is not valid');
			return true;
		} else {
			return true;
		}
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initialState}
			validationSchema={SignUpSchema}
			onSubmit={(values) => {
				handleSubmitData(values);
			}}
		>{({
			values,
			errors,
			touched,
			setFieldValue,
			setFieldError,
			handleChange,
			handleSubmit,
			handleBlur,
			dirty
		}) => (
			<form className="material-form" onSubmit={handleSubmit}>
				<div className='my-1 mb-3 position-relative upload-profile-picture-container'>
					<img className="user-profile-picture" src={values.image ? values.image : Avatar} alt='user-picture' />
					<input type="file" name="image" accept=".jpeg, .jpg, .png" hidden id="exampleFile" ref={(r) => {
						myref = r;
					}} onChange={(e) => onFileSelected(e, setFieldValue)} />
					<div class="upload-profile-picture-middle">
						<Button onClick={handleBrowse} type='button' className='upload-profile-picture-btn'>
							<UploadIcon className='p-0 m-0 upload-icon' />
						</Button>
					</div>
				</div>
				<div>
					<TextField
						className="material-form__field"
						type="text"
						label="Full Name"
						placeholder="Larry Boom"
						name="name" onChange={handleChange} onBlur={handleBlur} value={values.name}
						invalid={touched.name && errors.name} />
					{touched.name && errors.name &&
						<span className="form__form-group-error mb-2">{touched.name && errors.name}</span>}
				</div>
				<div>
					<TextField
						className="material-form__field"
						placeholder="example@mail.com"
						label="Email"
						value={user.email}
					/>
				</div>
				<div>
					<TextField
						className="material-form__field"
						label="Title"
						name="title" onChange={handleChange} onBlur={handleBlur} value={values.title}
						invalid={touched.title && errors.title} />
					{touched.title && errors.title &&
						<span className="form__form-group-error mb-2">{touched.title && errors.title}</span>}
				</div>
				<div>
					<TextField
						className="material-form__field"
						label="Role"
						name="role" onChange={handleChange} onBlur={handleBlur} value={values.role} select={values.role}>
						<MenuItem value="TenantManager">TenantManager</MenuItem>
						<MenuItem value="ClientManager">ClientManager</MenuItem>
						<MenuItem value="ProjectManager">ProjectManager</MenuItem>
					</TextField>
					{touched.role && errors.role &&
						<span className="form__form-group-error mb-2">{touched.role && errors.role}</span>}
				</div>
				<div>
					<Label className='label'>Phone</Label>
					<PhoneInput
						country={'au'}
						value={values.phone}
						autoFormat={false}
						onChange={handleChange('phone')}
						onBlur={handleBlur('phone')}
						isValid={isValidPhone(setFieldError, errors)}
						containerStyle={{ borderWidth: 10 }}
						inputStyle={{ border: 'none', borderBottom: '1px solid #f2f4f7', borderRadius: '0px' }}
						buttonStyle={{ background: 'red !important' }}
						placeholder="Mobile Number (will be used for 2FA)"
					/>
					{touched.phone && errors.phone &&
						<span className="form__form-group-error mb-2">{touched.phone && errors.phone}</span>}
				</div>
				<label className="toggle-btn customizer__toggle d-flex align-items-center" htmlFor="collapse_toggle">
					<input
						className="toggle-btn__input"
						type="checkbox"
						name="mfa"
						id="collapse_toggle"
						checked={values.mfa}
						onChange={() => setFieldValue('mfa', !values.mfa)}
					/>
					<span className="assumption-apply mr-2">MFA</span>
					<span className="toggle-btn__input-label m-0" />
				</label>
				<ButtonToolbar className="form__button-toolbar">
					<div className="col-12 p-0">
						<Button type="button" disabled={isUserUpdating || isLoadingForgotPassword} color="primary"
							onClick={forgotPasswordHandler}>
							{isLoadingForgotPassword ? <ClipLoader size={20} color={'#4ce1b6'} /> : 'Reset Password'}
						</Button>
					</div>
					<Button disabled={isUserUpdating || !dirty || isLoadingForgotPassword} color="primary" type="submit">
						{isUserUpdating || selectedImageLoading ?
							<ClipLoader size={20} color='#4ce1b6' /> : 'Update profile'}
					</Button>
					<Button color="primary">
						{isUserUpdating ?
							<ClipLoader size={20} color='#4ce1b6' /> : 'Disabled User'}
					</Button>
				</ButtonToolbar>
			</form>
		)}
		</Formik>
	)
}

export default Setting;
const phoneRegExp = /^\d{9,10}$/;
const SignUpSchema = Yup.object().shape({
	name: Yup.string()
		.required('Requireds'),
	title: Yup.string()
		.required('Requireds'),
	role: Yup.string()
		.required('Requireds'),
});
