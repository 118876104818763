import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import GreaterThanIcon from 'mdi-react/GreaterThanIcon';
import LessThanIcon from 'mdi-react/LessThanIcon';
import React, { useEffect, useState } from 'react';
import {
	Badge,
	Button,
	ButtonGroup,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormFeedback,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	UncontrolledDropdown
} from 'reactstrap';
import AddCircleOutlineIcon from 'mdi-react/AddCircleIcon';
import { ClipLoader } from 'react-spinners';
import { Creators as validationDispatcher } from '../../../store/ducks/validations';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from 'mdi-react/CloseCircleIcon'


const AddVariable = ({ isOpen, loading = false, toggle, title = 'Modal', className, ...props }) => {
	const [state, setState] = useState({ inputName: '', severity: 'error', type: '' });
	const [errors, setErrors] = useState({});
	const [category, setCategory] = useState('');
	const [isCreate, setIsCreate] = useState(false);
	const dispatch = useDispatch();
	const { validations, } = useSelector((state) => state.validations);
	useEffect(() => {
		setIsCreate(false);
		setState({ inputName: '', severity: 'error', type: '' });
	}, [isOpen]);

	useEffect(() => {
		if (!loading && isCreate) {
			setIsCreate(!isCreate);
			toggle();
		}
	}, [loading]);

	const typeChangeHandler = (value) => {
		const validValues = value === "Category" ? [] : value === "Numeric"
			? {
				lessThan: { '<=': [{ 'var': state.inputName }, 1] },
				greaterThan: { '>=': [{ 'var': state.inputName }, 0] }
			} : {};
		setState(p => ({
			...p,
			type: value,
			validValues,
			warning: value === "Category" ? { ...state, type: value, _id: null, severity: 'warning', validValues } : null,
		}));
	};

	const handleNumericInputChange = ({ target: { name, value } }) => {
		if (name === '<=')
			state.validValues.lessThan = { '<=': [{ 'var': name }, +value] };
		else
			state.validValues.greaterThan = { '>=': [{ 'var': name }, +value] };
		setState(state);
	};
	const handleChangeValues = ({ target: { value, name } }) => {
		if (validations.find(p => p.inputName === value)) {
			setErrors({ inputName: 'Validation with same name exists.' })
		} else {
			setErrors({ inputName: null })
		}
		setState(p => ({ ...p, [name]: value, warning: p.warning ? { ...p.warning, [name]: value } : null }));
	};
	const handleAddValue = (value) => {
		if (value)
			setState(p => ({
				...p, warning: { ...p.warning, validValues: [...p.warning.validValues, value] }
			}));
	};
	const handleAdd = () => {
		setIsCreate(true);
		dispatch(validationDispatcher.addValidationsData(state));
	};
	const handleRemoveOS = (OSIndex) => {
		const validData = Object.assign({}, state);
		validData.warning.validValues.splice(OSIndex, 1);
		setState(validData);
	}

	return (
		<Modal backdrop={'static'} isOpen={isOpen} toggle={toggle} contentClassName='p-0'
			className={`${className} shadow`} {...props}>
			<ModalHeader toggle={!loading && toggle}>{title}</ModalHeader>
			<ModalBody>
				<div>
					<div className="row mt-3 validation-row">
						<div className="col-12 col-md-3 d-flex align-items-center">
							<h5 className=" validation-labels">Input Name</h5>
						</div>
						<div className="col-12 col-md-9">
							<Input name='inputName' onChange={(e) => handleChangeValues(e)} placeholder='vCPU' type="text"
								invalid={errors.inputName}
								value={state.inputName} />
							<FormFeedback>
								{errors.inputName}
							</FormFeedback>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-12 col-md-3 d-flex align-items-center">
							<h5 className=" validation-labels">Input Type</h5>
						</div>
						<div className="col-12 col-md-9">
							<UncontrolledDropdown>
								<ButtonGroup dir="ltr" className="w-100">
									<DropdownToggle outline className="w-100 text-left rounded-0 validation-dropdown-style">
										{state.type || 'Select...'}
									</DropdownToggle>
									<DropdownToggle outline className="validation-icon icon--right rounded-0">
										<ChevronDownIcon />
									</DropdownToggle>
								</ButtonGroup>
								<DropdownMenu right className="dropdown__menu">
									<DropdownItem onClick={(e) => typeChangeHandler(e.currentTarget.textContent)}>Numeric</DropdownItem>
									<DropdownItem onClick={(e) => typeChangeHandler(e.currentTarget.textContent)}>Category</DropdownItem>
									<DropdownItem onClick={(e) => typeChangeHandler(e.currentTarget.textContent)}>FreeText</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					</div>
					{
						state.type === 'Numeric' && (
							<div className="row mt-3">
								<div className="col-12 col-md-3 d-flex align-items-center">
									<h5 className=" validation-labels">Valid Values</h5>
								</div>
								<div className="col-12 col-md-4 mb-3 mb-sm-0">
									<div className="form__form-group mb-0">
										<div className="form__form-group-field client-project-manager-search-input">
											<Input type="text" name='>=' value={state.validValues?.greaterThan[">="][0][1]}
												onChange={handleNumericInputChange} />
											<div className="form__form-group-icon clients-validation-icon h-auto">
												<GreaterThanIcon />
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-4">
									<div className="form__form-group mb-0">
										<div className="form__form-group-field client-project-manager-search-input">
											<Input type="text" name='<=' value={state.validValues?.lessThan["<="][0][1]}
												onChange={handleNumericInputChange} />
											<div className="form__form-group-icon clients-validation-icon h-auto">
												<LessThanIcon />
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					}
					{
						state.type === 'Category' && <div className="row mt-3">
							<div className="col-12 col-md-3 d-flex align-items-center">
								<h5 className=" validation-labels">Valid Values</h5>
							</div>
							<div className="col-10 col-md-6 ">
								<div className="form__form-group mb-0">
									<div className="form__form-group-field client-project-manager-search-input">
										<Input type="text" name='value' value={category}
											onChange={(e) => setCategory(e.target.value)} />
									</div>
								</div>
							</div>
							<div className="col-2 col-md-3 pl-0">
								<AddCircleOutlineIcon onClick={() => handleAddValue(category)}
									className="add-circle-icon pointer" />
							</div>
							<div className="offset-12 offset-md-2 col-12 mt-3 w-auto ">
								{state.warning?.validValues?.map((x, badgeIndex) =>
									<Badge key={x} className={` ${randomBadgeColor(badgeIndex)}`}>
										{x.toUpperCase()}
										<CloseIcon onClick={() => handleRemoveOS(badgeIndex)} className='ml-2' />
									</Badge>)}
							</div>
						</div>
					}
				</div>
			</ModalBody>
			<ModalFooter>
				<Button disabled={!state.inputName || !state.type || errors.inputName || loading} className='mb-0' size={'sm'}
					color="primary"
					onClick={handleAdd}>
					{loading ? <ClipLoader size={30} color='black' /> : 'Add'}
				</Button>
			</ModalFooter>
		</Modal>
	)
};

export default AddVariable;
const randomBadgeColor = (i) => ['validation-badge-success', 'validation-badge-primary', 'validation-badge-danger', 'validation-badge-warning'][i % 4];
