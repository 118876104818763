import { createActions, createReducer } from "reduxsauce";
import {
	deleteProject as deleteProjectApi,
	deleteScenario as deleteScenarioApi,
	getClientData as clientDataApi,
	updateProject as updateProjectApi
} from "../../api/admin";

const getClientsData = (data, dataId, pm) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingClientData());
		clientDataApi(data, dataId, pm).then((response) => {
			dispatch(Creators.getClientsDataSuccess(response));
		}).catch((error) => {
			dispatch(Creators.getClientsDataError(error));
		});
	};
}

//Delete client project
const deleteProject = (data) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingDeleteProject());
		deleteProjectApi(data).then((response) => {
			dispatch(Creators.deleteProjectSuccess(data));
		}).catch((error) => {
			dispatch(Creators.deleteProjectError(error));
		});
	};
}
const updateProject = (clientId, data) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingUpdateProject());
		updateProjectApi(clientId, data).then((response) => {
			dispatch(Creators.updateProjectSuccess(data));
		}).catch((error) => {
			dispatch(Creators.updateProjectError(error));
		});
	};
}
//Delete client project scenarios
const deleteScenario = (data) => {
	return (dispatch) => {
		dispatch(Creators.isLoadingDeleteScenario());
		deleteScenarioApi(data).then((response) => {
			dispatch(Creators.deleteScenarioSuccess(data.dataId, data.id));
		}).catch((error) => {
			dispatch(Creators.deleteProjectError(error));
		});
	};
}

export const { Types, Creators } = createActions({
	getClientsData,
	deleteProject,
	deleteScenario, updateProject,
	getClientsDataSuccess: ['clientData'],
	getClientsDataError: ['error'],
	isLoadingClientData: [],
	deleteProjectSuccess: ['data'],
	deleteProjectError: ['error'],
	isLoadingDeleteClient: [],
	updateProjectSuccess: ['data'],
	updateProjectError: ['error'],
	isLoadingUpdateProject: [],
	deleteScenarioSuccess: ['projectId', 'scenarioId'],
	assignClientSuccess: ['userId', 'clientId'],
	assignClientError: ['error'],
	unAssignClientSuccess: ['userId', 'clientId'],
	unAssignClientError: ['error'],
	assignProjectSuccess: ['userId', 'data'],
	assignProjectError: ['error'],
	// unAssignProject,
	unAssignProjectSuccess: ['userId', 'data'],
	unAssignProjectError: ['error'],
	isLoadingAssignProject: [],
	isLoadingUnAssignProject: [],
	isLoadingUnAssignClient: [],
	isLoadingAssignClient: [],
	isLoadingDeleteScenario: [],
	isLoadingDeleteProject: [],
});

const initialState = {
	clientData: null,
	users: [],
	isError: false,
	isLoading: false,
	isDeleteLoading: false,
	isLoadingDeleteProject: false,
	isLoadingDeleteScenario: false,
	isDeleteError: null,
	isLoadingAssignProject: false,
	isLoadingUnAssignProject: false,
};

const getClientsDataSuccess = (state = initialState, action) => {
	if (!Array.isArray(action.clientData.data)) {
		const data = action.clientData.data;
		let clientData = state.clientData;
		if (clientData) {
			const index = clientData.findIndex(p => p._id === data._id);
			if (index >= 0) {
				clientData = Object.assign(clientData, { [index]: data });
			} else {
				clientData = [...clientData, data];
			}
		} else {
			clientData = [data];
		}
		return {
			...state,
			clientData,
			isLoading: false,
			isError: '',
		};
	}
	return {
		...state,
		clientData: action.clientData.data,
		users: action.clientData.users,
		isLoading: false,
		isError: '',
	};
};

const getClientsDataError = (state = initialState, action) => {
	return {
		...state,
		clientData: null,
		isLoading: false,
		isError: action.error,
	};
};

const isLoadingClientData = (state = initialState, action) => {
	return {
		...state,
		isLoading: true,
		isError: '',
	};
};

const deleteProjectSuccess = (state = initialState, action) => {
	if (!state.clientData) return state;
	return {
		...state,
		clientData: state.clientData.filter(p => p._id !== action.data._id),
		isLoadingDeleteProject: false,
		isDeleteError: '',
	};
};

const deleteScenarioSuccess = (state = initialState, action) => {
	const allData = [...state.clientData];
	const index = allData?.findIndex(p => p._id === action.projectId);
	const scenarios = allData[index]?.scenarios.filter(p => p._id !== action.scenarioId);
	allData[index].scenarios = scenarios;

	return {
		...state,
		clientData: allData,
		isLoadingDeleteScenario: false,
		isDeleteError: '',
	};
};

const deleteProjectError = (state = initialState, action) => {
	return {
		...state,
		isLoadingDeleteProject: false,
		isLoadingDeleteScenario: false,
		isDeleteError: action.error,
	};
};

const isLoadingDeleteProject = (state = initialState, action) => {
	return {
		...state,
		isLoadingDeleteProject: true,
		isDeleteError: '',
	};
};
const isLoadingDeleteScenario = (state = initialState, action) => {
	return {
		...state,
		isLoadingDeleteScenario: true,
		isDeleteError: '',
	};
};

const updateProjectSuccess = (state = initialState, action) => {
	if (!state.clientData) return state;
	const project = state.clientData.find(p => p._id === action.data._id);
	project.status = action.data.status;
	return {
		...state,
		clientData: state.clientData,
		isDeleteLoading: false,
		isDeleteError: '',
		isUpdateLoading: false,
		isUpdateError: '',
	};
};

const updateProjectError = (state = initialState, action) => {
	return {
		...state,
		isUpdateLoading: false,
		isUpdateError: action.error,
	};
};

const isLoadingUpdateProject = (state = initialState, action) => {
	return {
		...state,
		isUpdateLoading: true,
		isUpdateError: '',
	};
};


export default createReducer(initialState, {
	[Types.GET_CLIENTS_DATA]: getClientsData,
	[Types.DELETE_PROJECT]: deleteProject,
	[Types.DELETE_PROJECT_ERROR]: deleteProjectError,
	// [Types.IS_LOADING_DELETE_CLIENT]: isLoadingDeleteClient,
	[Types.UPDATE_PROJECT]: updateProject,
	[Types.UPDATE_PROJECT_SUCCESS]: updateProjectSuccess,
	[Types.UPDATE_PROJECT_ERROR]: updateProjectError,
	[Types.IS_LOADING_UPDATE_PROJECT]: isLoadingUpdateProject,
	[Types.DELETE_PROJECT_SUCCESS]: deleteProjectSuccess,
	[Types.DELETE_SCENARIO]: deleteScenario,
	[Types.GET_CLIENTS_DATA_SUCCESS]: getClientsDataSuccess,
	[Types.GET_CLIENTS_DATA_ERROR]: getClientsDataError,
	[Types.DELETE_SCENARIO_SUCCESS]: deleteScenarioSuccess,
	[Types.IS_LOADING_CLIENT_DATA]: isLoadingClientData,
	[Types.IS_LOADING_DELETE_PROJECT]: isLoadingDeleteProject,
	[Types.IS_LOADING_DELETE_SCENARIO]: isLoadingDeleteScenario,
});
