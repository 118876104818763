import React, { Component } from 'react';
import { Doughnut, Chart, } from 'react-chartjs-2';

const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
	draw: function () {
		originalDoughnutDraw.apply(this, arguments);
		const chart = this.chart.chart;
		const ctx = chart.ctx;
		const width = chart.width;
		const height = chart.height;
		const fontSize = 0.9;
		ctx.font = fontSize + "Arial";
		ctx.textBaseline = "center";
		const text = chart.config.data.text,
			textX = Math.round((width - ctx.measureText(text).width) / 2),
			textY = height / 1.35;
		ctx.fillText(text, textX, textY);
		const title = chart.config.data.title,
			titleX = Math.round((width - ctx.measureText(title).width) / 2),
			titleY = height / 1.7;
		ctx.fillText(title, titleX, titleY);
	}
});
class DynamiclyRefreshedDoughnut extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				labels: [],
				datasets: [{
					data: [],
					backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
					hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
					borderColor: ['#FF6384', '#36A2EB', '#FFCE56'],
				}]
			},
			options: {
				legend: {
					display: false
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							return `${data?.labels[tooltipItem['index']]} : $ ${data?.datasets[0]?.data[tooltipItem?.index].toFixed(2)}`;
						}
					},
				},
			}
		};
	}
	render() {
		const { data, options } = this.state;
		const { chartData, showText, cutoutPercentage = 50, title = "", canShowLegend = true } = this.props;
		if (chartData) {
			data.datasets[0].data = chartData.data;
			data.labels = chartData.labels;
			if (chartData.colors) {
				data.datasets[0].backgroundColor = chartData.colors;
				data.datasets[0].borderColor = chartData.colors;
				data.datasets[0].hoverBackgroundColor = chartData.colors;
			}
			data.title = title;
			data.text = showText ? `${chartData.data.reduce((p, c) => p + c, 0)?.toFixed(2)}/y` : ''
		}
		return (
			<Doughnut data={data} options={{ ...options, legend: { display: canShowLegend }, cutoutPercentage, }} />
		);
	}
}

export default (DynamiclyRefreshedDoughnut);
