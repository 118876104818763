import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Table,
	UncontrolledDropdown,
} from 'reactstrap'
import { ClipLoader } from "react-spinners";
import AddIcon from 'mdi-react/AddCircleIcon';
import ShareIcon from 'mdi-react/SendIcon';
import DeleteIcon from "mdi-react/DeleteIcon";
import ViewIcon from "mdi-react/FileEyeIcon";
import { useDispatch, useSelector } from 'react-redux';
import { Creators as clientDataDuck } from "../../../../store/ducks/clientData";
import { Creators as suggestionsDuck } from "../../../../store/ducks/suggestions";
import ConfirmDialog from '../../HomePage/ConfirmDialog';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatCurrency, useUserRole } from "../../../../utils";
import ShareScenario from '../ReviewSuggesions/ShareScenario';
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import classnames from "classnames";

function ClientData({ selectedClient, selectedProject, match, ...props }) {
	// const client = props.location?.state;
	const client = selectedClient || selectedProject;
	const history = useHistory();
	const dispatch = useDispatch();
	const { clientData, isLoading: clientDataLoading, isDeleteLoading, isUpdateLoading, isDeleteError, isLoadingDeleteScenario, isLoadingDeleteProject } = useSelector(state => state.clientsData);
	const [projectData, setProjectData] = useState(null);
	const [scenarioData, setScenarioData] = useState(null);
	const [shareModal, setShareModal] = useState(null);
	const [updatingProjectStatus, setUpdatingProjectStatus] = useState({ pId: null, isUpdating: false });

	const userRole = useUserRole();
	useEffect(() => {
		dispatch(clientDataDuck.getClientsData(client._id))
	}, [client._id]);

	useEffect(() => {
		if (!isUpdateLoading)
			setUpdatingProjectStatus({ pId: null, isUpdating: false })
	}, [isUpdateLoading]);

	useEffect(() => {
		if (!isDeleteLoading && !isLoadingDeleteScenario && !isLoadingDeleteProject && (projectData || scenarioData) && !isDeleteError) {
			confirmationDeleteProject();
		}
		if (isDeleteError && !isDeleteLoading && !isLoadingDeleteScenario && !isLoadingDeleteProject && (projectData || scenarioData)) {
			toast.error(isDeleteError.message, {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	}, [isDeleteLoading, isLoadingDeleteScenario, isLoadingDeleteProject]);

	const viewScenarioHandler = (suggestions, clientData) => {
		dispatch(suggestionsDuck.clearSuggestionsData(null));
		history.push(`/homepage/client/${client._id}/project/review-suggestions/${suggestions._id}`, {
			client: client._id,
			clientData,
		})
	};

	const newScenarioHandler = clientData => {
		history.push(`/homepage/client/${client._id}/project/review-assumptions`, {
			client: client._id,
			clientData,
			welcomeData: client
		});
	};

	const deleteScenarioHandler = ({ id, dataId, clientId }) => {
		dispatch(clientDataDuck.deleteScenario({ id, dataId, clientId }));
	}

	const deleteProjectHandler = ({ _id, clientId }) => {
		dispatch(clientDataDuck.deleteProject({ _id, clientId }));
	}

	const selectDeleteProjectHandler = (_id) => {
		setProjectData({ _id, clientId: client._id });
	}

	const selectDeleteScenarioHandler = (id, dataId) => {
		setScenarioData({ id, dataId, clientId: client._id });
	}

	const confirmationDeleteProject = () => {
		setProjectData(null);
		setScenarioData(null);
	}

	const linkShareHandler = (data) => {
		setShareModal(data)
	}
	const isAdminOrTenantManager = userRole === 2 || userRole === 0;
	const isClientManager = userRole === 1;
	const handleChangeStatus = (_id, status) => {
		setUpdatingProjectStatus({ pId: _id, isUpdating: true })
		dispatch(clientDataDuck.updateProject(client._id, { _id, status }));
	};
	return (
		<div>
			<ConfirmDialog
				title={`Delete ${projectData ? "Project" : "Scenario"} ?`}
				subTitle={`Do you want to delete  ${projectData ? " Project" : "Scenario"} ?`}
				isOpen={projectData || scenarioData}
				isLoading={isDeleteLoading || isLoadingDeleteScenario || isLoadingDeleteProject}
				deleteHandler={() => projectData ? deleteProjectHandler(projectData) : deleteScenarioHandler(scenarioData)}
				closeHandler={confirmationDeleteProject} />
			{shareModal && <ShareScenario
				linkShareHandler={linkShareHandler}
				dataId={shareModal.dataId}
				shareModal={shareModal}
				clientId={client._id} scenarioId={shareModal.scenarioId}
			/>}
			{!clientDataLoading && clientData && clientData.map(p => {
				return (
					<React.Fragment key={p._id}>
						<Card className={classnames("shadow pb-0 mt-3 mb-3 mt-xl-0", { selected: p === client })}>
							<CardBody className="p-3">
								<div className="row">
									<div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
										<div className="row">
											<div className="col-6 col-lg-12 mb-lg-3 col-xl-6">
												<h4 className="welcome-title-card d-flex justify-content-center">{p.label}</h4>
												<h4
													className="align-items-center scenario-sub-title d-flex justify-content-center cursor-pointer"
													onClick={() => newScenarioHandler(p)}><AddIcon className="mr-1  add-icon" />New Scenario
												</h4>
												{(isAdminOrTenantManager || isClientManager) && <h4
													className="align-items-center scenario-sub-title d-flex justify-content-center cursor-pointer"
													onClick={() => selectDeleteProjectHandler(p._id)}><DeleteIcon className="mr-1  add-icon " />Delete
													Project
												</h4>}
											</div>
											<div className="col-6 col-lg-12 mb-lg-3 col-xl-6">
												<h4 className="welcome-server-card  d-flex justify-content-center">Servers</h4>
												<h4 className="server-length-title d-flex justify-content-center">{p.count}</h4>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<UncontrolledDropdown>
													<ButtonGroup dir="ltr" className="w-100">
														<DropdownToggle
															disabled={updatingProjectStatus.pId === p._id && updatingProjectStatus.isUpdating} outline
															className="w-100 text-left rounded-0 validation-dropdown-style">
															{p.status}
														</DropdownToggle>
														<DropdownToggle
															disabled={updatingProjectStatus.pId === p._id && updatingProjectStatus.isUpdating}
															outline className="validation-icon icon--right rounded-0">
															{(updatingProjectStatus.pId === p._id && updatingProjectStatus.isUpdating) ?
																<div className="d-flex justify-content-center align-items-center">
																	<ClipLoader size={15} color={"#4ce1b6"} loading={true} />
																</div>
																: <ChevronDownIcon />}
														</DropdownToggle>
													</ButtonGroup>
													<DropdownMenu right className="dropdown__menu">
														<DropdownItem disabled={p.status === 'in-progress'}
															onClick={(e) => handleChangeStatus(p._id, 'in-progress')}>
															In Progress
														</DropdownItem>
														<DropdownItem disabled={p.status === 'complete'}
															onClick={(e) => handleChangeStatus(p._id, 'complete')}>
															Complete
														</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											</div>
										</div>
									</div>
									<div
										className={`col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 ${!(p.scenarios || []).filter(p => !!p).length && 'p-0'}`}>
										<Table responsive>
											<thead>
												<tr>
													<th>Scenario</th>
													<th>Description</th>
													<th>TotalCost</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{(p.scenarios || []).filter(p => !!p).map(x => {
													return (
														<tr key={x._id}>
															<th>{x.label}</th>
															<th>{x.description}</th>
															<th>{formatCurrency(x.totalCost)}</th>
															<th>
																<div className="d-flex">
																	<Button color="primary" size="sm" className="btn btn-primary mb-0"
																		onClick={() => viewScenarioHandler(x, p)}><ViewIcon className="m-0" /></Button>
																	<Button color="outline-danger" size="sm"
																		className="btn btn-outline-danger mb-0 d-flex align-items-center"
																		onClick={() => selectDeleteScenarioHandler(x._id, p._id)}><DeleteIcon
																			className="m-0" /></Button>
																	<Button color="outline-primary" size="sm"
																		className="btn btn-outline-primary mb-0 d-flex align-items-center"
																		onClick={() => linkShareHandler({
																			dataId: p._id,
																			scenarioId: x._id
																		})}><ShareIcon className="m-0" /></Button>
																</div>
															</th>
														</tr>
													)
												})}
											</tbody>
										</Table>
									</div>
								</div>
							</CardBody>
						</Card>
					</React.Fragment>
				)
			})}
			{clientDataLoading && (
				<div className="d-flex justify-content-center align-items-center">
					<ClipLoader size={50} color={"#4ce1b6"} loading={true} />
				</div>
			)}
			{!clientDataLoading && clientData && !clientData.length && <div className="card shadow h-auto">
				<div className="card-body p-0">
					<Col md={12} lg={12}>
						<Card>
							<CardBody className="p-0">
								<div className="tabs tabs--bordered-bottom">
									<div className="tabs__wrap">
										<div className="d-flex align-items-center justify-content-center p-5">
											<h4>No Projects assigned/found..</h4>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</div>
			</div>}
		</div>
	)
}

export default withRouter(ClientData)
