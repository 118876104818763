import React, { useState } from 'react'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Close from 'mdi-react/CloseIcon'
import Assumptions from "../../ReviewAssumptions/Assumptions/Assumptions";
import { getAssumptions } from "../../../../../api/admin";

export default function ModifyAssumptionPanel({ isServerDetail, assumptionsData, recalculateHandler, clientData, closeHandler, isOpen }) {
	const [assumptions, setAssumptions] = useState(assumptionsData);

	const resetAssumptionHandler = () => {
		setAssumptions(prev => ({ ...prev, isLoading: true }));
		getAssumptions().then((response) => {
			setAssumptions(prev => ({ ...prev, data: response, isLoading: false }))
		}).catch((err) => { setAssumptions({ isLoading: false }); })
	};

	const handleChange = (data) => {
		setAssumptions(prev => ({ ...prev, data: data }));
	};

	const migrationStrategyHandler = (migrationStrategy, name) => {
		const findIndex = assumptions.data.findIndex(a => a.name === name);
		setAssumptions({
			...assumptions,
			data: Object.assign(assumptions.data, {
				[findIndex >= 0 ? findIndex : assumptions.data.length]: {
					...migrationStrategy,
					attributeType: 'migrationStrategy',
					searchThreshold: []
				}
			})
		});
	}

	const utilizationOnchangeHandler = (utilizationGroup, name) => {
		const findIndex = assumptions.data.findIndex(a => a.name === name);
		setAssumptions({
			...assumptions,
			data: Object.assign(assumptions.data, {
				[findIndex >= 0 ? findIndex : assumptions.data.length]: {
					...utilizationGroup,
					attributeType: 'utilizationGroup',
					searchThreshold: []
				}
			})
		});
	}

	const updatePricingGroups = (data) => {
		data = assumptions.data.map(a => {
			const findIndex = data.findIndex(p => a.name === p.name);
			if (findIndex >= 0) {
				return {
					...a,
					...data[findIndex]
				}
			} return a;
		})
		setAssumptions({
			...assumptions,
			data
		});
	}

	return (
		<div>
			<SlidingPane
				className="ltr-support theme-light"
				overlayClassName="mb-4"
				isOpen={isOpen}
				closeIcon={<Close style={{ height: "20px", width: "20px" }} />}
				title="Modify Assumption"
				onRequestClose={closeHandler} >
				<Assumptions
					handleContinue={() => recalculateHandler(assumptions.data)}
					isResetLoading={assumptions?.isLoading}
					closeHandler={closeHandler}
					resetAssumptionHandler={resetAssumptionHandler}
					isServerDetail={isServerDetail}
					updatePricingGroups={updatePricingGroups}
					handleChange={handleChange}
					assumptionsData={assumptions?.data}
					rows={clientData}
					groupsOnchangeHandler={(migrationStrategy, name) => migrationStrategyHandler(migrationStrategy, name)}
					utilizationOnchangeHandler={(utilizationGroup, name) => utilizationOnchangeHandler(utilizationGroup, name)}
				/>
			</SlidingPane>
		</div>
	)
}
