import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config from './config';
import {Provider} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import userReducer from "./store/ducks/profile";
import clientsReducer from "./store/ducks/client";
import clientDataReducer from "./store/ducks/clientData";
import suggestionsDataReducer from "./store/ducks/suggestions";
import thunk from "redux-thunk";
import enumsReducer from './store/ducks/enums';
import validationsReducer from './store/ducks/validations';
import usersReducer from './store/ducks/users';
import projectsReducer from './store/ducks/projects';
import scenarioLinkReducer from './store/ducks/ScenarioLink';

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	},
	Storage: {
		region: config.s3.REGION,
		bucket: config.s3.BUCKET,
		identityPoolId: config.cognito.IDENTITY_POOL_ID
	},
	API: {
		endpoints: [{
			name: "client",
			endpoint: config.apiGateway.URL,
			region: config.apiGateway.REGION
		}]
	}
});

const rootReducer = combineReducers({
	user: userReducer,
	clients: clientsReducer,
	clientsData: clientDataReducer,
	suggestions: suggestionsDataReducer,
	enums: enumsReducer,
	validations: validationsReducer,
	users: usersReducer,
	project: projectsReducer,
	scenarioLink: scenarioLinkReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const persistConfig = { key: "root-", storage };
// const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
// const persistor = persistStore(store);
ReactDOM.render(
	<Provider store={store}>
		 {/*<PersistGate loading={null} persistor={persistor}> */}
		<App/>
		 {/*</PersistGate> */}
	</Provider>, document.getElementById('root')
);

serviceWorker.unregister();
