import React, { createContext, useEffect, useState } from 'react'
import {
	Button,
	Card,
	CardBody,
	Col,
	Collapse,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane
} from 'reactstrap';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import ServerSuggestions from './ServerSuggestions';
import ModifyAssumptionPanel from './ModifyAssumptionPanel';
import { Builder, Query, Utils as QbUtils } from 'react-awesome-query-builder';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import "antd/dist/antd.css"
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import Back from 'mdi-react/ArrowLeftIcon'
import { submitAssumptions } from "../../../../../api/admin";
import { ClipLoader } from "react-spinners";
import { Creators as suggestionsDuck } from "../../../../../store/ducks/suggestions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import StorageSuggestions from './StorageSuggestions';
import { Creators as enumsDuck } from "../../../../../store/ducks/enums";

const { loadFromJsonLogic } = QbUtils;
const InitialConfig = AntdConfig;
const defaultSelectedManualValues = ['instanceType', 'vcpu', 'memory', 'operatingSystem', 'capacitystatus', 'tenancy', 'instanceFamily', 'licenseModel'];

export const ContextProvider = createContext();

export default function ServerDetail(props) {
	const shared = props.location.state.shared
	const inputWidget = (label, type, min, max) => {
		let field = { label, type, preferWidgets: [type], valueSources: ['value'] };
		if (type === 'number')
			field.fieldSettings = { min, max, };
		return field
	};
	const selectWidget = (label, options) => ({
		label,
		type: 'select',
		fieldSettings: { listValues: options.map(o => ({ value: o, title: o })) },
		preferWidgets: ['select'],
		valueSources: ['value'],
		operators: ['select_not_any_in', 'select_any_in', 'select_equals', 'select_not_equals'],
	});
	const loadedInitValue = useSelector(state => state.suggestions.suggestions?.manualSearch);
	const history = useHistory();
	const dispatch = useDispatch();
	const { suggestions, isLoadingManualSearch, isErrorManualSearch } = useSelector(state => state.suggestions);
	const { scenarios } = useSelector(state => state.scenarioLink);
	const { enums } = useSelector(state => state.enums);
	const queryValue = { "id": QbUtils.uuid(), "type": "group" };
	const [config, setConfig] = useState({ ...InitialConfig });
	const [tree, setTree] = useState(QbUtils.checkTree(QbUtils.loadTree(queryValue), config));
	const [assumptionsData, setAssumptionsData] = useState(history.location.state.assumptionsData);
	const [suggestion, setSuggestions] = useState({ isLoading: false, data: history.location.state.suggestion });
	const server = history.location.state.server;
	const [isPaneOpen, setIsPaneOpen] = useState(false);
	const [isManualSearch, setIsManualSearch] = useState(false);
	const [activeTab, setActiveTab] = useState('1');
	const [isApplyingManualSearch, setIsApplyingManualSearch] = useState(false);
	const selectedAssumption = assumptionsData?.find(a => a.name === "storage")?.pricingAttributes?.find(p => p.name === "storageGB");
	const { id: clientId } = props.match?.params;

	useEffect(() => {
		if (enums) {
			const manualSearchData = [...enums];
			let configs = {};
			manualSearchData.forEach(p => {
				if (p.type === "number") {
					const sortedArray = p.values.sort((a, b) => a - b);
					configs = {
						...configs,
						[p.name]: inputWidget(p.name, p.type, sortedArray[0], sortedArray[sortedArray.length - 1])
					}
				} else configs = { ...configs, [p.name]: selectWidget(p.name, p.values) }
			});
			setConfig((prev) => ({ ...prev, fields: { ...configs } }));
		} else {
			dispatch(enumsDuck.getEnums({ names: [] }))

		}
	}, [enums]);


	useEffect(() => {
		if (suggestions && suggestions.assumptions && suggestions.assumptions.default) {
			setAssumptionsData(suggestions.assumptions[clientId] || suggestions.assumptions.default)
		}
	}, [suggestions && suggestions.assumptions]);

	useEffect(() => {
		if (suggestions && suggestions?.products)
			setSuggestions({
				data: suggestions?.products[server._id] || scenarios?.products[server._id],
				isLoading: false
			});
	}, [suggestions && suggestions?.products]);

	useEffect(() => {
		const serverIds = server._id;
		if (loadedInitValue) {
			const emptyInitValue = { "id": QbUtils.uuid(), "type": "group" };
			const initValue = loadedInitValue[serverIds] && Object.keys(loadedInitValue[serverIds]).length > 0 ? loadedInitValue[serverIds] : emptyInitValue;
			const initTree = QbUtils.checkTree(QbUtils.loadTree(initValue), config);
			setTree(initTree);
		} else {

			let defaultValue = { or: [] };
			if (enums) {
				const manualSearchData = [...enums];
				manualSearchData.forEach(p => {
					if (defaultSelectedManualValues.includes(p.name)) {
						if (p.type === "number") {
							const sortedArray = p.values.sort((a, b) => a - b);
							defaultValue.or.push({
								"==": [
									{
										var: p.name
									},
									sortedArray[0] || ""
								]
							})
						} else {
							defaultValue.or.push({
								"==": [
									{
										var: p.name
									},
									p.values || ""
								]
							})
						}
					}
				});
			}
			if (config.fields) {
				const initTreeLogic = defaultValue && Object.keys(defaultValue).length > 0 ? defaultValue : undefined;
				const initTree = QbUtils.checkTree(loadFromJsonLogic(initTreeLogic, config), config);
				setTree(QbUtils.checkTree(QbUtils.loadTree(initTree), config));
			}
		}
	}, [loadedInitValue, config]);

	useEffect(() => {
		if (!isLoadingManualSearch && isApplyingManualSearch && !isErrorManualSearch) {
			setIsApplyingManualSearch(false);
		} else if (!isLoadingManualSearch && isApplyingManualSearch && isErrorManualSearch) {
			toast.error(isErrorManualSearch.message, {
				position: toast.POSITION.TOP_RIGHT,
			});
			setIsApplyingManualSearch(false);
		}
	}, [isErrorManualSearch, isLoadingManualSearch]);

	const submitAssumptionHandler = (assumptions) => {
		setIsPaneOpen(false);
		const serverIds = [server._id];
		setSuggestions(prev => ({ ...prev, isLoading: true }));
		setAssumptionsData(assumptions);
		submitAssumptions({ serverIds, assumptions }).then((response) => {
			dispatch(suggestionsDuck.getSuggestionDataSuccess({
				...suggestions,
				products: { ...suggestions.products, [server._id]: response[server._id] },
				assumptions: { ...suggestions.assumptions, [server._id]: assumptions },
			}));
			setSuggestions({ data: response[server._id], isLoading: false });
		}).catch((err) => {
			setSuggestions({ data: {}, isLoading: false });
		})
	};

	const renderBuilder = (props) => (
		<div div className="query-builder-container" style={{ padding: '10px' }}>
			<div className="query-builder qb-lite">
				<Builder {...props} />
			</div>
		</div>
	)

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab)
		}
	};

	const assumptionPanelCloseHandler = () => {
		setIsPaneOpen(false);
	};

	const suggestionChangeHandler = (suggestion) => {
		const index = suggestions?.products[server._id].findIndex(p => p._id === suggestion._id);
		dispatch(suggestionsDuck.getSuggestionDataSuccess({
			...suggestions,
			products: {
				...suggestions.products,
				[server._id]: Object.assign(suggestions.products[server._id], { [index]: suggestion }),
			},
		}));
	};

	const manualSearchHandler = () => setIsManualSearch(!isManualSearch);

	const serverDetailBackHandler = () => {
		history.goBack();
	};

	const onChange = (immutableTree, config) => {
		setTree(QbUtils.checkTree(immutableTree, config))
	}

	const applyManualSearch = () => {
		const serverIds = [server._id];
		setIsApplyingManualSearch(true);
		dispatch(suggestionsDuck.applyManualSearch({
			serverIds,
			query: QbUtils.mongodbFormat(tree, config)
		}, QbUtils.getTree(tree, false)))
	};

	return (
		<div>
			<Container className="dashboard server-detail">
				<Row>
					<Col md={12}>
						<Card>
							<CardBody className="server-detail-card">
								{suggestion.isLoading ? (
									<div className="d-flex justify-content-center align-items-center">
										<ClipLoader size={50} color={"#4ce1b6"} loading={true} />
									</div>
								) :
									<>
										<div className="row">
											<div className="col-12 mb-2">
												<div className="row d-flex align-items-center">
													<Back className="mr-2 back-btn" color="#70bbfd" onClick={serverDetailBackHandler} />
													<h2 className="client-server-title mb-0 ml-1">{server.serverName} Detail</h2>
												</div>
												<hr />
												<div className="row">
													<div className="col-6 col-md-6 col-xl-3  server-detail-title">
														<span className="server-instance-label mb-3">IOPS</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.IOPS : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">avgCPU</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.avgCPU : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">avgMemory</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.avgMemory : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">cores</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.cores : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">cpuGHZ</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.cpuGHZ : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">hyperVisor</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.hyperVisor : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">memory</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.memory : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">memoryGHZ</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.memoryGHZ : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">operatingSystem</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span
															className="server-instance-name mb-3">{server ? server.operatingSystem : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">operatingSystemVersion</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span
															className="server-instance-name mb-3">{server ? server.operatingSystemVersion : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">peakCPU</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.peakCPU : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">peakMemory</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.peakMemory : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">serverName</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.serverName : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">serverRole</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.serverRole : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">storageType</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.storageType : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">storageallocated</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span
															className="server-instance-name mb-3">{server ? server.storageallocated : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">storageused</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.storageused : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">vCPU</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.vCPU : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">vmName</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.vmName : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">peakDiskIO</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-name mb-3">{server ? server.peakDiskIO : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">dailyDiskChangeRate</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span
															className="server-instance-name mb-3">{server ? server.dailyDiskChangeRate : "-"}</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span className="server-instance-label mb-3">monthlyDiskGrowthRate</span>
													</div>
													<div className="col-6 col-md-6 col-xl-3 server-detail-title">
														<span
															className="server-instance-name mb-3">{server ? server.monthlyDiskGrowthRate : "-"}</span>
													</div>
												</div>
											</div>
										</div>
										{!shared &&
											<>
												<div>
													<div className="row mt-3 m-0 d-flex justify-content-end">
														<Button className="modal_ok btn btn-primary small-btn mb-2 " color="primary"
															onClick={() => setIsPaneOpen(true)}>Modify Assumptions </Button>
														<Button className="modal_ok btn btn-primary small-btn mb-2 mr-sm-3" color="primary">Exclude
															from
															Analysis </Button>
														<Button className="modal_ok btn btn-primary small-btn mb-2 " color="primary"
															onClick={manualSearchHandler}>Manual
															Search </Button>
														<ModifyAssumptionPanel isOpen={isPaneOpen}
															closeHandler={assumptionPanelCloseHandler}
															recalculateHandler={submitAssumptionHandler}
															assumptionsData={{ data: assumptionsData, }} />
													</div>
												</div>
												<Collapse isOpen={isManualSearch}>
													<div>
														<h3 className="manual-search-title">Manual Search</h3>
													</div>
													<div className="mt-3">
														<Query {...config} value={tree} onChange={onChange} renderBuilder={renderBuilder} />
													</div>
													<div className="row mt-3 m-0 d-flex justify-content-end">
														<Button className="modal_ok btn btn-primary mb-2 mr-1" color="primary"
															onClick={() => setIsManualSearch(!isManualSearch)}>Cancel</Button>
														<Button className="modal_ok btn btn-primary mb-2 mr-1" color="primary"
															disabled={isLoadingManualSearch || !QbUtils.mongodbFormat(tree, config)}
															onClick={applyManualSearch}>{
																isLoadingManualSearch ? <div className="d-flex justify-content-center align-items-center">
																	<ClipLoader size={20} loading={isLoadingManualSearch} />
																</div> : "Apply"
															}</Button>
													</div>
												</Collapse>
											</>
										}
										<div className="tabs tabs--justify tabs--bordered-bottom mt-5">
											<div className="tabs__wrap">
												<ContextProvider.Provider value={selectedAssumption}>
													<Nav tabs>
														<NavItem>
															<NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>
																Server Suggestions
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink className={classnames({ active: activeTab === '2' })} onClick={() => toggle('2')}>
																Storage Suggestion
															</NavLink>
														</NavItem>
													</Nav>
													<TabContent activeTab={activeTab}>
														<TabPane tabId="1">
															<ServerSuggestions server={server} suggestion={suggestion.data}
																onSuggestionChange={suggestionChangeHandler}
																assumptionsData={assumptionsData}
																shared={shared}
															/>
														</TabPane>
														<TabPane tabId="2">
															<StorageSuggestions
																server={server}
																assumptionsData={assumptionsData}
																suggestion={suggestion.data}
																onSuggestionChange={suggestionChangeHandler} shared={shared} />
														</TabPane>
													</TabContent>
												</ContextProvider.Provider>
											</div>
										</div>
									</>}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	)
}
