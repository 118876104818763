import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col } from "reactstrap";
import { ClipLoader } from "react-spinners";
import { useHistory, withRouter } from "react-router-dom";
import Assumptions from "../Form/ReviewAssumptions/Assumptions/Assumptions";
import { useDispatch, useSelector } from "react-redux";
import { Creators as assumptionDuck } from "../../../store/ducks/enums";
import { usePrevious } from "../../../utils";

function Assumption({ isServerDetail = false, closeHandler, ...props }) {
	const [assumption, setAssumption] = useState({});
	const { suggestions, isLoading } = useSelector((state) => state.suggestions);
	const clientId = props.match?.params.id;
	const clientData = props.location?.state?.clientData;
	const history = useHistory();
	const dispatch = useDispatch();
	const previousLoading = usePrevious(isLoading);
	const {
		assumptions,
		getAssumptionLoading,
		updateAssumptionLoading,
	} = useSelector((state) => state.enums);

	useEffect(() => {
		dispatch(assumptionDuck.getAssumption());
	}, []);

	// useEffect(() => {
	// 	if (!isLoading && previousLoading) {
	// 		history.push(`/homepage/client/${clientId}/project/review-suggestions/${suggestions._id || 'new-scenario'}`, {
	// 			assumptionsData: { default: assumptionsData.data },
	// 			clientData,
	// 			clientId,
	// 		})
	// 	}
	// }, [isLoading]);

	const handleChange = (data) => {
		// setAssumptionsData(prev => ({ ...prev, data: data }));
	};

	const updatedAssumptionHandler = (updatedAssumption) => {
		setAssumption((a) => ({ ...a, [updatedAssumption._id]: updatedAssumption }));
	};

	const handleContinue = () => {
		// const serverIds = clientData.rows.map(p => p._id);
		// dispatch(suggestionsDuck.getSuggestions({ serverIds, assumptions }));
		Object.values(assumption).forEach((element) => {
			dispatch(assumptionDuck.updateAssumption(element._id, element));
		});
	};

	return (
		<div>
			<Col md={12} lg={12}>
				<Card>
					<CardBody className="review-assumption-container sticky-assumptions-review-tabs-top-60">
						<Assumptions
							isServerDetail={isServerDetail}
							handleChange={handleChange}
							updatedAssumption={updatedAssumptionHandler}
							assumptionsData={assumptions}
							loading={getAssumptionLoading}
							handleContinue={handleContinue}
							isLoading={updateAssumptionLoading}
							isAssumptionPage
						/>
						{/* <div className="d-flex justify-content-center">
							<div>
								<Button
									color="primary"
									className="btn-sm"
									onClick={handleContinue}
								>
									{updateAssumptionLoading ? (
										<ClipLoader
											sizeUnit={"px"}
											size={20}
											color={"#fff"}
											loading={true}
										/>
									) : (
										"Save"
									)}
								</Button>
							</div>
						</div> */}
					</CardBody>
				</Card>
			</Col>
		</div>
	);
}

export default withRouter(Assumption);
