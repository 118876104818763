import { API } from "aws-amplify";
import * as pako from "pako";

export function addUsers(body) {
	return API.post("client", `/user`, { body });
}

export function getAllClient() {
	return API.get("client", `/client`, {});
}

export function getScenarioLink(id, dataId, clientId) {
	return API.get("client", `/getScenarioLink/${id}?dataId=${dataId}&clientId=${clientId}`, {});
}

export function refreshScenarioLinkCode(id, dataId, clientId) {
	return API.get("client", `/getScenarioLink/${id}?refreshCode=true&dataId=${dataId}&clientId=${clientId}`, {});
}

export function refreshScenarioLink(id, dataId, clientId) {
	return API.get("client", `/getScenarioLink/${id}?refreshLink=true&dataId=${dataId}&clientId=${clientId}`, {});
}

export function refreshScenarioLinkByCode(id, code) {
	return API.get("client", `/scenariosByCode/${id}?code=${code}`, {})
		.then(async ({ scenario, servers }) => {
			if (!scenario.products) {
				const res = await getScenarioProductsByCode(id, code, 1, 100);
				const allRes = await Promise.all(Array.from(Array(res.totalPages - 1))
					.map((_, i) => getScenarioProductsByCode(id, code, i + 2, 100)));
				const products = allRes.flatMap(p => p.docs)
					.concat(res.docs)
					.reduce((p, c) => ({ ...p, [c.serverId]: c.products }), {});
				scenario.products = products;
			}
			return { scenario, servers };
		});
}
export function getScenarioProductsByCode(id, code, page, limit) {
	return API.get("client", `/scenariosByCode/${id}/products?page=${page}&limit=${limit}&code=${code}`, {
		headers: {
			Accept: '*/*'
		}, responseType: 'base64'
	})
		.then(unzip);
}

export function updateClient(id, body) {
	return API.put("client", `/client/${id}`, { body });
}

export function addClient(body) {
	return API.post("client", `/client`, { body });
}

export function deleteClient(id) {
	return API.del("client", `/client/${id}`, {});
}

export function getUploadUrl(body) {
	return API.post("client", `/getUploadUrl`, { body });
}

export function validateClientData(body, id) {
	return API.post("client", `/clientData/validate/${id}`, { body });
}

export function assignClients(id, body) {
	return API.post("client", `/user/${id}/assignClient`, { body });
}

export function unAssignClients(id, body) {
	return API.post("client", `/user/${id}/unAssignClient`, { body });
}

export function assignProjects(id, body) {
	return API.post("client", `/user/${id}/assignProject`, { body });
}

export function unAssignProjects(id, body) {
	return API.post("client", `/user/${id}/unAssignProject`, { body });
}

export function insertClientData(body, id) {
	return API.post("client", `/clientData/insert/${id}`, { body });
}


export function getClientData(id, dataId, pm) {
	return API.get("client", `/clientData/${id}?dataId=${dataId || ''}&pm=${pm || ''}`);
}

export function getAssumptions(id) {
	return API.get("client", `/defaultAssumptions`);
}

export function updateAssumptions(id, body) {
	return API.put("client", `/assumptions/${id}`, { body });
}

export function submitAssumptions(body) {
	return API.post("client", `/submitAssumptions`, {
		body, headers: {
			Accept: '*/*'
		}, responseType: 'base64'
	})
		.then(unzip);
}

export function submitManualSearchQuery(body) {
	return API.post("client", `/manualSearch`, { body });
}

export function saveScenario(body) {
	return API.post("client", `/scenarios`, { body: { code: zip(body) } });
}

export function saveScenarioProducts(_id, body) {
	return API.post("client", `/scenarios/${_id}/products`, { body: { code: zip(body) } });
}

export function getScenario(_id, dataId, clientId) {
	return API.get("client", `/scenarios/${_id}?dataId=${dataId}&clientId=${clientId}`, {})
		.then(async ({ data: { scenarios: suggestions } }) => {
			if (!suggestions.products) {
				const res = await getScenarioProducts(_id, 1, 100);
				const allRes = await Promise.all(Array.from(Array(res.totalPages - 1))
					.map((_, i) => getScenarioProducts(_id, i + 2, 100)));
				const products = allRes.flatMap(p => p.docs)
					.concat(res.docs)
					.reduce((p, c) => ({ ...p, [c.serverId]: c.products }), {});
				suggestions.products = products;
			}
			return suggestions;
		});
}

export function getScenarioProducts(_id, page, limit) {
	return API.get("client", `/scenarios/${_id}/products?page=${page}&limit=${limit}`, {
		headers: {
			Accept: '*/*'
		}, responseType: 'base64'
	})
		.then(unzip);
}

export function exportScenario(_id, dataId, clientId, type) {
	return API.get("client", `/scenarios/${_id}/export?dataId=${dataId}&clientId=${clientId}&type=${type}`, {});
}

export function deleteScenario(body) {
	return API.del("client", `/scenarios`, { body });
}

export function deleteProject(body) {
	return API.del("client", `/clientData`, { body });
}

export function updateProject(clientId, body) {
	return API.put("client", `/clientData/${clientId}`, { body });
}

export function getEnumeration(body) {
	return API.post("client", `/enums`, { body });
}

export function getAllUsers() {
	return API.get("client", '/users', {});
}

export function getConfig() {
	return API.get("client", '/config', {});
}

export function updateUserProfile(id, body) {
	return API.put("client", `/user/${id}`, { body });
}

export function forgotPassword(body) {
	return API.post("client", `/user/forgot-password`, { body });
}

export function confirmPassword(body) {
	return API.post("client", `/user/confirm-password`, { body });
}

export function getValidations() {
	return API.get("client", `/validations`, {});
}

export function updateValidation(id, body) {
	return API.put("client", `/validation/${id}`, { body });
}

export function createValidation(body) {
	return API.post("client", `/validation`, { body }).then(p => p.data || p);
}

export function deleteValidation(id, body) {
	return API.del("client", `/validation/${id}`, { body });
}

export function updateLogin(body) {
	return API.post("client", `/user/update-login`, { body });
}

export function getAllProjects() {
	return API.get("client", `/projects`, {});
}

export function downloadFile(id) {
	return API.get("client", `/downloadFile/${id}`, {});
}

export function getAssignments(id, body) {
	return API.get("client", `/user/${id}/getAssignments`, { body });
}

export function getTimeline(id) {
	return API.get("client", `/users/${id}/activity`);
}

export function unzip(base64str) {
	const strData = atob(base64str);
	// Convert binary string to character-number array
	const charData = strData.split("").map((x) => {
		return x.charCodeAt(0);
	});
	// Turn number array into byte-array
	const binData = new Uint8Array(charData);
	return JSON.parse(pako.inflate(binData, { to: "string" }));
}

export function zip(data) {
	const bindata = pako.deflate(JSON.stringify(data));
	const compressed = btoa((new Uint8Array(bindata).reduce((d, b) => d + String.fromCharCode(b), '')));
	return compressed;
}
