import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown, Card, CardBody } from 'reactstrap';
import DynamiclyRefreshedDoughnut from '../../DynamiclyRefreshedDoughnut';
import ServerSuggestionsPricing from './ServerSuggestionsPricing';
import MigrationTable from "../../MigrationTable";
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import AddCircleOutlineIcon from "mdi-react/AddCircleIcon";
import { useSelector } from 'react-redux';

const groupPricingOptions = [
  { value: 'noMigration', year: '', label: 'No Migration' },
  { value: 'OnDemand', year: '', label: 'OnDemand' },
  { value: 'convertible1yrNo Upfront', year: '1', label: 'convertible 1yr No Upfront' },
  { value: 'convertible1yrPartial Upfront', year: '1', label: 'convertible 1yr Partial Upfront' },
  { value: 'convertible1yrAll Upfront', year: '1', label: 'convertible 1yr All Upfront' },
  { value: 'convertible3yrNo Upfront', year: '3', label: 'convertible 3yr No Upfront' },
  { value: 'convertible3yrPartial Upfront', year: '3', label: 'convertible 3yr Partial Upfront' },
  { value: 'convertible3yrAll Upfront', year: '3', label: 'convertible 3yr All Upfront' },
  { value: 'standard1yrNo Upfront', year: '1', label: 'standard 1yr No Upfront' },
  { value: 'standard1yrPartial Upfront', year: '1', label: 'standard 1yr Partial Upfront' },
  { value: 'standard1yrAll Upfront', year: '1', label: 'standard 1yr All Upfront' },
  { value: 'standard3yrNo Upfront', year: '3', label: 'standard 3yr No Upfront' },
  { value: 'standard3yrPartial Upfront', year: '3', label: 'standard 3yr Partial Upfront' },
  { value: 'standard3yrAll Upfront', year: '3', label: 'standard 3yr All Upfront' },
];

const groupColors = ["success", "warning", "danger", "primary", "secondary", "info", "light", "dark", "success", "warning"];

export default function ServerSuggestionsCard({
  server,
  assumptionsData,
  serverSelectHandler,
  clientServer,
  serverUpdateHandler,
  groupsOnchangeHandler,
  selectServer,
  storageProducts,
  ...props
}) {
  const { costs, _id, attributes, productFamily } = server;
  const reservationTerms = [...new Set(costs.map(p => p.termAttributes.LeaseContractLength))];
  const paymentOptions = [...new Set(costs.map(p => p.termAttributes.PurchaseOption))];
  const pricingModels = [...new Set(costs.map(p => p.termAttributes.OfferingClass))];
  const [selectedCost, setSelectedCost] = useState(costs.find(p => p.selected) || costs[0]);
  const [chartData, setChartData] = useState({ labels: [], data: [] });
  const [migrationStrategy, setMigrationStrategy] = useState([]);
  const [utilizationGroups, setUtilizationGroups] = useState([]);
  const [isError, setIsError] = useState({});
  const [pricingMonths, setPricingMonths] = useState(36);
  const { suggestions } = useSelector(state => state.suggestions);
  const shared = props.shared;
  const servers = [server];
  //Storage Product Costs
  const selectedStorage = storageProducts["Storage"] && (storageProducts["Storage"]?.find(p => p.selected) || storageProducts["Storage"][0])
  const selectedStorageCosts = selectedStorage && (selectedStorage.costs.find(p => p.selected) || selectedStorage.costs[0]);
  selectedStorage && servers.push(selectedStorage)
  //Snapshot Product Costs
  const selectedSnapshot = storageProducts['Storage Snapshot'] && (storageProducts['Storage Snapshot']?.find(p => p.selected) || storageProducts['Storage Snapshot'][0])
  const selectedSnapshotCosts = selectedSnapshot && (selectedSnapshot.costs.find(p => p.selected) || selectedSnapshot.costs[0]);
  selectedSnapshot && servers.push(selectedSnapshot)

  const selectCostHandler = (costData) => {
    costData.selected = true;
    selectedCost.selected = undefined;
    setSelectedCost(costData);
    serverUpdateHandler(selectServer);
  };

  useEffect(() => {
    if (suggestions && suggestions.migrationStrategy && clientServer) {
      setMigrationStrategy(suggestions.migrationStrategy && suggestions.migrationStrategy.filter(m => m.name === clientServer.migrationStrategy))
    } else if (suggestions) {
      setMigrationStrategy(suggestions.assumptions.default.filter(m => m.name === clientServer.migrationStrategy && m.attributeType === "migrationStrategy"))
    }
    if (suggestions && suggestions.utilizationGroups && clientServer) {
      setUtilizationGroups(suggestions.utilizationGroups && suggestions.utilizationGroups.filter(m => m.name === clientServer.utilizationGroup))
    } else if (suggestions) {
      setUtilizationGroups(suggestions.assumptions.default.filter(m => m.name === clientServer.utilizationGroup && m.attributeType === "utilizationGroup"))
    }
  }, [clientServer, suggestions]);

  useEffect(() => {
    const labels = Object.keys(storageProducts);
    const data = [];
    for (const key of labels) {
      if (key === "Storage") data.push(selectedStorageCosts?.totalCost1Year)
      else if (key === "Storage Snapshot") data.push(selectedSnapshotCosts?.totalCost1Year)
      if (key === "Compute Instance") data.push(selectedCost?.totalCost1Year)
    }
    setChartData({ labels, data });
  }, [selectedCost, selectedStorageCosts, selectedSnapshotCosts])

  const groupMonthsHandler = ({ target: { value, name } }, index, migrationName) => {
    const selectedGroupIndex = migrationStrategy.findIndex(a => a.name === migrationName);
    const data = Object.assign([], migrationStrategy);
    data[selectedGroupIndex].pricingGroups[index][name] = +value;
    const changedData = pricingAutoChangeHandler(data, selectedGroupIndex, index)
    setMigrationStrategy(changedData);
    setIsError(p => ({ ...p, [migrationName]: '' }));
    if (value > pricingMonths) {
      setIsError(p => ({ ...p, [migrationName]: 'Value cannot be greater than value pricing months!' }));
    }
  }

  const pricingHandleChange = (index) => {
    //AddNew
    const data = Object.assign([], migrationStrategy);
    data[index].pricingGroups.push({
      reservationTermFrom: +data[index].pricingGroups[data[index].pricingGroups.length - 1].reservationTermTo + 1,
      reservationTermTo: +data[index].pricingGroups[data[index].pricingGroups.length - 1].reservationTermTo + 2,
      pricingModel: "noMigration"
    })
    setMigrationStrategy(data);
  }

  const pricingModelRemoveHandler = (index, i) => {
    //Remove
    let newFormValues = [...migrationStrategy];
    newFormValues[index].pricingGroups.splice(i, 1);
    const changedData = pricingAutoChangeHandler(newFormValues, index, 0)
    setMigrationStrategy(changedData);
  }

  const pricingFieldHandleChange = ({ value }, { target: { name } }, parentIndex, index, year) => {
    //DropDown
    const data = Object.assign([], migrationStrategy);
    data[parentIndex].pricingGroups[index][name] = value;
    if (year) {
      data[parentIndex].pricingGroups[index].reservationTermTo = index === 0 ? +year * 12 : (data[parentIndex].pricingGroups[index].reservationTermFrom - 1) + (+year * 12);
      const changedData = pricingAutoChangeHandler(data, parentIndex, index)
      setMigrationStrategy(changedData);
    }
    setMigrationStrategy(data);
    setIsError(p => ({ ...p, [data[parentIndex].name]: '' }));
    if ((+year * 12) > pricingMonths) {
      setIsError(p => ({ ...p, [data[parentIndex].name]: 'Value cannot be grater than value pricing months!' }));
    }
  }

  const pricingAutoChangeHandler = (data, parentIndex, i) => {
    //Auto
    data[parentIndex].pricingGroups.forEach((p, index) => {
      if (index > i) {
        const priceYears = groupPricingOptions.find(a => a.value === p.pricingModel);
        p.reservationTermFrom = +data[parentIndex].pricingGroups[index - 1].reservationTermTo + 1;
        p.reservationTermTo = +p.reservationTermFrom + (priceYears && priceYears.year ? (+priceYears.year * 12) - 1 : 1);
      }
      return p;
    });
    return data;
  }

  const handleChangePricingGroups = ({ target: { value } }) => {
    const data = Object.assign([], migrationStrategy);
    data.forEach(a => {
      a.pricingMonths = value;
      return a;
    });
    setPricingMonths(value);
    setMigrationStrategy(data);
  };

  return (
    <div className="card shadow mt-3">
      <div className="card-body p-3">
        {productFamily === 'Unavailable' ?
          <span className="server-not-available">No server detail available  for this server</span>
          :
          <>
            <div className="row m-0 mb-3">
              <div className="col-12 col-xl-8 ">
                <div className="row">
                  <div className="col-12">
                    <span
                      className="storage-title d-flex align-items-center mb-0 font-weight-bold">{attributes.instanceType}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">Current Generation</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.currentGeneration}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">Instance Family</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.instanceFamily}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">vcpu</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.vcpu}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">Physical Processor</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.physicalProcessor}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">Clock Speed</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.clockSpeed}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">memory</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.memory}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">storage</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.storage}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">networkPerformance</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.networkPerformance}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">processorArchitecture</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.processorArchitecture}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">tenancy</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.tenancy}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">operatingSystem</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.operatingSystem}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">licenseModel</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.licenseModel}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">usagetype</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.usagetype}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">operation</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.operation}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">capacitystatus</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.capacitystatus}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">dedicatedEbsThroughput</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.dedicatedEbsThroughput}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">ecu</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.ecu}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">enhancedNetworkingSupported</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.enhancedNetworkingSupported}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">intelAvxAvailable</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.intelAvxAvailable}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">intelAvx2Available</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.intelAvx2Available}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">intelTurboAvailable</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.intelTurboAvailable}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">normalizationSizeFactor</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.normalizationSizeFactor}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">preInstalledSw</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.preInstalledSw}</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-label">processorFeatures</span>
                  </div>
                  <div className="col-6  col-md-6 col-lg-3 col-xl-3">
                    <span className="server-instance-name">{attributes.processorFeatures}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-4 d-flex align-items-center justify-content-center ">
                <div>
                  <DynamiclyRefreshedDoughnut chartData={chartData} title="Average" showText cutoutPercentage={80} />
                  <div className="d-flex justify-content-center mt-4">
                    <Button color={_id === selectServer?._id ? "success" : "primary"}
                      className="tn btn-primary  account__btn--small"
                      onClick={() => serverSelectHandler(server)} disabled={shared}>
                      {_id === selectServer?._id ? "Current" : "Select"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ overflow: "auto", width: '100%' }}>
              <MigrationTable assumptionsData={assumptionsData} suggestionsData={{ [clientServer._id]: servers }} clientData={[clientServer]} />
            </div>
            {!!migrationStrategy.length && <>
              <hr />
              <h4 className='d-flex align-items-center justify-content-center'>Migration Strategy</h4>
              <div className="row mt-3 validation-row">
                <div className="col-12  d-flex align-items-center">
                  <h5 className=" assumption-labels">Migration Strategy</h5>
                </div>
              </div>
              <div className="offset-12 offset-md-2 col-12 mt-3 w-auto">
                <div className="row align-items-center assumption-num-container">
                  <div className="">
                    <h2 className="assumption-title">Number of Pricing Months</h2>
                  </div>
                  <div className="">
                    <Input type="number" name="value" value={migrationStrategy[0].pricingMonths}
                      onChange={handleChangePricingGroups}
                      className="w-100 text-left rounded-0 assumption-group-input font-weight-bold" />
                  </div>
                </div>
                <div className="row align-items-center mt-4 mb-2 ">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-3 p-0 ">
                    <h2 className="assumption-title">Groups</h2>
                  </div>
                </div>
                {migrationStrategy.map((m, index) => {
                  return (
                    <div className="row gap my-2">
                      <div className="">
                        <Button color={groupColors[index]}
                          className="rounded assmption-group-btn">{m.name}</Button>
                      </div>
                      <div className="flex-grow-1 mr-lg-5 mr-xl-5">
                        <Card className="assumption-card-content">
                          <CardBody className="p-3">
                            {m.pricingGroups.map((group, i) => {
                              return (
                                <div className="row m-0 gap">
                                  <div className=" ">
                                    <div className="row m-0 align-items-center">
                                      <div className="mr-2">
                                        <Input type="number" inputmode="numeric"
                                          name="reservationTermFrom" disabled
                                          value={group.reservationTermFrom}
                                          className="w-100 text-left rounded-0 assumption-group-input" />
                                      </div>
                                      <span className="assumption-label-t">to</span>
                                      <div className="ml-2">
                                        <Input type="number" max={pricingMonths}
                                          inputmode="numeric" name="reservationTermTo"
                                          readOnly={group.pricingModel !== "noMigration" && group.pricingModel !== "OnDemand"}
                                          value={group.reservationTermTo}
                                          className="w-100 text-left rounded-0 assumption-group-input"
                                          onChange={(e) => groupMonthsHandler(e, i, m.name)}
                                          onBlur={({ target: { value, name } }) => {
                                            const data = Object.assign([], migrationStrategy);
                                            data[index].pricingGroups[i][name] = name === "reservationTermTo" ? +value <= data[index].pricingGroups[i].reservationTermFrom ? +data[index].pricingGroups[i].reservationTermFrom + 1 : value : value
                                            const changedData = pricingAutoChangeHandler(data, index, i);
                                            setMigrationStrategy(changedData);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <UncontrolledDropdown>
                                      <ButtonGroup dir="ltr" className="w-100">
                                        <Button outline
                                          className="w-100 text-left rounded-0 validation-dropdown-style assumption-drop-label">{group.pricingModel}</Button>
                                        <DropdownToggle outline
                                          className="validation-icon icon--right rounded-0 assumption-drop-btn">
                                          <ChevronDownIcon />
                                        </DropdownToggle>
                                      </ButtonGroup>
                                      <DropdownMenu right
                                        className="dropdown__menu custom-dropdown-menu">
                                        {groupPricingOptions.map(o => (
                                          <DropdownItem name="pricingModel"
                                            pricingFieldHandleChang onClick={(e) => pricingFieldHandleChange(o, e, index, i, o.year)}>{o.label}</DropdownItem>
                                        ))}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  {!!i &&
                                    <div onClick={() => pricingModelRemoveHandler(index, i)}>
                                      <DeleteIcon color="#dc3545" className="deleteIconStyle" />
                                    </div>}
                                </div>
                              )
                            })}
                            {isError[m.name] && <span className="red-text">{isError[m.name]}</span>}
                            <div className="row m-0">
                              <div className="" onClick={() => pricingHandleChange(index)}>
                                <AddCircleOutlineIcon className="add-circle-icon pointer" />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  )
                })}
              </div>
            </>}
            <ServerSuggestionsPricing shared={shared} subtitle="Show Server Price List"
              selectedCost={selectedCost}
              setSelectedCost={selectCostHandler} costs={costs}
              reservationTerms={reservationTerms} pricingModels={pricingModels}
              utilizationGroup={utilizationGroups}
              server={clientServer}
              paymentOptions={paymentOptions} />
            <ServerSuggestionsPricing shared={shared} subtitle="Show Storage Pricing"
              selectedCost={selectedStorageCosts} setSelectedCost={selectCostHandler}
              costs={selectedStorage?.costs}
              paymentOptions={paymentOptions} pricingType="storage" server={clientServer} />
            <ServerSuggestionsPricing shared={shared} subtitle="Show Snapshots Pricing"
              selectedCost={selectedSnapshotCosts} setSelectedCost={selectCostHandler}
              costs={selectedSnapshot?.costs}
              paymentOptions={paymentOptions} pricingType="snapshot" server={clientServer} />
          </>
        }
      </div>
    </div>
  )
}
