import { createActions, createReducer } from "reduxsauce";
import {
    deleteProject as deleteProjectApi,
    deleteScenario as deleteScenarioApi,
    getAllProjects,
    updateProject as updateProjectApi
} from "../../api/admin";

const getProjects = () => {
    return (dispatch) => {
        dispatch(Creators.isLoadingProjects());
        getAllProjects().then(res => {
            dispatch(Creators.getProjectsSuccess(res));
        }).catch((error) => {
            dispatch(Creators.getProjectsError(error));
        });
    };
};

const updateProject = (clientId, data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingUpdateProject());
        updateProjectApi(clientId, data).then((response) => {
            dispatch(Creators.updateProjectSuccess(data));
        }).catch((error) => {
            dispatch(Creators.updateProjectError(error));
        });
    };
}

//Delete client project
const deleteProject = (data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingDeleteProject());
        deleteProjectApi(data).then((response) => {
            dispatch(Creators.deleteProjectSuccess(data));
        }).catch((error) => {
            dispatch(Creators.deleteProjectError(error));
        });
    };
}

//Delete client project scenarios
const deleteScenario = (data) => {
    return (dispatch) => {
        dispatch(Creators.isLoadingDeleteProject());
        deleteScenarioApi(data).then((response) => {
            dispatch(Creators.deleteProjectScenarioSuccess(data.dataId, data.id, data.clientId));
        }).catch((error) => {
            dispatch(Creators.deleteProjectError(error));
        });
    };
}

export const { Types, Creators } = createActions({
    getProjects,
    deleteProject,
    deleteScenario,
    updateProject,
    updateProjectSuccess: ['data'],
    updateProjectError: ['error'],
    isLoadingUpdateProject: [],
    getProjectsSuccess: ['projects'],
    getProjectsError: ['error'],
    isLoadingProjects: [],
    deleteProjectSuccess: ['data'],
    deleteProjectScenarioSuccess: ['projectId', 'scenarioId', 'clientId'],
    deleteProjectError: ['error'],
    isLoadingDeleteProject: [],
});

const initialState = {
    projects: [],
    isError: false,
    isLoading: false,
    isDeleteLoading: false,
};


const updateProjectSuccess = (state = initialState, action) => {
    // if (!state.clientData) return state;
    const project = state.projects.flatMap(p => p.data).find(p => p._id === action.data._id);
    project.status = action.data.status;
    return {
        ...state,
        clientData: {
            ...state.clientData,
        },
        isDeleteLoading: false,
        isUpdateLoading: false,
        isDeleteError: '',
        isUpdateError: ''
    };
};

const updateProjectError = (state = initialState, action) => {
    return {
        ...state,
        isUpdateLoading: false,
        isUpdateError: action.error,
    };
};

const isLoadingUpdateProject = (state = initialState, action) => {
    return {
        ...state,
        isUpdateLoading: true,
        isUpdateError: '',
    };
};

const getProjectsSuccess = (state = initialState, action) => {
    return {
        ...state,
        projects: action.projects,
        isLoading: false,
        isError: '',
    };
};

const getProjectsError = (state = initialState, action) => {
    return {
        ...state,
        projects: null,
        isLoading: false,
        isError: action.error,
    };
};

const isLoadingProjects = (state = initialState, action) => {
    return {
        ...state,
        isLoading: true,
        isError: '',
    };
};

const deleteProjectSuccess = (state = initialState, action) => {
    if (!state?.projects) return state
    const projects = state?.projects.filter(d => d._id !== action.data._id)
    return {
        ...state,
        projects,
        isDeleteLoading: false,
        isDeleteError: '',
    };
};



const deleteProjectScenarioSuccess = (state = initialState, action) => {
    const allData = Object.assign([], state.projects);
    const index = allData.findIndex(p => p._id === action.clientId);
    // const projectIndex = allData[index]?.data.findIndex(p => p._id === action.projectId);
    allData[index].data.scenarios = allData[index].data.scenarios.filter(p => p._id !== action.scenarioId);
    return {
        ...state,
        projects: allData,
        isDeleteLoading: false,
        isDeleteError: '',
    };
};

const deleteProjectError = (state = initialState, action) => {
    return {
        ...state,
        isDeleteLoading: false,
        isDeleteError: action.error,
    };
};
const isLoadingDeleteProject = (state = initialState, action) => {
    return {
        ...state,
        isDeleteLoading: true,
        isDeleteError: '',
    };
};

export default createReducer(initialState, {
    [Types.GET_PROJECTS]: getProjects,
    [Types.GET_PROJECTS_SUCCESS]: getProjectsSuccess,
    [Types.GET_PROJECTS_ERROR]: getProjectsError,
    [Types.IS_LOADING_PROJECTS]: isLoadingProjects,
    [Types.DELETE_PROJECT_SUCCESS]: deleteProjectSuccess,
    [Types.DELETE_PROJECT_SCENARIO_SUCCESS]: deleteProjectScenarioSuccess,
    [Types.DELETE_PROJECT_ERROR]: deleteProjectError,
    [Types.IS_LOADING_DELETE_PROJECT]: isLoadingDeleteProject,
    [Types.DELETE_PROJECT]: deleteProject,
    [Types.DELETE_SCENARIO]: deleteScenario,
    [Types.UPDATE_PROJECT_SUCCESS]: updateProjectSuccess,
    [Types.UPDATE_PROJECT_ERROR]: updateProjectError,
    [Types.IS_LOADING_UPDATE_PROJECT]: isLoadingUpdateProject,
});
